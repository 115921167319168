import { format } from 'date-fns';

function formatDate(date) {
  const newDate = new Date(date);
  const formattedDate = format(newDate, 'MM/DD/YYYY');

  return formattedDate;
}

export default formatDate;
