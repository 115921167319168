import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor } from '../../../util/theme';

const HeaderLinkIcons = styled.div`
  align-self: flex-end;

  display: flex;
  align-items: center;

  height: 100%;

  padding: 10px 10px 0 10px;

  @media (min-width: ${getBreakpoint('md')}) {
    display: none;
  }

  svg {
    height: 15px;
    width: 15px;

    margin: 0 10px;

    path {
      fill: ${getColor('controlBlue')};

      &:hover {
        fill: ${getColor('accentOrange')};
      }
    }
  }
`;

export default HeaderLinkIcons;
