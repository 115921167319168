import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../util/theme';

const LoanHeaderContentSection = styled.div`
  flex-basis: 33%;

  display: flex;
  align-items: center;

  position: relative;

  margin: 0 0 15px 0;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 0;
  }
`;

export default LoanHeaderContentSection;
