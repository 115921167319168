import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const ProfileContentFieldLabel = styled.label`
  flex-basis: 30%;

  color: ${getColor('titleGrey')};
  font-size: 1.4rem;
  font-weight: ${getFontWeight('medium')};
  text-align: right;
  text-transform: uppercase;

  margin: 0 5px 0 0;
  padding: 10px 0;

  width: 125px;

  text-align: left;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 0 20px 0 0;
  }

  &.align-start {
    align-self: flex-start;
  }
`;

export default ProfileContentFieldLabel;
