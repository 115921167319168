import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../util/theme';

const PreLoanInformationContent = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 15px 0 25px 0;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 0;
  }
`;

export default PreLoanInformationContent;
