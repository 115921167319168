import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor } from '../../../../util/theme';

const DrawerWrapper = styled.div`
  background-color: ${props => (props.expanded ? getColor('primaryBlue') : getColor('white'))};

  position: fixed;
  left: 0;
  top: 0;

  height: ${props => (props.expanded ? '100%' : '50px')};
  width: ${props => props.width};

  padding: 0 0 25px 0;

  transition: all 0.3s ease-in-out;
  transform: translate3d(0, 0, 0);

  overflow-x: hidden;
  overflow-y: ${props => (props.expanded ? 'auto' : 'hidden')};

  z-index: 9999;

  @media (min-width: ${getBreakpoint('md')}) {
    background-color: ${getColor('primaryBlue')};

    height: 100%;

    overflow-y: auto;
  }
`;

export default DrawerWrapper;
