import { useEffect, useState } from 'react';

// import config
import { API_BASE_URL } from '../util/config';

// process loans function
import { formatLoans } from '../util/processLoans';
// import formatAddress from '../../../util/formatAddress';

async function formatLoanAddresses(loans) {
  // const loansWithFormattedAddresses = loans.map(async loan => {
  //   const formattedAddress = await formatAddress(loan.subjectPropertyAddress);
  //   return {
  //     ...loan,
  //     propertyAddr: formattedAddress,
  //   };
  // });

  // const resolvedLoansWithFormattedAddresses = await Promise.all(loansWithFormattedAddresses);

  // return resolvedLoansWithFormattedAddresses;

  const loansWithFormattedAddresses = loans.map(loan => {
    return {
      ...loan,
      propertyAddr: loan.subjectPropertyAddress,
    };
  });

  return loansWithFormattedAddresses;
}

async function fetchRawAndProcessedLoans(userId, accessToken, setLoansState, setLoading, setError) {
  if (userId === 0) {
    return [null, null];
  }

  const loansUrl = `${API_BASE_URL}/api/Loan?user=${userId}`;

  const userLoans = await fetch(loansUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${accessToken}`,
    },
  });

  if (userLoans.status === 500) {
    setLoansState([], []);
    setLoading(false);
    setError(true);

    return;
  }

  const rawLoansData = await userLoans.json();

  const rawLoansWithAddressesData = await formatLoanAddresses(rawLoansData);

  const processedLoansData = await formatLoans(rawLoansWithAddressesData);

  setLoansState(rawLoansData, processedLoansData);

  setLoading(false);
}

function useGetUserLoans(userId, accessToken) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rawLoans, setRawLoans] = useState([]);
  const [processedLoans, setProcessedLoans] = useState({});

  const setLoansState = (rawLoans, processedLoans) => {
    setRawLoans(rawLoans);
    setProcessedLoans(processedLoans);
  };

  useEffect(() => {
    fetchRawAndProcessedLoans(userId, accessToken, setLoansState, setLoading, setError);
  }, [userId]);

  return [error, loading, rawLoans, processedLoans];
}

export default useGetUserLoans;
