import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

// import functions
import { logoutUser } from '../functions';

// import styled components
import { DrawerItem, DrawerItemTitle, DrawerItemListWrapper, DrawerItemList } from './styled';

// import icon
import profileIcon from '../../../assets/icons/drawer-profile-icon.svg';

const DrawerProfile = ({ expanded }) => (
  <>
    <DrawerItem expanded={expanded}>
      <img src={profileIcon} alt="Loans" />
      <DrawerItemTitle expanded={expanded}>
        <span>Profile</span>
      </DrawerItemTitle>
    </DrawerItem>
    <DrawerItemListWrapper expanded={expanded} className="profile">
      <DrawerItemList>
        <li>
          <Link to="/profile">View & Edit Profile</Link>
        </li>
        <li className="interactive" onClick={() => logoutUser()} onKeyPress={() => logoutUser()} tabIndex="0">
          Logout
        </li>
      </DrawerItemList>
    </DrawerItemListWrapper>
  </>
);

export default DrawerProfile;

DrawerProfile.propTypes = {
  expanded: PropTypes.bool.isRequired,
};
