import styled from 'styled-components/macro';

// import helpers
import { getColor } from '../../../../util/theme';

const AfLoanBodyBackground = styled.div`
  height: 100%;
  width: 100%;

  background-color: ${getColor('backgroundGrey')};
`;

export default AfLoanBodyBackground;
