import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const ProfileContentField = styled.div`
  display: flex;
  flex-direction: column;

  padding: 5px 0;

  width: 100%;

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
  }
`;

export default ProfileContentField;
