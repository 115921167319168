import React from 'react';

// import assets
import { ReactComponent as ProfileIcon } from '../../assets/icons/user-profile-icon.svg';

// import styled components
import { ProfileHeaderWrapper, ProfileHeaderLeftLink, ProfileHeaderRightLink } from './styled';

const ProfileHeader = () => (
  <ProfileHeaderWrapper>
    <ProfileHeaderLeftLink to="/profile">
      <ProfileIcon />
      <h1>Profile</h1>
    </ProfileHeaderLeftLink>
    <div>
      <ProfileHeaderRightLink to="/profile/password">Change Password</ProfileHeaderRightLink>
    </div>
  </ProfileHeaderWrapper>
);

export default ProfileHeader;
