// not rly necessary to use constructors here but w/e
function Rail(height, marginTop) {
  this.position = 'absolute';
  this.width = '100%';
  this.height = height;
  this.marginTop = marginTop;
  this.borderRadius = 5;
  this.background = 'repeating-linear-gradient(-45deg, #0071bc, #0071bc 3px, #a3cbe6 3px, #a3cbe6 6px)';
}

const mobileRailStyle = new Rail(15, 30);
const railStyle = new Rail(20, 35);

export { mobileRailStyle, railStyle };
