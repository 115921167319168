import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// import context
import { LoggedInContext } from '../Context/LoggedInContext';

// import hooks
import { useGetIndividualLoanDetails } from './hooks';

// import components
import LoanHeader from './LoanHeader';
import ActiveFundedLoan from './ActiveFundedLoan';
import PreLoan from './PreLoan';

// import styled components
import { Grid, Spinner } from '../styled';

/**
 * not destructuring props here to make issue with props.location.state in Edge clear, see below
 */
function Loan(props) {
  const {
    state: { loanCacheList, loanCache, userState },
    functions: { loanCacheDispatch },
  } = useContext(LoggedInContext);

  /**
   * there is a bug in Edge with this...props.location.state is null if loan is directly accessed/refreshed
   * if 100% Edge support becomes an issue (lol) then look at that more closely...
   * works fine in everything else, even IE11!
   */

  // const {
  //   location: {
  //     state: { type },
  //   },
  // } = props;

  const loanData = useGetIndividualLoanDetails(userState, props.id, loanCache, loanCacheList, loanCacheDispatch);

  return (
    <div id="main">
      {// show loading spinner if loan type hasn't been determined, prevents flash of blank content
      !loanData.loanType ? (
        <Grid className="load-container">
          <Spinner />
        </Grid>
      ) : (
        <>
          <Grid>
            <LoanHeader loanData={loanData} />
          </Grid>
          {loanData.loanType === 'In Process' || loanData.loanType === 'Funded' ? (
            <ActiveFundedLoan loanData={loanData} />
          ) : (
            <PreLoan loanData={loanData} />
          )}
        </>
      )}
    </div>
  );
}

export default Loan;

/**
 * Loan props are all from router
 */
Loan.propTypes = {
  '*': PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};

/**
 * set default props bc all return undefined on initial render
 */
Loan.defaultProps = {
  '*': '',
  location: {},
  navigate: () => null,
  path: '',
  uri: '',
};
