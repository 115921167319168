import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// import hooks
import { useIsOpen } from '../../../../hooks';

// import styled components
import {
  AfLoanContactCardWrapper,
  AfLoanContactCardHeader,
  AfLoanContactCardBodyWrapper,
  AfLoanContactCardBody,
} from './styled';

// import svg
import { ReactComponent as LoanContactArrow } from '../../../../assets/ui/loan-contact-arrow.svg';

function AfLoanContactCard({ contact }) {
  const [isOpen, setIsOpen] = useIsOpen(false);

  const possibleFields = [
    {
      label: 'NAME',
      field: 'name',
    },
    {
      label: 'COMPANY NAME',
      field: 'companyName',
    },
    {
      label: 'NMLS#',
      field: 'nmls',
    },
    {
      label: 'LICENSE',
      field: 'license',
    },
    {
      label: 'EMAIL',
      field: 'email',
    },
    {
      label: 'PHONE',
      field: 'phone',
    },
    {
      label: 'ADDRESS',
      field: 'address',
      html: true,
    },
  ];

  const drawLoanContactCardBody = (field, label, html) => {
    if (contact[field]) {
      if (field === 'email') {
        return (
          <div key={field}>
            <span>{label}</span>
            <p>
              <a href={`mailto:${contact[field]}`} target="_blank" rel="noopener noreferrer">
                {contact[field]}
              </a>
            </p>
          </div>
        );
      }

      if (field === 'phone') {
        return (
          <div key={field}>
            <span>{label}</span>
            <p>
              <a href={`tel:${contact[field]}`}>{contact[field]}</a>
            </p>
          </div>
        );
      }

      return (
        <div key={field}>
          <span>{label}</span>
          {html ? <p>{ReactHtmlParser(contact[field])}</p> : <p>{contact[field]}</p>}
        </div>
      );
    }
  };

  return (
    <AfLoanContactCardWrapper>
      <AfLoanContactCardHeader onClick={() => setIsOpen(!isOpen)} onKeyPress={() => setIsOpen(!isOpen)} tabIndex="0">
        <LoanContactArrow />
        <h3>{contact.role}</h3>
        <LoanContactArrow className={isOpen ? null : 'isClosed'} />
      </AfLoanContactCardHeader>
      <AfLoanContactCardBodyWrapper isOpen={isOpen}>
        <AfLoanContactCardBody>
          {possibleFields.map(possibleField => {
            return drawLoanContactCardBody(possibleField.field, possibleField.label, possibleField.html);
          })}
        </AfLoanContactCardBody>
      </AfLoanContactCardBodyWrapper>
    </AfLoanContactCardWrapper>
  );
}

export default AfLoanContactCard;

AfLoanContactCard.propTypes = {
  contact: PropTypes.object.isRequired,
};
