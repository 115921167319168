import styled from 'styled-components/macro';

// import helpers
import { getColor } from '../../../util/theme';

const LoginFormLogo = styled.div`
  margin: 0 auto;
  padding: 4.5rem 0 3rem 0;

  text-align: center;

  width: 22rem;

  svg {
    path {
      fill: ${getColor('white')};
    }
  }
`;

export default LoginFormLogo;
