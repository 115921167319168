import React from 'react';
import PropTypes from 'prop-types';

// import hooks
import { useRegenerateLetter } from '../hooks';

// import styled components
import { PreLoanLetterButton, PreLoanLetterButtonWrapper } from '../styled';

// import svg
import { ReactComponent as ResetValuesIcon } from '../../../../assets/buttons/reset-values.svg';
import { ReactComponent as PreviewLetterIcon } from '../../../../assets/buttons/preview-letter.svg';
import { ReactComponent as CancelLetterIcon } from '../../../../assets/buttons/cancel-letter.svg';

function PreLoanModifyButtons({ dispatch, isMobile, loanData, modifiedLoan, toggleModify }) {
  const modifiedLetterData = {
    ...loanData.letterInformation,
    LoanAmount: `$${parseInt(modifiedLoan.letterLoanAmount).toLocaleString()}`,
    PurchasePrice: `$${parseInt(modifiedLoan.letterPurchasePrice).toLocaleString()}`,
  };

  const [handleLetterRequest, sendingRequest] = useRegenerateLetter(loanData.loanType, modifiedLetterData);

  return (
    <PreLoanLetterButtonWrapper>
      <PreLoanLetterButton onClick={() => dispatch({ type: 'resetValues' })}>
        <ResetValuesIcon /> Reset Values
      </PreLoanLetterButton>
      <PreLoanLetterButton disabled={sendingRequest} onClick={() => handleLetterRequest()}>
        <PreviewLetterIcon /> Preview Letter
      </PreLoanLetterButton>
      <PreLoanLetterButton onClick={() => toggleModify(isMobile)}>
        <CancelLetterIcon /> Cancel Letter
      </PreLoanLetterButton>
    </PreLoanLetterButtonWrapper>
  );
}

export default PreLoanModifyButtons;

PreLoanModifyButtons.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  loanData: PropTypes.object.isRequired,
  modifiedLoan: PropTypes.object.isRequired,
  toggleModify: PropTypes.func.isRequired,
};
