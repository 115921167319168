import styled from 'styled-components/macro';

// import helpers
import { getColor } from '../../../../util/theme';

const PreLoanSuccessReturnButton = styled.button`
  border: 0;
  border-bottom: 1px solid ${getColor('complimentaryBlue')};

  background-color: transparent;

  color: ${getColor('complimentaryBlue')};
  font-size: 1.6rem;

  margin: 15px 0 35px 0;
  padding: 5px;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    color: ${getColor('accentGreen')};
    border-bottom: 1px solid ${getColor('accentGreen')};

    svg {
      polygon {
        fill: ${getColor('accentGreen')} !important;
      }
    }
  }

  svg {
    margin: 0 10px 0 0;
  }
`;

export default PreLoanSuccessReturnButton;
