import React from 'react';
import { Link } from '@reach/router';

// import styled components
import { RegisterFailOrSuccessWrapper } from './styled';

const RegisterSubmitSuccess = () => (
  <RegisterFailOrSuccessWrapper>
    <strong>
      <h3>Registration Success!</h3>
    </strong>
    <h3 className="regular">Your account has been successfully created.</h3>
    <p>
      Please return to the <Link to="/login">login page</Link> and login to view the status of your clients'
      transactions.
    </p>
  </RegisterFailOrSuccessWrapper>
);

export default RegisterSubmitSuccess;
