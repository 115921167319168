import { API_BASE_URL } from '../../../../util/config';

async function sendLoginRequest(userState) {
  const loginUrl = `${API_BASE_URL}/login`;

  const userLogin = await fetch(loginUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `username=${userState.username}&password=${userState.password}&grant_type=password`,
  });

  if (userLogin.status === -1) {
    return {
      failed: true,
      error: {
        serviceFailed: 'The server is currently unavailable. Please come back later. Sorry for the inconvenience!',
      },
    };
  }

  if (userLogin.status === 400) {
    const { error_description } = await userLogin.json();

    return {
      failed: true,
      error: {
        loginFailed: error_description,
      },
    };
  }

  const { UserId, UserName, access_token } = await userLogin.json();

  return {
    userId: UserId,
    userName: UserName,
    accessToken: access_token,
  };
}

export default sendLoginRequest;
