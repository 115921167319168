function checkIfUsernameOrPasswordBlank(userState) {
  const isUsernameBlank = () => {
    if (userState.username.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const isPasswordBlank = () => {
    if (userState.password.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  return {
    value: isUsernameBlank() || isPasswordBlank(),
    errors: {
      username: isUsernameBlank() ? 'Username missing' : '',
      password: isPasswordBlank() ? 'Password missing' : '',
    },
  };
}

export default checkIfUsernameOrPasswordBlank;
