import React from 'react';
import PropTypes from 'prop-types';

// import styled base
import { HandleLabel, HandleWrapper } from './styled';

const Handle = ({ getHandleProps, handle: { id, value, percent } }) => (
  <HandleWrapper percent={percent} {...getHandleProps(id)}>
    <HandleLabel>{`$${value.toLocaleString()}`}</HandleLabel>
  </HandleWrapper>
);

export default Handle;

Handle.propTypes = {
  getHandleProps: PropTypes.func.isRequired,
  handle: PropTypes.object.isRequired,
};
