import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import config
import { API_BASE_URL } from '../../../util/config';

// import styled components
import { SubmitButton } from '../../styled';
import { RegisterSubmitWrapper } from './styled';

/**
 * this component could really use some modularization lol
 */

const setUntouchedFieldErrors = (state, fields, dispatch) => {
  const untouchedFields = fields.filter(field => !state[field].touched);

  untouchedFields.map(field => {
    dispatch({ type: 'addUntouchedError', name: field });
    return null;
  });
};

const checkForRegistrationFormErrors = (state, dispatch) => {
  const requiredFields = Object.keys(state).filter(field => state[field].required);

  setUntouchedFieldErrors(state, requiredFields, dispatch);

  const requiredFieldErrors = requiredFields.map(field => state[field].touched && !state[field].error);

  const everyRequiredFieldValid = requiredFieldErrors.every(field => field);

  return everyRequiredFieldValid;
};

const submitRegistration = async (state, email, setSendingRequest, setRegistrationStatusState) => {
  setSendingRequest(true);

  const registerUserUrl = `${API_BASE_URL}/api/Registration/Create`;

  // const newUserData = Object.keys(state).map(field => state[field].value);
  const newUserData = Object.keys(state).reduce((obj, field) => {
    if (field === 'address') {
      obj.address = { ...state.address };
      delete obj.address.required;
    } else {
      obj[field] = state[field].value;
    }
    return obj;
  }, {});

  const formattedNewUserData = Object.keys(newUserData).reduce(
    // eslint-disable-next-line no-sequences
    (c, k) => ((c[`${k.charAt(0).toUpperCase()}${k.slice(1, k.length)}`] = newUserData[k]), c),
    {}
  );

  const formattedNewUserDataWithExtra = {
    ...formattedNewUserData,
    NewsLetter: formattedNewUserData.NewsLetter ? true : false,
    Gender: formattedNewUserData.Gender ? formattedNewUserData.Gender : 'null',
    Email: email,
    City: formattedNewUserData.Address.city,
    State: formattedNewUserData.Address.state,
    ZipCode: formattedNewUserData.Address.zip,
    Address: formattedNewUserData.Address.street,
  };

  const formData = new FormData();

  const serializedNewUserData = JSON.stringify(formattedNewUserDataWithExtra);

  formData.append('jsonData', serializedNewUserData);

  if (state.headshot.file) {
    formData.append('HeadShot', state.headshot.file);
  }

  if (state.companyLogo.file) {
    formData.append('CompanyLogo', state.companyLogo.file);
  }

  const sendRegisterUserRequest = await fetch(registerUserUrl, {
    method: 'POST',
    body: formData,
  });

  setSendingRequest(false);

  if (sendRegisterUserRequest.status === 200) {
    setRegistrationStatusState({
      component: 'registrationSubmitSuccess',
    });
  } else {
    setRegistrationStatusState({
      component: 'registrationSubmitFailed',
    });
  }
};

const validateAndSubmitRegistration = (
  e,
  state,
  email,
  setError,
  setSendingRequest,
  setRegistrationStatusState,
  dispatch
) => {
  e.preventDefault();

  const isEveryRequiredFieldValid = checkForRegistrationFormErrors(state, dispatch);

  if (isEveryRequiredFieldValid) {
    setError('');
    submitRegistration(state, email, setSendingRequest, setRegistrationStatusState);
  } else {
    setError('Please ensure all required fields are completed.');
  }
};

function RegisterSubmit({ state, dispatch, email, setRegistrationStatusState }) {
  const [error, setError] = useState(null);
  const [sendingRequest, setSendingRequest] = useState(false);

  return (
    <RegisterSubmitWrapper
      onSubmit={e =>
        validateAndSubmitRegistration(
          e,
          state,
          email,
          setError,
          setSendingRequest,
          setRegistrationStatusState,
          dispatch
        )
      }
    >
      {error ? <p>{error}</p> : <p>&nbsp;</p>}
      <SubmitButton disabled={sendingRequest}>Submit</SubmitButton>
    </RegisterSubmitWrapper>
  );
}

export default RegisterSubmit;

RegisterSubmit.propTypes = {
  dispatch: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  setRegistrationStatusState: PropTypes.func.isRequired,
};
