import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor } from '../../../util/theme';

const LoginForm = styled.form`
  background-color: ${getColor('electricBlue')};

  color: ${getColor('white')};

  text-align: center;

  width: 100%;

  padding: 2rem 2rem 4rem 2rem;

  @media (min-width: ${getBreakpoint('sm')}) {
    width: 50rem;
  }
`;

export default LoginForm;
