import styled from 'styled-components/macro';

// import helpers
import { getColor } from '../../../util/theme';

const DashboardLoanCard = styled.div`
  border: 1px solid ${getColor('lightGrey')};
  border-top: 5px solid ${getColor('accentGreen')};

  padding: 20px;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-10px);

    cursor: pointer;
  }

  hr {
    border-top: 0;
    border-color: ${getColor('lightestGrey')};

    margin: 10px 0;
  }
`;

export default DashboardLoanCard;
