import React from 'react';
import PropTypes from 'prop-types';

// import assets
import { ReactComponent as CalconLogo } from '../../assets/logos/calcon-logo-color.svg';
import { ReactComponent as OthlLogo } from '../../assets/logos/othl-logo-color.svg';
import { ReactComponent as TaborLogo } from '../../assets/logos/tabor-logo-color.svg';

// import styled components
import { FooterWrapper, FooterLogo, FooterText } from './styled';

function Footer({ brand }) {
  const drawLogo = brand => {
    switch (brand) {
      case 'calcon':
        return <CalconLogo />;
      case 'othl':
        return <OthlLogo />;
      case 'tabor':
        return <TaborLogo />;
      default:
        return <OthlLogo />;
    }
  };

  const drawBrandName = brand => {
    switch (brand) {
      case 'calcon':
        return 'CalCon Mutal Mortgage';
      case 'othl':
        return 'OneTrust Home Loans';
      case 'tabor':
        return 'Tabor Mortgage Group';
      default:
        return 'OneTrust Home Loans';
    }
  };

  return (
    <FooterWrapper className={brand}>
      <FooterLogo className={brand}>{drawLogo(brand)}</FooterLogo>
      <FooterText>
        <span>
          © {new Date().getFullYear()} {drawBrandName(brand)} &nbsp; • &nbsp;{' '}
          <a href="https://onetrusthomeloans.com/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </span>
      </FooterText>
    </FooterWrapper>
  );
}

export default Footer;

Footer.propTypes = {
  brand: PropTypes.string.isRequired,
};
