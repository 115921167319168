import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../../util/theme';

const AfLoanDatesAndContactsWrapper = styled.div`
  flex-basis: 55%;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 25px 25px 0 0;
  }
`;

export default AfLoanDatesAndContactsWrapper;
