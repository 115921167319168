const profileContentSecondFields = [
  {
    name: 'officePhone',
    label: 'Office Phone',
    phone: true,
  },
  {
    name: 'mobilePhone',
    label: 'Mobile Phone',
    phone: true,
  },
  {
    name: 'website',
    label: 'Website',
  },
  {
    name: 'address',
    label: 'Mailing Address',
    subFields: [
      {
        name: 'street',
        label: 'Street',
      },
      {
        name: 'city',
        label: 'City',
      },
      {
        name: 'state',
        label: 'State',
        type: 'select',
        options: [
          'Select a State',
          'Alabama',
          'Alaska',
          'Arizona',
          'Arkansas',
          'California',
          'Colorado',
          'Connecticut',
          'Delaware',
          'District of Columbia',
          'Florida',
          'Georgia',
          'Hawaii',
          'Idaho',
          'Illinois',
          'Indiana',
          'Iowa',
          'Kansas',
          'Kentucky',
          'Louisiana',
          'Maine',
          'Maryland',
          'Massachusetts',
          'Michigan',
          'Minnesota',
          'Mississippi',
          'Missouri',
          'Montana',
          'Nebraska',
          'Nevada',
          'New Hampshire',
          'New Jersey',
          'New Mexico',
          'New York',
          'North Carolina',
          'North Dakota',
          'Ohio',
          'Oklahoma',
          'Oregon',
          'Pennsylvania',
          'Rhode Island',
          'South Carolina',
          'South Dakota',
          'Tennessee',
          'Texas',
          'Utah',
          'Vermont',
          'Virginia',
          'Washington',
          'West Virginia',
          'Wisconsin',
          'Wyoming',
        ],
      },
      {
        name: 'zip',
        label: 'Zip Code',
      },
    ],
  },
];

export default profileContentSecondFields;
