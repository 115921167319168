import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// import helpers
import { getBreakpoint, getColor } from '../../../../../util/theme';

const AfLoanTab = styled.div`
  display: flex;
  align-items: center;

  border-bottom: 2px solid ${props => (props.active ? getColor('cardBlue') : getColor('medGrey'))};

  margin: 0 10px 0 0;
  padding: 0 5px;

  cursor: pointer;

  transition: all 0.2s ease-in-out;

  @media (min-width: ${getBreakpoint('md')}) {
    min-width: 216px;

    margin: 0 15px;
    padding: 0 5px 10px 5px;
  }

  &:hover {
    transform: translateY(-10px);

    border-bottom: 2px solid ${getColor('complimentaryBlue')};

    h2 {
      color: ${getColor('complimentaryBlue')};
    }
  }

  svg {
    width: 30px;

    @media (min-width: ${getBreakpoint('md')}) {
      width: 45px;
    }
  }
`;

export default AfLoanTab;

AfLoanTab.propTypes = {
  active: PropTypes.bool.isRequired,
};
