import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

// import config
import { API_BASE_URL } from '../../../util/config';

// import context
import { LoggedInContext } from '../../Context/LoggedInContext';

// import styled components
import { Spinner } from '../../styled';
import { ProfileContentSubmitContainer, ProfileContentSubmitButton, ProfileContentSubmitMessage } from './styled';

/**
 * could modularize this better
 */
function ProfileSubmit({ accessToken, profileState }) {
  const {
    state: { profileImagesUpdated },
    functions: { setProfileImagesUpdated },
  } = useContext(LoggedInContext);

  const [submitState, setSubmitState] = useState(false);
  const [submitProfileMessage, setSubmitProfileMessage] = useState({ text: '', type: null });

  const submitUpdatedProfileData = async () => {
    setSubmitState(true);

    const updateProfileUrl = `${API_BASE_URL}/api/User/Update/${profileState.userId}`;

    const formData = new FormData();

    const serializedProfileState = JSON.stringify(profileState);

    formData.append('jsonData', serializedProfileState);

    const randomNum = Math.floor(Date.now() / 1000);

    if (profileState.newImage.headshot) {
      formData.append(
        'HeadShot',
        profileState.newImage.headshot,
        `HeadShot-${randomNum}.${profileState.newImage.headshot.type.substr(6)}`
      );
    }

    if (profileState.newImage.companyLogo) {
      formData.append(
        'CompanyLogo',
        profileState.newImage.companyLogo,
        `CompanyLogo-${randomNum}.${profileState.newImage.companyLogo.type.substr(6)}`
      );
    }

    const sendUpdateToProfile = await fetch(updateProfileUrl, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
      body: formData,
    });

    const text = await sendUpdateToProfile.text();

    if (sendUpdateToProfile.status === 200) {
      setTimeout(() => {
        setSubmitState(false);
        setSubmitProfileMessage({
          text: 'Profile successfully updated',
          type: 'success',
        });

        /**
         * for preserving new uploaded images when the Profile component is unmounted
         * see LoggedInContext for where this is stored
         */
        if (profileState.newImage.headshot && profileState.newImage.companyLogo) {
          setProfileImagesUpdated({
            headshot: { updated: true, value: profileState.headshot },
            companyLogo: { updated: true, value: profileState.companyLogo },
          });
        } else if (profileState.newImage.companyLogo) {
          setProfileImagesUpdated({
            ...profileImagesUpdated,
            companyLogo: { updated: true, value: profileState.companyLogo },
          });
        } else if (profileState.newImage.headshot) {
          setProfileImagesUpdated({
            ...profileImagesUpdated,
            headshot: { updated: true, value: profileState.headshot },
          });
        }
      }, 500);
    } else {
      setTimeout(() => {
        setSubmitState(false);
        setSubmitProfileMessage({
          text: 'Error updating profile',
          type: 'error',
        });
      }, 500);
    }

    return text;
  };

  return (
    <>
      <ProfileContentSubmitMessage>
        {submitProfileMessage.text ? (
          <p className={submitProfileMessage.type}>{submitProfileMessage.text}</p>
        ) : (
          <p>&nbsp;</p>
        )}
      </ProfileContentSubmitMessage>
      <ProfileContentSubmitContainer>
        {submitState ? (
          <Spinner />
        ) : (
          <ProfileContentSubmitButton onClick={() => submitUpdatedProfileData()}>Save</ProfileContentSubmitButton>
        )}
      </ProfileContentSubmitContainer>
    </>
  );
}

export default ProfileSubmit;

ProfileSubmit.propTypes = {
  accessToken: PropTypes.string.isRequired,
  profileState: PropTypes.object.isRequired,
};
