import styled from 'styled-components/macro';

// import theme helpers
import { getColor } from '../../../../util/theme';

const DrawerItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;

  &:hover {
    span {
      color: ${getColor('accentGreen')};
    }

    svg {
      polygon,
      rect {
        fill: ${getColor('accentGreen')};
      }
    }
  }

  span {
    color: ${getColor('white')};
    font-size: 1.8rem;
    line-height: 1;

    padding: 1rem;

    overflow: hidden;

    white-space: nowrap;

    &.secondary {
      margin: 0 0 0 40px;
    }
  }

  svg {
    height: 10px;

    margin: 0;

    transition: all 0.5s;

    &.isClosed {
      transform: rotate(-90deg);
    }
  }
`;

export default DrawerItemTitle;
