import lengthValidator from './lengthValidator';
import numberValidator from './numberValidator';
import passwordValidator from './passwordValidator';
import confirmPasswordValidator from './confirmPasswordValidator';
import telValidator from './telValidator';

function initializeValidators() {
  const validators = {
    lengthValidator,
    numberValidator,
    passwordValidator,
    confirmPasswordValidator,
    telValidator,
  };

  return validators;
}

export default initializeValidators;
