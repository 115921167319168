import styled from 'styled-components/macro';

const ProfileContentHeadshotWrapper = styled.div`
  flex-basis: 35%;

  padding: 0 25px;
  margin: 0 0 25px 0;

  input {
    display: none;
  }
`;

export default ProfileContentHeadshotWrapper;
