import { useEffect, useReducer } from 'react';

function profileReducer(state, action) {
  const { type, name, value, file } = action;

  switch (type) {
    case 'setProfileValues':
      return {
        ...value,
      };
    case 'inputChange':
      return {
        ...state,
        [name]: value,
      };
    case 'addressInputChange':
      return {
        ...state,
        address: {
          ...state.address,
          [name]: value,
        },
      };
    case 'imageChange':
      return {
        ...state,
        ...value,
        newImage: {
          ...state.newImage,
          [name]: file,
        },
      };
    default:
      return { ...state };
  }
}

function useProfileReducer(initialLoad, contextProfileState) {
  const [profileState, dispatch] = useReducer(profileReducer, contextProfileState);

  useEffect(() => {
    if (!initialLoad) {
      return;
    }

    if (contextProfileState) {
      dispatch({ type: 'setProfileValues', value: contextProfileState });
    }
  }, [contextProfileState]);

  return [profileState, dispatch];
}

export default useProfileReducer;
