import styled from 'styled-components/macro';

// import reuseable styled components
import { SubmitButton } from '../../styled';

const RegisterSubmitButton = styled(SubmitButton)`
  &::after {
    content: 'Next';
  }
`;

export default RegisterSubmitButton;
