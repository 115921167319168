import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const PreLoanTitle = styled.h2`
  color: ${getColor('white')};
  font-size: 2rem;
  font-weight: ${getFontWeight('regular')};
  text-align: center;

  background-color: ${getColor('cardBlue')};

  padding: 10px;
  margin: 0 0 25px 0;

  width: 100%;

  @media (min-width: ${getBreakpoint('md')}) {
    font-size: 3rem;
  }
`;

export default PreLoanTitle;
