import { useState } from 'react';

function useEditModeState(initialEditModeState) {
  const [editModeState, setEditModeState] = useState(initialEditModeState);

  const toggleFieldEditMode = (field, address) => {
    const updatedEditModeState = { ...editModeState };

    if (address) {
      updatedEditModeState.address[field] = !updatedEditModeState.address[field];
    } else {
      updatedEditModeState[field] = !updatedEditModeState[field];
    }

    setEditModeState(updatedEditModeState);
  };

  return [editModeState, toggleFieldEditMode];
}

export default useEditModeState;
