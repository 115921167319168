// not rly necessary to use constructors here but w/e
function Slider(height) {
  this.position = 'relative';
  this.width = '100%';
  this.height = height;
}

const mobileSliderStyle = new Slider(73);
const sliderStyle = new Slider(80);

export { mobileSliderStyle, sliderStyle };
