import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../../../../util/theme';

const HandleLabel = styled.div`
  color: ${getColor('complimentaryBlue')};
  font-size: 1.6rem;
  font-weight: ${getFontWeight('medium')};

  margin: -25px 0 0 -20px;
`;

export default HandleLabel;
