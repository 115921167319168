import React from 'react';
import PropTypes from 'prop-types';

// import components
import PreLoanLetterLevelOneButtons from '../PreLoanLetter/PreLoanLetterLevelOneButtons';
import PreLoanLetterLevelTwoButtons from '../PreLoanLetter/PreLoanLetterLevelTwoButtons';

// import styled components
import { PreLoanInformationContent, PreLoanInformationTable, PreLoanSectionWrapper, PreLoanTitle } from '../styled';

function PreLoanInformation({
  fileName,
  letterData,
  levelOne,
  levelTwo,
  loanData: { loanInformation },
  loadingLetter,
  loanInformationVisible,
  modifyingLetter,
  toggleModal,
  toggleModify,
}) {
  if (!loanInformationVisible && modifyingLetter) return null;

  return (
    <PreLoanSectionWrapper>
      <PreLoanTitle>Loan Information</PreLoanTitle>
      {levelOne === 'True' && levelTwo === 'False' ? (
        <PreLoanLetterLevelOneButtons
          letterData={letterData}
          fileName={fileName}
          loadingLetter={loadingLetter}
          toggleModal={toggleModal}
          mobileOnly={true}
        />
      ) : (
        <PreLoanLetterLevelTwoButtons
          letterData={letterData}
          fileName={fileName}
          loadingLetter={loadingLetter}
          toggleModal={toggleModal}
          toggleModify={toggleModify}
          mobileOnly={true}
        />
      )}
      <PreLoanInformationContent>
        <PreLoanInformationTable>
          <tbody>
            {Object.keys(loanInformation).map(info => (
              <tr key={loanInformation[info].label}>
                <td>{loanInformation[info].label}</td>
                <td>{loanInformation[info].display}</td>
              </tr>
            ))}
          </tbody>
        </PreLoanInformationTable>
      </PreLoanInformationContent>
    </PreLoanSectionWrapper>
  );
}

export default PreLoanInformation;

PreLoanInformation.propTypes = {
  fileName: PropTypes.string.isRequired,
  letterData: PropTypes.object,
  levelOne: PropTypes.string,
  levelTwo: PropTypes.string,
  loanData: PropTypes.object.isRequired,
  loadingLetter: PropTypes.bool.isRequired,
  loanInformationVisible: PropTypes.bool,
  modifyingLetter: PropTypes.bool,
  toggleModal: PropTypes.func,
  toggleModify: PropTypes.func,
};
