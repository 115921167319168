import React, { useContext } from 'react';
import { Router } from '@reach/router';

// import context
import { LoggedInContext } from '../Context/LoggedInContext';

// import hooks
import { useProfileReducer } from './hooks';

// import styled components
import { Grid } from '../styled';
import { ProfileWrapper } from './styled';

// import components
import ProfileHeader from './ProfileHeader';
import ProfileContent from './ProfileContent';
import ProfilePassword from './ProfilePassword';

/**
 * check if a new headshot and/or logo has been uploaded by reading from context
 * if so then replace contextProfileState image with the new one
 * for preserving newly-uploaded images when this component is unmounted
 */
const formatLocalProfileState = (localProfileState, profileImagesUpdated) => {
  const { companyLogo, headshot } = profileImagesUpdated;

  if (headshot.updated === true && companyLogo.updated === true) {
    return { ...localProfileState, headshot: headshot.value, companyLogo: companyLogo.value };
  } else if (headshot.updated === true) {
    return { ...localProfileState, headshot: headshot.value };
  } else if (companyLogo.updated === true) {
    return { ...localProfileState, companyLogo: companyLogo.value };
  } else {
    return { ...localProfileState };
  }
};

/**
 * Profile has a route so it gets route props but not bothering to do PropTypes here b/c lazy
 * see Dashboard component for what it looks like
 */
function Profile() {
  const {
    state: {
      profileData: contextProfileState,
      profileImagesUpdated,
      userState: { accessToken },
    },
  } = useContext(LoggedInContext);

  const [localProfileState, dispatch] = useProfileReducer(true, contextProfileState, true);

  const formattedLocalProfileState = formatLocalProfileState(localProfileState, profileImagesUpdated);

  return (
    <Grid>
      <ProfileWrapper id="main">
        <ProfileHeader />
        <Router primary={false}>
          <ProfileContent
            accessToken={accessToken}
            profileState={formattedLocalProfileState}
            dispatch={dispatch}
            path="/"
          />
          <ProfilePassword accessToken={accessToken} profileState={formattedLocalProfileState} path="password" />
        </Router>
      </ProfileWrapper>
    </Grid>
  );
}

export default Profile;
