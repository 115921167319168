import styled from 'styled-components/macro';
import { Link } from '@reach/router';

// import helpers
import { getColor } from '../../../util/theme';

const DashboardItemLink = styled(Link)`
  &:focus {
    outline: 2px solid ${getColor('accentGreen')};
  }
`;

export default DashboardItemLink;
