import React from 'react';
import PropTypes from 'prop-types';

// import functions
import { groupContacts } from '../functions';

// import styled components
import { AfLoanContactsGroupsWrapper, AfLoanContactsGroupRow, AfLoanContactsGroup } from './styled';

// import components
import AfLoanContactCard from './AfLoanContactCard';

function AfLoanContacts({ contacts }) {
  const groupedContacts = groupContacts(contacts);

  const drawContactGroup = groupIndex => {
    return groupedContacts[groupIndex].map(contact => {
      return <AfLoanContactCard key={contact.role} contact={contact} />;
    });
  };

  return (
    <AfLoanContactsGroupsWrapper>
      <AfLoanContactsGroupRow>
        <AfLoanContactsGroup>{drawContactGroup(0)}</AfLoanContactsGroup>
        <AfLoanContactsGroup>{drawContactGroup(1)}</AfLoanContactsGroup>
      </AfLoanContactsGroupRow>
      <AfLoanContactsGroupRow>
        <AfLoanContactsGroup>{drawContactGroup(2)}</AfLoanContactsGroup>
        <AfLoanContactsGroup>{drawContactGroup(3)}</AfLoanContactsGroup>
      </AfLoanContactsGroupRow>
    </AfLoanContactsGroupsWrapper>
  );
}

export default AfLoanContacts;

AfLoanContacts.propTypes = {
  contacts: PropTypes.object.isRequired,
};

AfLoanContacts.defaultProps = {
  contacts: {},
};
