import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../util/theme';

const DashboardLoanCardBody = styled.div`
  h3 {
    font-size: 1.2rem;
    font-weight: ${getFontWeight('regular')};

    margin: 0;
    padding: 0;
  }

  p {
    color: ${getColor('medGrey')};
    font-size: 1.8rem;
    font-weight: ${getFontWeight('light')};

    margin: 5px 0;

    @media (min-width: ${getBreakpoint('md')}) {
      font-size: 2rem;

      margin: 1em 0;
    }

    &:nth-of-type(2) {
      margin: 0;
    }

    &.prequal {
      margin: 1.5em 0 0 0;
    }

    &.placeholder {
      color: transparent;
      text-shadow: 0px 0px 15px ${getColor('black')};
    }
  }
`;

export default DashboardLoanCardBody;
