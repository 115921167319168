import { useReducer } from 'react';

function loanCacheReducer(state, action) {
  const { type, loanNumber, loan } = action;

  switch (type) {
    case 'addLoan':
      if (!state.loanNumbers.includes(loanNumber)) {
        return {
          loanNumbers: [...state.loanNumbers, loanNumber],
          loans: [...state.loans, loan],
        };
      } else {
        return { ...state };
      }
    default:
      return { ...state };
  }
}

function useLoanCache() {
  const initialLoanCache = {
    loanNumbers: [],
    loans: [],
  };

  const [state, dispatch] = useReducer(loanCacheReducer, initialLoanCache);

  return [state, dispatch];
}

export default useLoanCache;
