// import functions
import transformPrequalifiedPreapprovedLoan from './transformPrequalifiedPreapprovedLoan';

async function processPrequalifiedPreapprovedLoan(loanDetailsData) {
  const formattedLoanDetailsData = await transformPrequalifiedPreapprovedLoan(loanDetailsData);

  return formattedLoanDetailsData;
}

export default processPrequalifiedPreapprovedLoan;
