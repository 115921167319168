import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor } from '../../../util/theme';

const DashboardLoanContainer = styled.div`
  background-color: ${getColor('white')};

  padding: 25px 10px;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 25px;
  }
`;

export default DashboardLoanContainer;
