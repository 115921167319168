import styled from 'styled-components/macro';

const RegisterFormUploadPreview = styled.div`
  flex-basis: 50%;

  button {
    display: block;

    margin: 0 auto;
  }

  img {
    padding: 25px;
  }
`;

export default RegisterFormUploadPreview;
