import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../util/theme';

const DashboardLoanContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 0 20px 0;
  padding: 5px 0;

  border-bottom: 1px solid ${getColor('lightestGrey')};

  cursor: pointer;

  svg {
    width: 20px;

    @media (min-width: ${getBreakpoint('md')}) {
      width: 25px;
    }
  }

  &:hover {
    h1 {
      color: ${getColor('accentGreen')};
    }

    svg {
      polygon {
        fill: ${getColor('accentGreen')};
      }
    }
  }

  h1 {
    color: ${getColor('titleBlue')};
    font-size: 2.5rem;
    font-weight: ${getFontWeight('regular')};

    margin: 0;
    padding: 0;

    @media (min-width: ${getBreakpoint('md')}) {
      font-size: 3rem;
    }
  }

  svg {
    transition: all 0.5s;

    &.isClosed {
      transform: rotate(-90deg);
    }
  }
`;

export default DashboardLoanContainerTitle;
