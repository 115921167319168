import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const PreLoanSectionWrapper = styled.div`
  flex-basis: 55%;

  padding: 0;

  display: ${props => (props.hideMobile ? 'none' : 'block')};

  &:first-of-type {
    flex-basis: 45%;
  }

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 0 25px;

    display: block;
  }
`;

export default PreLoanSectionWrapper;

PreLoanSectionWrapper.propTypes = {
  hideMobile: PropTypes.bool.isRequired,
};

PreLoanSectionWrapper.defaultProps = {
  hideMobile: false,
};
