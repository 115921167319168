import React from 'react';
import { Link } from '@reach/router';

const SuccessfulPasswordResetRequest = () => (
  <div>
    <h3>Password reset successfully.</h3>
    <p>
      Please go back to the <Link to="/login">Login page</Link> to login.
    </p>
  </div>
);

export default SuccessfulPasswordResetRequest;
