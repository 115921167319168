import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// import data
import profileContentSecondFields from './data/profleContentSecondFields';

// import hooks
import { useFileReader } from '../../../hooks';
import { useEditModeState } from './hooks';

// import styled components
import {
  ProfileContentSectionSide,
  ProfileContentFieldsWrapper,
  ProfileContentLogoWrapper,
  ProfileContentLogoContainer,
  ProfileContentImageOverlay,
  ProfileContentField,
  ProfileContentFieldLabel,
  ProfileContentFieldInputWrapper,
  ProfileContentAddressFieldsWrapper,
} from './styled';

// import svg
import { ReactComponent as CompanyLogoPlaceholder } from '../../../assets/img/company-logo-placeholder.svg';

function ProfileContentSecond({ dispatch, profileState }) {
  const companyLogoUpload = useRef(null);

  const initialEditModeState = {
    officePhone: false,
    mobilePhone: false,
    website: false,
    birthDate: false,
    address: {
      street: false,
      city: false,
      state: false,
      zip: false,
    },
  };

  const [editModeState, toggleFieldEditMode] = useEditModeState(initialEditModeState);
  const transformImage = useFileReader(dispatch);

  const drawInputFields = fields => {
    return fields.map(field => {
      const { name, label, phone } = field;

      if (phone) {
        return drawPhoneNumberInputField(field);
      }

      if (name === 'address') {
        return drawAddressInputFields(field);
      }

      return (
        <ProfileContentField key={name}>
          <ProfileContentFieldLabel>{label}</ProfileContentFieldLabel>
          <ProfileContentFieldInputWrapper>
            {editModeState[name] ? (
              <input
                type="text"
                name={name}
                value={profileState[name]}
                placeholder={label}
                onChange={e => dispatch({ type: 'inputChange', name, value: e.target.value })}
                autoFocus
              />
            ) : (
              <div onClick={() => toggleFieldEditMode(name)} onKeyPress={() => toggleFieldEditMode(name)} tabIndex="0">
                {profileState[name] === '' ? `Add your ${label}` : profileState[name]}
              </div>
            )}
            <span
              onClick={() => toggleFieldEditMode(name)}
              onKeyPress={() => toggleFieldEditMode(name)}
              className={editModeState[name] ? 'visible' : null}
              tabIndex="0"
            >
              ✓
            </span>
          </ProfileContentFieldInputWrapper>
        </ProfileContentField>
      );
    });
  };

  const drawPhoneNumberInputField = field => {
    const { name, label } = field;

    return (
      <ProfileContentField key={name}>
        <ProfileContentFieldLabel>{label}</ProfileContentFieldLabel>
        <ProfileContentFieldInputWrapper>
          {editModeState[name] ? (
            <NumberFormat
              type="text"
              name={name}
              value={profileState[name]}
              placeholder={label}
              onChange={e => dispatch({ type: 'inputChange', name, value: e.target.value })}
              format="(###) ###-####"
              autoFocus
            />
          ) : (
            <div onClick={() => toggleFieldEditMode(name)} onKeyPress={() => toggleFieldEditMode(name)} tabIndex="0">
              {profileState[name] === '' ? `Add your ${label}` : profileState[name]}
            </div>
          )}
          <span
            onClick={() => toggleFieldEditMode(name)}
            onKeyPress={() => toggleFieldEditMode(name)}
            className={editModeState[name] ? 'visible' : null}
            tabIndex="0"
          >
            ✓
          </span>
        </ProfileContentFieldInputWrapper>
      </ProfileContentField>
    );
  };

  const drawAddressInputFields = field => {
    const { name, label, subFields } = field;
    return (
      <ProfileContentField key={name}>
        <ProfileContentFieldLabel>{label}</ProfileContentFieldLabel>
        <ProfileContentAddressFieldsWrapper>
          {subFields.map(field => {
            const { name, label } = field;

            if (!profileState.address) {
              return null;
            }

            return (
              <ProfileContentFieldInputWrapper key={name}>
                {editModeState.address[name] ? (
                  <input
                    type="text"
                    name={name}
                    value={profileState.address[name]}
                    placeholder={label}
                    onChange={e => dispatch({ type: 'addressInputChange', name, value: e.target.value })}
                    autoFocus
                  />
                ) : (
                  <div
                    onClick={() => toggleFieldEditMode(name, true)}
                    onKeyPress={() => toggleFieldEditMode(name, true)}
                    tabIndex="0"
                  >
                    {profileState.address[name] === '' ? `Add your ${label}` : profileState.address[name]}
                  </div>
                )}
                <span
                  onClick={() => toggleFieldEditMode(name, true)}
                  onKeyPress={() => toggleFieldEditMode(name, true)}
                  className={editModeState.address[name] ? 'visible' : null}
                  tabIndex="0"
                >
                  ✓
                </span>
              </ProfileContentFieldInputWrapper>
            );
          })}
        </ProfileContentAddressFieldsWrapper>
      </ProfileContentField>
    );
  };

  return (
    <ProfileContentSectionSide>
      <ProfileContentLogoWrapper>
        <ProfileContentLogoContainer>
          {profileState.companyLogo ? (
            <img src={profileState.companyLogo} alt={`${profileState.company}`} />
          ) : (
            <CompanyLogoPlaceholder />
          )}
          <ProfileContentImageOverlay
            onClick={e => companyLogoUpload.current.click()}
            onKeyPress={e => companyLogoUpload.current.click()}
            tabIndex="0"
          >
            <p>Upload a logo</p>
          </ProfileContentImageOverlay>
        </ProfileContentLogoContainer>
        <input
          type="file"
          name="companyLogo"
          id="companyLogo"
          accept="image/png, image/jpeg, image/svg"
          ref={companyLogoUpload}
          onChange={e => transformImage(companyLogoUpload, 'companyLogo')}
        />
      </ProfileContentLogoWrapper>
      <ProfileContentFieldsWrapper>{drawInputFields(profileContentSecondFields)}</ProfileContentFieldsWrapper>
    </ProfileContentSectionSide>
  );
}

export default ProfileContentSecond;

ProfileContentSecond.propTypes = {
  dispatch: PropTypes.func.isRequired,
  profileState: PropTypes.object.isRequired,
};
