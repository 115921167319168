import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getFontWeight, darkenColor } from '../../util/theme';

const SubmitButton = styled.button`
  align-self: center;

  background-color: ${getColor('accentOrange')};

  border: 1px solid transparent;
  border-radius: 2px;
  outline: 0;

  color: ${getColor('primaryBlue')};
  font-size: 1.8rem;
  text-align: center;

  padding: 0;
  margin: ${props => (props.margin ? props.margin : '0')};

  height: 40px;
  width: 300px;

  position: relative;

  transition: all 0.4s ease-in;

  cursor: pointer;

  &:hover {
    &::before {
      left: 0;
      right: 0;
    }
  }

  &::before {
    background-color: ${darkenColor('accentOrange', 0.1)};

    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 50%;

    display: inline-block;

    content: '';

    transition: all 0.2s ease;
  }

  &::after {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    border: 1px solid transparent;

    color: ${getColor('primaryBlue')};
    font-size: 1.8rem;
    font-weight: ${getFontWeight('medium')};

    content: 'Submit';
  }

  &:focus {
    outline: 1px solid ${getColor('accentGreen')};
  }
`;

export default SubmitButton;
