import React from 'react';
import PropTypes from 'prop-types';

// import styled components
import { RegisterFailOrSuccessWrapper } from './styled';

const RegisterSubmitFailed = ({ setRegistrationStatusState }) => (
  <RegisterFailOrSuccessWrapper>
    <strong>
      <h3>Oops!</h3>
    </strong>
    <h3 className="regular">Something went wrong...</h3>
    <p>
      There was a problem with your registration. Please return to the{' '}
      <span className="reset" onClick={() => setRegistrationStatusState({ component: 'emailInput', type: null })}>
        registration page
      </span>{' '}
      to register again.
    </p>
  </RegisterFailOrSuccessWrapper>
);

export default RegisterSubmitFailed;

RegisterSubmitFailed.propTypes = {
  setRegistrationStatusState: PropTypes.func.isRequired,
};
