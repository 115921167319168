import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// import helpers
import { getBreakpoint } from '../../../../../../util/theme';

// import assets
import sliderHandle from '../../../../../../assets/slider/slider-handle.svg';

const HandleWrapper = styled.div.attrs(props => ({ style: { left: `${props.percent}%` } }))`
  position: absolute;
  z-index: 2;

  margin: 15px 0 0 -15px;

  width: 22px;
  height: 35px;

  border: 0;

  background-image: url(${sliderHandle});
  background-repeat: no-repeat;
  background-size: cover;

  text-align: center;

  cursor: pointer;

  touch-action: none;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 20px 0 0 -15px;

    width: 25px;
    height: 40px;
  }
`;

export default HandleWrapper;

HandleWrapper.propTypes = {
  percent: PropTypes.number.isRequired,
};
