import styled from 'styled-components/macro';

const RegisterWrapper = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100%;
`;

export default RegisterWrapper;
