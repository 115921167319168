import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../util/theme';

const DashboardLoanCardTitle = styled.div`
  h2 {
    color: ${getColor('titleBlue')};
    font-size: 2rem;
    font-weight: ${getFontWeight('regular')};

    margin: 0 0 10px 0;

    @media (min-width: ${getBreakpoint('md')}) {
      font-size: 2.4rem;
    }
  }
`;

export default DashboardLoanCardTitle;
