import { useCallback } from 'react';

function transformImage(ref, name, dispatch) {
  const reader = new FileReader();

  const file = ref.current.files[0];

  if (!file) return;

  reader.readAsDataURL(file);

  reader.onloadend = ee => {
    const imageResult = {
      [name]: ee.target.result,
    };

    dispatch({ type: 'imageChange', value: imageResult, name, file });

    return imageResult;
  };
}

function useFileReader(dispatch) {
  const transformUploadedImage = useCallback((ref, name) => {
    transformImage(ref, name, dispatch);
  });

  return transformUploadedImage;
}

export default useFileReader;
