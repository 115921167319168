import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getFontWeight } from '../../../../util/theme';

const ProfileContentSubmitMessage = styled.div`
  p {
    font-size: 1.8rem;
    font-weight: ${getFontWeight('medium')};
    text-align: center;

    &.success {
      color: ${getColor('accentGreen')};
    }

    &.error {
      color: ${getColor('accentRed')};
    }
  }
`;

export default ProfileContentSubmitMessage;
