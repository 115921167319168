// import config
import { API_BASE_URL } from '../../../../util/config';

async function submitPasswordUpdate(accessToken, email, passwordState) {
  const formattedPasswordUpdate = JSON.stringify({
    email,
    Password: passwordState.oldPassword,
    NewPassword: passwordState.newPassword,
    ConfirmPassword: passwordState.confirmNewPassword,
  });

  const updatePasswordUrl = `${API_BASE_URL}/api/user/changepassword`;

  const sendPasswordUpdate = await fetch(updatePasswordUrl, {
    method: 'POST',
    headers: {
      Authorization: `bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: formattedPasswordUpdate,
  });

  const passwordUpdateStatus = sendPasswordUpdate.status;
  const passwordUpdateResponse = await sendPasswordUpdate.json();

  return {
    status: passwordUpdateStatus,
    response: passwordUpdateResponse,
  };
}

export default submitPasswordUpdate;
