import { useCallback, useState } from 'react';

function useIsOpen(initialIsOpen) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const toggleIsOpen = useCallback(() => setIsOpen(!isOpen));

  return [isOpen, toggleIsOpen];
}

export default useIsOpen;
