const registerBottomFields = [
  {
    name: 'gender',
    label: 'Gender',
    type: 'gender',
    options: ['Male', 'Female', 'Not Specified'],
    required: false,
    validator: null,
  },
  {
    name: 'officePhone',
    label: 'Office Phone',
    placeholder: '(555) 555-5555',
    type: 'phone',
    required: false,
    validator: 'telValidator',
  },
  {
    name: 'mobilePhone',
    label: 'Mobile Phone',
    placeholder: '(555) 555-5555',
    type: 'phone',
    required: false,
    validator: 'telValidator',
  },
  {
    name: 'website',
    label: 'Website',
    placeholder: 'https://example.com',
    type: 'text',
    error: 'Please enter a valid URL',
    required: false,
    validator: 'lengthValidator',
  },
  {
    name: 'birthDate',
    label: 'Birth Date',
    placeholder: 'mm/dd/yyyy',
    type: 'date',
    required: false,
  },
  {
    name: 'address',
    label: 'Mailing Address',
    type: 'address',
    subFields: [
      {
        name: 'street',
        label: 'Street',
      },
      {
        name: 'city',
        label: 'City',
      },
      {
        name: 'state',
        label: 'State',
        type: 'select',
        options: [
          'Select a State',
          'Alabama',
          'Alaska',
          'Arizona',
          'Arkansas',
          'California',
          'Colorado',
          'Connecticut',
          'Delaware',
          'District of Columbia',
          'Florida',
          'Georgia',
          'Hawaii',
          'Idaho',
          'Illinois',
          'Indiana',
          'Iowa',
          'Kansas',
          'Kentucky',
          'Louisiana',
          'Maine',
          'Maryland',
          'Massachusetts',
          'Michigan',
          'Minnesota',
          'Mississippi',
          'Missouri',
          'Montana',
          'Nebraska',
          'Nevada',
          'New Hampshire',
          'New Jersey',
          'New Mexico',
          'New York',
          'North Carolina',
          'North Dakota',
          'Ohio',
          'Oklahoma',
          'Oregon',
          'Pennsylvania',
          'Rhode Island',
          'South Carolina',
          'South Dakota',
          'Tennessee',
          'Texas',
          'Utah',
          'Vermont',
          'Virginia',
          'Washington',
          'West Virginia',
          'Wisconsin',
          'Wyoming',
        ],
      },
      {
        name: 'zip',
        label: 'Zip Code',
      },
    ],
  },
];

export default registerBottomFields;
