import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor } from '../../../../util/theme';

const PreLoanModifyInputInput = styled.div`
  input {
    border: none;
    border-bottom: 2px solid ${getColor('complimentaryBlue')};

    font-size: 1.8rem;

    /* width: 75%; */

    &:focus,
    &:hover {
      border-bottom: 2px solid ${getColor('accentGreen')};
    }
  }

  button {
    width: 100%;

    margin: 15px 0;

    @media (min-width: ${getBreakpoint('lg')}) {
      margin: 15px 0 0 0;
    }
  }

  svg {
    fill: ${getColor('white')};

    height: auto;
    width: 18px;
  }
`;

export default PreLoanModifyInputInput;
