import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../util/theme';

const LoginLogo = styled.div`
  margin: 0 0 0 -2.5rem;
  padding: 2rem 0;

  width: 22rem;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 0 0 0 -4rem;

    width: 25rem;
  }
`;

export default LoginLogo;
