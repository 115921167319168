import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../../util/theme';

const AfLoanTabTitle = styled.h2`
  color: ${props => (props.active ? getColor('complimentaryBlue') : getColor('medGrey'))};
  font-size: 2rem;
  font-weight: ${props => (props.active ? getFontWeight('regular') : getFontWeight('light'))};

  margin: 0 10px;

  transition: all 0.2s linear;

  @media (min-width: ${getBreakpoint('md')}) {
    font-size: 3rem;
  }
`;

export default AfLoanTabTitle;

AfLoanTabTitle.propTypes = {
  active: PropTypes.bool.isRequired,
};
