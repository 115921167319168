import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor } from '../../../../util/theme';

const RegisterFormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${getColor('backgroundGrey')};

  padding: 15px;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 25px;
  }

  hr {
    width: 90%;

    border: 0;
    border-top: 1px solid ${getColor('lightestGrey')};

    padding: 0 0 25px 0;
  }
`;

export default RegisterFormWrapper;
