import styled from 'styled-components/macro';

// import helpers
import { getColor } from '../../../util/theme';

const LoginWrapper = styled.div`
  background-color: ${getColor('white')};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
`;

export default LoginWrapper;
