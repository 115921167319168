import { useEffect, useState } from 'react';
import { navigate } from '@reach/router';

function useIsUserLoggedIn(setUserState) {
  const [loggedIn, setLoggedIn] = useState(false);
  const checkSessionStorage = sessionStorage.getItem('loanForceInsiderPortal');

  useEffect(() => {
    if (checkSessionStorage) {
      const loggedInUserState = JSON.parse(checkSessionStorage);

      setUserState({ ...loggedInUserState });

      setLoggedIn(true);
    } else {
      navigate('/login');
    }
  }, [checkSessionStorage]);

  return [loggedIn];
}

export default useIsUserLoggedIn;
