import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getFontWeight } from '../../../../util/theme';

const ProfilePasswordFormWrapper = styled.form`
  display: flex;
  flex-direction: column;

  background-color: ${getColor('white')};

  padding: 30px;

  width: 100%;

  div {
    display: flex;
    flex-direction: column;

    padding: 10px 0;
  }

  label {
    color: ${getColor('titleGrey')};
    font-size: 1.4rem;
    font-weight: ${getFontWeight('medium')};
    text-transform: uppercase;

    margin: 10px 0;
  }

  input {
    font-size: 1.6rem;

    height: 40px;
    width: 100%;

    padding: 3px 10px;

    &.error {
      border: 2px solid ${getColor('accentRed')};
    }
  }
`;

export default ProfilePasswordFormWrapper;
