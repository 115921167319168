import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../util/theme';

const RegisterFailedWrapper = styled.div`
  padding: 0 25px;

  p {
    color: ${getColor('black')};
    font-size: 1.8rem;
    font-weight: ${getFontWeight('light')};

    &.reset {
      color: ${getColor('complimentaryBlue')};
      font-weight: ${getFontWeight('medium')};

      cursor: pointer;

      &:hover {
        color: ${getColor('accentOrange')};
      }
    }
  }

  a,
  span {
    font-weight: ${getFontWeight('medium')};

    &:hover {
      color: ${getColor('accentOrange')};
    }
  }

  span {
    color: ${getColor('complimentaryBlue')};

    cursor: pointer;
  }

  h3 {
    color: ${getColor('complimentaryBlue')};
    font-size: 3rem;
    font-weight: ${getFontWeight('bold')};

    margin: 0;
    padding: 0;

    &.regular {
      font-weight: ${getFontWeight('regular')};
    }
  }
`;

export default RegisterFailedWrapper;
