// API returns image data in arrayBuffer sooo
// https://gist.github.com/zzarcon/1a3603b84dac7abe5e3f#file-fetch_blob-js <-- thanks gist
function arrayBufferToBase64(buffer) {
  const bytes = [].slice.call(new Uint8Array(buffer));
  let binary = '';

  bytes.forEach(b => (binary += String.fromCharCode(b)));

  return window.btoa(binary);
}

export default arrayBufferToBase64;
