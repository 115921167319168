import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../../util/theme';

const AfLoanTabsBodyWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;

  @media (min-width: ${getBreakpoint('md')}) {
    justify-content: center;
  }
`;

export default AfLoanTabsBodyWrapper;
