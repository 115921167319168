import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

// import styled components
import { RegisterFailOrSuccessWrapper } from './styled';

const RegisterValidateFailed = ({ failureType, setRegistrationStatusState }) => (
  <RegisterFailOrSuccessWrapper>
    <h3>
      {failureType === 'noLoansAssociated'
        ? 'The email address is not in our system'
        : 'This email has already been registered before'}
    </h3>
    {failureType === 'noLoansAssociated' ? (
      <p>Please check with your MLO and make sure you are using the correct email account.</p>
    ) : (
      <p>
        Forgot your password? <Link to="/forgotPassword">Request a password reset here.</Link>
      </p>
    )}
    {failureType === 'noLoansAssociated' ? (
      <p className="reset" onClick={() => setRegistrationStatusState({ component: 'emailInput', type: null })}>
        Try Again
      </p>
    ) : null}
  </RegisterFailOrSuccessWrapper>
);

export default RegisterValidateFailed;

RegisterValidateFailed.propTypes = {
  failureType: PropTypes.string.isRequired,
  setRegistrationStatusState: PropTypes.func.isRequired,
};
