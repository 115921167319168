import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

// import functions
import { logoutUser } from '../App/functions';

// import assets
import { ReactComponent as InsiderLogo } from '../../assets/logos/insider-logo.svg';
import { ReactComponent as LoanForceLogo } from '../../assets/logos/loanforce-logo.svg';
import { ReactComponent as CogIcon } from '../../assets/icons/cog-icon.svg';
import { ReactComponent as LoginIcon } from '../../assets/icons/login-icon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout-icon.svg';

// import styled components
import { HeaderWrapper, HeaderContent, HeaderLogo, HeaderLinks, HeaderLinkIcons } from './styled';

const Header = ({ loggedIn }) => (
  <HeaderWrapper>
    <HeaderContent>
      <HeaderLogo>
        <Link className="logo-link" to={loggedIn ? '/' : '/login'}>
          <LoanForceLogo className="lfLogo" />
          <InsiderLogo className="insiderLogo" />
        </Link>
      </HeaderLogo>
      <HeaderLinks>
        {loggedIn ? (
          <>
            <Link to="/profile">Profile</Link>
            <p onClick={() => logoutUser()}>Logout</p>
          </>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </HeaderLinks>
      <HeaderLinkIcons>
        {loggedIn ? (
          <>
            <Link to="/profile">
              <CogIcon />
            </Link>
            <Link to="/logout">
              <LogoutIcon onClick={() => logoutUser()} />
            </Link>
          </>
        ) : (
          <Link to="/login">
            <LoginIcon />
          </Link>
        )}
      </HeaderLinkIcons>
    </HeaderContent>
  </HeaderWrapper>
);

export default Header;

Header.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  loggedIn: false,
};
