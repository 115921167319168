import React from 'react';
import styled from 'styled-components/macro';

// import theme helpers
import { getColor } from '../../util/theme';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${props => (props.heightProp ? `${props.heightProp}px` : `100px`)};
  width: ${props => (props.widthProp ? `${props.widthProp}px` : `100px`)};

  align-self: center;

  margin: ${props => (props.marginProp ? props.marginProp : '0 auto')};
`;

const Spinner = styled.svg`
  animation: rotate 2s linear infinite;
  height: ${props => (props.heightProp ? `${props.heightProp}px` : `100px`)};
  width: ${props => (props.widthProp ? `${props.widthProp}px` : `100px`)};

  .path {
    stroke: ${getColor('accentGreen')};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default props => (
  <SpinnerContainer heightProp={props.heightProp} widthProp={props.widthProp} marginProp={props.marginProp}>
    <Spinner heightProp={props.heightProp} widthProp={props.widthProp} viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
    </Spinner>
  </SpinnerContainer>
);
