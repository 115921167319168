import styled from 'styled-components/macro';

// import theme helpers
import { getColor } from '../../../../util/theme';

const RegisterFormField = styled.div`
  flex-basis: 50%;

  display: flex;
  flex-direction: column;

  margin: 0 0 10px 0;
  padding: 0 25px;

  label {
    color: ${getColor('primaryBlue')};

    margin: 10px 0;
  }

  p {
    margin: 0;
    padding: 10px 0 0 0;

    &.error {
      color: ${getColor('accentRed')};
    }
  }

  input[type='radio'] {
    height: auto;
    width: auto;

    margin: 0 10px 0 0;
  }
`;

export default RegisterFormField;
