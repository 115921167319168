import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getFontWeight } from '../../../../util/theme';

const ProfileContentImageOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  height: 100%;
  width: 100%;

  &:focus {
    opacity: 1;
  }

  p {
    color: ${getColor('white')};
    font-weight: ${getFontWeight('medium')};
  }
`;

export default ProfileContentImageOverlay;
