import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../util/theme';

const RegisterFormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  /* rules for IE11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }
`;

export default RegisterFormFieldsContainer;
