import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor } from '../../../util/theme';

const ResetPasswordFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${getColor('backgroundGrey')};

  padding: 15px;

  width: 100%;

  @media (min-width: ${getBreakpoint('md')}) {
    width: auto;
  }
`;

export default ResetPasswordFormWrapper;
