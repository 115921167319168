import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// import theme helpers
import { getBreakpoint, getColor } from '../../../../util/theme';

const PreLoanLetterButton = styled.button`
  color: ${getColor('white')};
  font-size: 1.6rem;

  background-color: ${getColor('buttonTeal')};

  display: flex;

  padding: ${props => (props.level === 'one' ? '7px 30px' : '7px 20px')};

  border: 0;
  border-radius: 2px;

  cursor: pointer;

  &:disabled {
    opacity: 0.75;

    cursor: not-allowed;
  }

  &.download {
    background-color: ${getColor('accentRed')};
    margin: 25px 0 0 0;
  }

  /**
  * only implement hover state on desktop display
  * iPhone will trigger hover event on first click otherwise @_@
  */
  @media (min-width: ${getBreakpoint('lg')}) {
    &:hover {
      background-color: ${getColor('accentOrange')};
      background-color: none;
    }

    &.download {
      &:hover {
        background-color: ${getColor('accentGreen')};
      }
    }
  }

  svg {
    margin: 0 20px 0 0;
  }
`;

export default PreLoanLetterButton;

PreLoanLetterButton.propTypes = {
  level: PropTypes.string.isRequired,
};

PreLoanLetterButton.defaultProps = {
  level: '7px 20px',
};
