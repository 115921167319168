import React, { useState } from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';

// import hooks
import { useModifyLoanReducer, usePreloadRegeneratedLetter } from './hooks';

// import components
import PreLoanHistory from './PreLoanHistory';
import PreLoanInformation from './PreLoanInformation';
import PreLoanLetter from './PreLoanLetter';
import PreLoanModify from './PreLoanModify';
import PreLoanSuccess from './PreLoanSuccess';

// import styled components
import { PreLoanBody, PreLoanSectionWrapper } from './styled';
import { Grid } from '../../styled';

const TransitionComponent = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    delay: 300,
    transition: {
      opacity: { duration: 250 },
    },
  },
  exit: {
    x: 250,
    opacity: 0,
    transition: { opacity: { duration: 250 } },
  },
});

const HistoryTransitionComponent = posed.div({});

function PreLoan({ loanData }) {
  /**
   * bail out of rendering if loanData prop is still an active/funded loan, only prequal/preapproved have constraints property
   * this can happen when navigating directly from one loan to another via the drawer menu, if it tries to render a PreLoan before loanData has updated
   * check could be changed to a different property or loan type if desired idk
   */
  if (!loanData.hasOwnProperty('constraints')) {
    return null;
  }

  const {
    loanInformation: { loanAmount, loanToValue, purchasePrice },
    permissions: { levelOne, levelTwo },
  } = loanData;

  const [modifiedLoan, dispatch] = useModifyLoanReducer({
    initialValues: {
      purchasePrice: purchasePrice.value,
      loanAmount: loanAmount.value,
      loanToValue: parseFloat(loanToValue.value).toFixed(2),
    },
    purchasePrice: purchasePrice.value,
    inputPurchasePrice: parseInt(purchasePrice.value).toLocaleString(),
    letterPurchasePrice: purchasePrice.value,
    loanAmount: loanAmount.value,
    inputLoanAmount: parseInt(loanAmount.value).toLocaleString(),
    letterLoanAmount: loanAmount.value,
    loanToValue: parseFloat(loanToValue.value).toFixed(2),
    loanLetters: [...loanData.loanLetters],
    constraints: loanData.constraints,
    success: false,
  });

  const [preloadedLetterData, fileName, loadingLetter] = usePreloadRegeneratedLetter(
    loanData.loanType,
    loanData.letterInformation
  );

  const [modifyingLetter, setModifyingLetter] = useState(false);
  const [loanInformationVisible, setLoanInformationVisible] = useState(true);

  const toggleModify = mobile => {
    if (mobile) {
      setLoanInformationVisible(!loanInformationVisible);
    }

    setModifyingLetter(!modifyingLetter);
  };

  /**
   * level one only permissions - return read only components
   * no animations for this, content is totally static
   */
  if (levelOne === 'True' && levelTwo === 'False') {
    return (
      <>
        <Grid>
          <PreLoanBody>
            <PreLoanInformation
              loanData={loanData}
              letterData={preloadedLetterData}
              fileName={fileName}
              loadingLetter={loadingLetter}
              levelOne={levelOne}
              levelTwo={levelTwo}
            />
            <PreLoanSectionWrapper>
              <PreLoanLetter
                loanData={loanData}
                letterData={preloadedLetterData}
                fileName={fileName}
                loadingLetter={loadingLetter}
                levelOne={levelOne}
                levelTwo={levelTwo}
              />
            </PreLoanSectionWrapper>
          </PreLoanBody>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid>
        <PoseGroup flipMove={false}>
          {modifiedLoan.success ? (
            <TransitionComponent key="success">
              <PreLoanBody>
                <PreLoanSuccess
                  loanData={loanData}
                  loanLetters={modifiedLoan.loanLetters}
                  modifiedLoan={modifiedLoan}
                  dispatch={dispatch}
                  setModifyingLetter={setModifyingLetter}
                />
              </PreLoanBody>
            </TransitionComponent>
          ) : (
            <TransitionComponent key="unsuccess">
              <PreLoanBody>
                <PreLoanInformation
                  loanData={loanData}
                  letterData={preloadedLetterData}
                  fileName={fileName}
                  loadingLetter={loadingLetter}
                  toggleModify={toggleModify}
                  modifyingLetter={modifyingLetter}
                  loanInformationVisible={loanInformationVisible}
                />
                <PreLoanSectionWrapper>
                  <PoseGroup flipMove={true}>
                    {modifyingLetter ? (
                      <TransitionComponent key="modify">
                        <PreLoanModify
                          toggleModify={toggleModify}
                          loanData={loanData}
                          modifiedLoan={modifiedLoan}
                          dispatch={dispatch}
                        />
                      </TransitionComponent>
                    ) : (
                      <TransitionComponent key="letter">
                        <PreLoanLetter
                          loanData={loanData}
                          letterData={preloadedLetterData}
                          fileName={fileName}
                          loadingLetter={loadingLetter}
                          toggleModify={toggleModify}
                          levelOne={levelOne}
                          levelTwo={levelTwo}
                        />
                      </TransitionComponent>
                    )}
                    <HistoryTransitionComponent key="history">
                      <PreLoanHistory loanLetters={modifiedLoan.loanLetters} />
                    </HistoryTransitionComponent>
                  </PoseGroup>
                </PreLoanSectionWrapper>
              </PreLoanBody>
            </TransitionComponent>
          )}
        </PoseGroup>
      </Grid>
    </>
  );
}

export default PreLoan;

PreLoan.propTypes = {
  loanData: PropTypes.object.isRequired,
};
