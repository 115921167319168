import { useReducer } from 'react';
import { format } from 'date-fns';

function calculateNewLoanValues(state, value, name) {
  if (name === 'loanAmount') {
    const maxLoanAmount = state.purchasePrice * state.constraints.ltv;
    if (value >= maxLoanAmount) {
      return {
        loanToValue: ((maxLoanAmount / state.purchasePrice) * 100).toFixed(2),
        /**
         * add as small an increment as possible so that the loanAmount value is always new
         * without this it gets fixed at maxLoanAmount and won't update on the slider
         * use loanLetterAmount to store the value rounded to the hundred
         */
        loanAmount: maxLoanAmount - value * 0.0001,
        inputLoanAmount: parseInt(maxLoanAmount).toLocaleString(),
        letterLoanAmount: maxLoanAmount,
      };
    }

    const newLtv = ((value / state.purchasePrice) * 100).toFixed(2);

    return {
      loanToValue: newLtv,
      loanAmount: value,
      inputLoanAmount: parseInt(value).toLocaleString(),
      letterLoanAmount: value,
    };
  }

  if (name === 'purchasePrice') {
    const minPurchasePrice = state.constraints.loanAmount.min / state.constraints.ltv;

    if (value <= minPurchasePrice) {
      return {
        loanToValue: ((state.loanAmount / minPurchasePrice) * 100).toFixed(2),
        purchasePrice: minPurchasePrice + value * 0.0001,
        inputPurchasePrice: minPurchasePrice.toLocaleString(),
        letterPurchasePrice: minPurchasePrice,
        loanAmount: state.constraints.loanAmount.min,
        inputLoanAmount: state.constraints.loanAmount.min,
        letterLoanAmount: state.constraints.loanAmount.min,
      };
    }

    const loanAmountToPurchasePrice = state.loanAmount / value;

    if (loanAmountToPurchasePrice >= state.constraints.ltv) {
      return {
        loanToValue: (state.constraints.ltv * 100).toFixed(2),
        purchasePrice: value,
        inputPurchasePrice: value.toLocaleString(),
        letterPurchasePrice: value,
        loanAmount: value * state.constraints.ltv,
        inputLoanAmount: (value * state.constraints.ltv).toLocaleString(),
        letterLoanAmount: value * state.constraints.ltv,
      };
    }

    /** this condition might be the same as above idk my head hurts, test/remove later if so */
    if (value <= state.loanAmount) {
      // round adjusted loan amount to the nearest hundred so letter value matches what slider says
      const adjustedLoanAmount = Math.ceil(((value * state.constraints.ltv) / 100) * 100);

      return {
        purchasePrice: value,
        inputPurchasePrice: value.toLocaleString(),
        letterPurchasePrice: value,
        loanAmount: adjustedLoanAmount,
        letterLoanAmount: adjustedLoanAmount,
      };
    }

    const newLtv = ((state.loanAmount / value) * 100).toFixed(2);

    return {
      loanToValue: newLtv,
      purchasePrice: value,
      inputPurchasePrice: value.toLocaleString(),
      letterPurchasePrice: value,
    };
  }
}

function modifyLoanReducer(state, action) {
  const { type, name, value } = action;

  switch (type) {
    case 'modifyLoan':
      const valueChanges = calculateNewLoanValues(state, value, name);

      return {
        ...state,
        ...valueChanges,
      };
    case 'modifyLoanFromInput':
      // determine if purchase price or loan amount modified
      const whichField = `${name.charAt(5).toLowerCase()}${name.substr(6, name.length)}`;
      // transform react-number-field formatted string into number for calculations
      const numericalFieldValue = parseInt(state[name].replace('$', '').replace(',', ''));

      const valueChangesFromInput = calculateNewLoanValues(state, numericalFieldValue, whichField);
      return {
        ...state,
        ...valueChangesFromInput,
      };
    case 'modifyLoanInput':
      return {
        ...state,
        [name]: value,
      };
    case 'resetValues':
      return {
        ...state,
        loanAmount: state.initialValues.loanAmount,
        inputLoanAmount: parseInt(state.initialValues.loanAmount).toLocaleString(),
        letterLoanAmount: state.initialValues.loanAmount,
        purchasePrice: state.initialValues.purchasePrice,
        inputPurchasePrice: parseInt(state.initialValues.purchasePrice).toLocaleString(),
        letterPurchasePrice: state.initialValues.purchasePrice,
        loanToValue: state.initialValues.loanToValue,
      };
    case 'addLoanLetter':
      const modifiedLoanLetterEntry = {
        ...state.loanLetters[0],
        createdOn: format(new Date(), 'M/D/YYYY'),
        loanAmount: state.loanAmount,
        purchasePrice: state.purchasePrice,
      };

      return {
        ...state,
        loanLetters: [modifiedLoanLetterEntry, ...state.loanLetters],
      };
    case 'toggleSuccess':
      return {
        ...state,
        success: !state.success,
      };
    default:
      return { ...state };
  }
}

function useModifyLoanReducer(initialLoanValues) {
  const [modifiedLoan, dispatch] = useReducer(modifyLoanReducer, initialLoanValues);

  return [modifiedLoan, dispatch];
}

export default useModifyLoanReducer;
