import { useEffect, useState } from 'react';

function extractLoanPartners(loans) {
  const allLoanPartners = loans.map(loan => {
    return {
      name: loan.loanOfficerName,
      phone: loan.loanOfficerPhone,
    };
  });

  const removeLoanPartnerDuplicates = (arr, prop) => {
    const propAlreadyExists = new Set();

    return arr.filter(el => !propAlreadyExists.has(el[prop]) && propAlreadyExists.add(el[prop]));
  };

  return removeLoanPartnerDuplicates(allLoanPartners, 'name');
}

function useGetLoanPartners(rawLoans) {
  const [loanPartners, setLoanPartners] = useState([]);

  useEffect(() => {
    const extractedLoanPartners = extractLoanPartners(rawLoans, loanPartners);
    setLoanPartners(extractedLoanPartners);
  }, [rawLoans.length]);

  return loanPartners;
}

export default useGetLoanPartners;
