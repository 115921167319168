import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../util/theme';

// import styled components
import { Grid } from '../../styled';

const DashboardWrapper = styled(Grid)`
  padding: 10px;

  h2 {
    color: ${getColor('titleBlue')};
    font-size: 2rem;
    font-weight: ${getFontWeight('light')};
    line-height: 1.5;

    margin: 0;

    @media (min-width: ${getBreakpoint('md')}) {
      font-size: 3rem;
    }
  }
`;

export default DashboardWrapper;
