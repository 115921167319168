import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';

// import styled components
import { ModalScreen, ModalWindow, ModalClose, ModalDownloadButton } from './styled';

// import svg
import { ReactComponent as DownloadLetterIcon } from '../../assets/buttons/download-letter.svg';

function Modal(props) {
  /**
   * react-pdf workaround to change where pdf.worker.js is loaded from
   * see https://github.com/wojtekmaj/react-pdf/wiki/Known-issues
   */
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const { display, overflow, position, height, width, data, showDownloadButton, fileName } = props.attr;

  // const [, downloadLetterPdf] = useLetterActions(data, 'derp.pdf');

  /**
   * replicating this here instead of importing hook b/c closure over passed-in data on render
   * so doesn't work properly, tries to download initial null data
   */
  const downloadLetterPdf = (data, fileName) => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(data);
    link.href = url;
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
    // window.open(url);
  };

  return (
    <ModalScreen
      style={{
        display,
        overflow,
        position,
      }}
    >
      <ModalWindow height={height} width={width}>
        {showDownloadButton ? (
          <ModalDownloadButton onClick={() => downloadLetterPdf(data, fileName)}>
            <DownloadLetterIcon /> Download Letter
          </ModalDownloadButton>
        ) : null}
        <Document file={data}>
          <Page pageNumber={1} scale={1.1} />
        </Document>
      </ModalWindow>
      <ModalClose onClick={() => props.toggleModal()} onKeyPress={() => props.toggleModal()} tabIndex="0">
        ╳
      </ModalClose>
    </ModalScreen>
  );
}

export default Modal;

Modal.propTypes = {
  attr: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
