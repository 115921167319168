import styled from 'styled-components/macro';

// import helpers
import { getColor } from '../../../../util/theme';

const AfLoanStatusContainer = styled.div`
  background-color: ${getColor('white')};

  flex-basis: 45%;

  height: 100%;

  margin: 25px 0;
`;

export default AfLoanStatusContainer;
