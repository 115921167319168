import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// import components
import PreLoanModifyButtons from './PreLoanModifyButtons';
import PreLoanModifyDownload from './PreLoanModifyDownload';
import PreLoanModifyInput from './PreLoanModifyInput';

// import styled components
import { PreLoanTitle } from '../styled';

function PreLoanModify({ dispatch, loanData, modifiedLoan, toggleModify }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <PreLoanTitle>Modify Letter</PreLoanTitle>
      <PreLoanModifyInput modifiedLoan={modifiedLoan} dispatch={dispatch} isMobile={isMobile} />
      <PreLoanModifyButtons
        toggleModify={toggleModify}
        loanData={loanData}
        modifiedLoan={modifiedLoan}
        dispatch={dispatch}
        isMobile={isMobile}
      />
      <PreLoanModifyDownload loanData={loanData} modifiedLoan={modifiedLoan} dispatch={dispatch} />
    </>
  );
}

export default PreLoanModify;

PreLoanModify.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanData: PropTypes.object.isRequired,
  modifiedLoan: PropTypes.object.isRequired,
  toggleModify: PropTypes.func.isRequired,
};
