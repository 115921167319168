import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import components
import AfLoanTabsHeader from './AfLoanTabsHeader';
import AfLoanContacts from './AfLoanContacts';
import AfLoanKeyDates from './AfLoanKeyDates';

// import styled components
import { AfLoanDatesAndContactsWrapper, AfLoanTabsBodyWrapper } from './styled';

function AfLoanDatesAndContacts({ loanData: { contacts, keyDatesMap } }) {
  const [activeTab, setActiveTab] = useState('contacts');

  return (
    <AfLoanDatesAndContactsWrapper>
      <AfLoanTabsHeader active={activeTab} setActiveTab={setActiveTab} />
      <AfLoanTabsBodyWrapper>
        {activeTab === 'dates' ? <AfLoanKeyDates keyDatesMap={keyDatesMap} /> : <AfLoanContacts contacts={contacts} />}
      </AfLoanTabsBodyWrapper>
    </AfLoanDatesAndContactsWrapper>
  );
}

export default AfLoanDatesAndContacts;

AfLoanDatesAndContacts.propTypes = {
  loanData: PropTypes.object.isRequired,
};
