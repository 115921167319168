import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../util/theme';

const LoginFormInputGroup = styled.div`
  width: 30rem;

  margin: 1rem auto;

  text-align: left;

  display: flex;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;

    position: relative;

    color: ${getColor('white')};
    font-size: 2rem;
    font-weight: ${getFontWeight('medium')};

    margin: 0;
  }

  span {
    color: ${getColor('white')};
    font-size: 1.6rem;
    font-weight: ${getFontWeight('light')};

    &.tooltip {
      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid ${getColor('white')};
      border-radius: 50%;

      height: 2rem;
      width: 2rem;

      margin: 0 10px;

      &:hover,
      &:focus {
        ~ span {
          display: block;
        }
      }
    }

    &.tooltip-content {
      display: none;

      position: absolute;
      right: -25px;
      bottom: 25px;

      background-color: ${getColor('accentOrange')};

      color: ${getColor('primaryBlue')};
      font-weight: ${getFontWeight('medium')};
      text-transform: none;

      width: 225px;

      padding: 10px;

      &:hover,
      &:focus {
        display: block;
      }
    }
  }

  input {
    border: 1px solid transparent;
    border-radius: 2px;
    box-shadow: none;
    outline: 0;

    font-size: 1.3rem;

    height: 40px;
    width: 100%;

    padding: 3px 10px;

    ::placeholder {
      color: ${getColor('lightGrey')};
    }

    &:focus {
      outline: 1px solid ${getColor('accentGreen')};

      ::placeholder {
        color: ${getColor('lightestGrey')};
      }
    }
  }
`;

export default LoginFormInputGroup;
