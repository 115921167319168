import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../util/theme';

const LoanHeaderTitle = styled.div`
  border-bottom: 1px solid ${getColor('lightestGrey')};

  margin: 0 0 25px 0;

  h1 {
    color: ${getColor('titleBlue')};

    font-size: 3rem;
    font-weight: ${getFontWeight('regular')};

    margin: 0;
    padding: 0;
  }
`;

export default LoanHeaderTitle;
