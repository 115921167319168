import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const DrawerItemListWrapper = styled.div`
  /* if transition not desired just use display */
  /* display: ${props => (props.isOpen && props.expanded ? 'block' : 'none')}; */

  /* various other ways of a slide-up transition if desired
  * these don't rely on JS to provide height but have various downsides!
  */
  /* height: ${props => (props.isOpen && props.expanded ? '100%' : 0)};
  max-height: ${props => (props.isOpen && props.expanded ? '1000px' : 0)};
  transform-origin: top;
  transform: ${props => (props.isOpen && props.expanded ? 'scaleY(1)' : 'scaleY(0)')}; */

  /* this one uses a ref to get the height at render time */
  height: ${props => (props.isOpen && props.expanded ? `${props.refHeight + 20}px` : 0)};
  width: 100%;

  overflow: hidden;

  transition: all 0.25s;

  &.profile {
    height: 65px;

    li {
      border-bottom: none;
    }
  }
`;

export default DrawerItemListWrapper;

DrawerItemListWrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  refHeight: PropTypes.number.isRequired,
};

/**
 * set default props here bc initial render returns undefined for isOpen and refHeight
 * guessing that the styled component is rendered before state & ref values are initialized
 */
DrawerItemListWrapper.defaultProps = {
  isOpen: false,
  refHeight: 0,
};
