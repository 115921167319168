import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// import hooks & functions
import { useIsOpen } from '../../../hooks';
import { setIsOpenAndSetExpanded } from './functions';

// import styled components
import { DrawerItem, DrawerItemTitle, DrawerItemListWrapper, DrawerItemList } from './styled';

// import svg
import partnerIcon from '../../../assets/icons/partner-icon.svg';
import { ReactComponent as DrawerArrow } from '../../../assets/ui/drawer-arrow.svg';

function DrawerPartners({ expanded, toggleDrawer, loanPartners }) {
  const partnersListRef = useRef(null);
  const [isOpen, setIsOpen] = useIsOpen(true);

  return (
    <>
      <DrawerItem expanded={expanded}>
        <img
          src={partnerIcon}
          alt="Loans"
          onClick={() => setIsOpenAndSetExpanded(isOpen, setIsOpen, expanded, toggleDrawer)}
        />
        <DrawerItemTitle expanded={expanded} onClick={() => setIsOpen()}>
          <span>Loan Partners</span>
          <DrawerArrow className={isOpen ? null : 'isClosed'} />
        </DrawerItemTitle>
      </DrawerItem>
      <DrawerItemListWrapper
        isOpen={isOpen}
        expanded={expanded}
        refHeight={partnersListRef.current ? partnersListRef.current.clientHeight : 0}
      >
        {loanPartners ? (
          <DrawerItemList ref={partnersListRef}>
            {loanPartners.map(({ name, phone }) => {
              if (!name) return null;
              return (
                <li key={name}>
                  <span>{name}</span>
                  <span>{phone}</span>
                </li>
              );
            })}
          </DrawerItemList>
        ) : null}
      </DrawerItemListWrapper>
    </>
  );
}

export default DrawerPartners;

DrawerPartners.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loanPartners: PropTypes.array.isRequired,
};
