import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../util/theme';

// import reuseable styled components
import { Grid } from '../../styled';

const ForgotPasswordContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;

  width: 100%;

  h1 {
    color: ${getColor('titleBlue')};
    font-weight: ${getFontWeight('thin')};

    margin: 25px 0 25px 0;

    @media (min-width: ${getBreakpoint('md')}) {
      margin: 0 0 75px 0;
    }
  }
`;

export default ForgotPasswordContainer;
