import React, { createContext, useRef, useState } from 'react';
import { Router } from '@reach/router';
import PropTypes from 'prop-types';

// import context
import { LoggedInContext as InsiderLoggedInContext } from '../Context';

// import hooks
import { useBrandFinder } from '../../hooks';
import { useModal } from '../Modal/hooks';

// import components
import AppLoader from './AppLoader';
import Drawer from './Drawer';
import Header from '../Header';
import Footer from '../Footer';
import Dashboard from '../Dashboard';
import Loan from '../Loan';
import Profile from '../Profile/index';
import Modal from '../Modal';

// import styled components
import { AppContentWrapper, AppContentContainer, SkipToContentLink } from './styled';

// context
export const AppContext = createContext();

/** use location.origin from router to determine brand - see prop types below */
function App({ location: { origin } }) {
  // modal state
  const [modalAttr, toggleModal] = useModal();

  // drawer expansion
  const drawerWrapperRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const closeMenu = () => {
    drawerWrapperRef.current.scrollTop = 0;
    if (expanded) setExpanded(false);
  };

  // brand state
  const [brandState] = useBrandFinder(origin);

  const appContextValues = {
    functions: {
      toggleModal,
    },
  };

  return (
    <InsiderLoggedInContext>
      <AppContext.Provider value={appContextValues}>
        <AppLoader>
          <SkipToContentLink href="#main">Skip to Main Content</SkipToContentLink>
          <Modal attr={modalAttr} toggleModal={toggleModal} />
          <Drawer expanded={expanded} setExpanded={setExpanded} drawerWrapperRef={drawerWrapperRef} />
          <AppContentWrapper id="content-wrapper" onClick={() => closeMenu()}>
            <Header loggedIn={true} />
            <AppContentContainer>
              <Router primary={false}>
                <Dashboard path="/" />
                <Profile path="/profile/*" />
                <Loan path="/loans/:id" />
              </Router>
            </AppContentContainer>
            <Footer brand={brandState} />
          </AppContentWrapper>
        </AppLoader>
      </AppContext.Provider>
    </InsiderLoggedInContext>
  );
}

export default App;

/**
 * App props are all from router
 * the only one it needs is location, but all of them come through so including here
 */
App.propTypes = {
  '*': PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};

/**
 * set default props bc all return undefined on initial render
 */
App.defaultProps = {
  '*': '',
  location: {},
  navigate: () => null,
  path: '',
  uri: '',
};
