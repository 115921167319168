import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../util/theme';

const HeaderLinks = styled.div`
  margin: 25px 0;
  padding: 0 50px;

  align-self: flex-end;

  display: none;

  @media (min-width: ${getBreakpoint('md')}) {
    display: block;
  }

  a,
  p {
    color: ${getColor('primaryBlue')};
    font-size: 1.6rem;
    font-weight: ${getFontWeight('medium')};
    margin: 0;
    padding: 10px 15px;

    display: inline-block;

    cursor: pointer;

    &:hover {
      color: ${getColor('white')};

      background-color: ${getColor('accentOrange')};
    }
  }
`;

export default HeaderLinks;
