function processKeyDates(dates = {}) {
  const keyDatesList = [
    {
      text: 'CD Issued',
      date: '',
    },
    {
      text: 'Title Requested',
      date: '',
    },
    {
      text: 'Title Received',
      date: '',
    },
    {
      text: 'Appraisal Ordered',
      date: '',
    },
    {
      text: 'Appraisal Received',
      date: '',
    },
    {
      text: 'Appraisal Inspection Date',
      date: '',
    },
    {
      text: 'Inspection Completed',
      date: '',
    },
    {
      text: 'Expected Signing',
      date: '',
    },
    {
      text: 'Expected Close',
      date: '',
    },
    {
      text: 'Docs Delivered',
      date: '',
    },
    {
      text: 'Funding',
      date: '',
    },
  ];

  const datesArray = Array.from(dates).map((date, i) => {
    if (date) {
      keyDatesList[i]['date'] = date[1].substr(0, date[1].indexOf(' '));
    }

    return datesArray;
  });

  return keyDatesList;
}

export default processKeyDates;
