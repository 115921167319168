import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../../util/theme';

const AfLoanContactsGroupRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
  }
`;

export default AfLoanContactsGroupRow;
