import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const PreLoanLetterButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'space-between')};

  /* rules for IE11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    justify-content: space-around;
  }

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
  }

  button {
    width: 75%;

    margin: 0 0 15px 0;

    justify-content: center;

    @media (min-width: ${getBreakpoint('md')}) {
      width: auto;

      margin: 0;
    }
  }

  &.mobile-only {
    @media (min-width: ${getBreakpoint('md')}) {
      display: none;
    }
  }
`;

export default PreLoanLetterButtonWrapper;

PreLoanLetterButtonWrapper.propTypes = {
  justifyContent: PropTypes.string.isRequired,
};

PreLoanLetterButtonWrapper.defaultProps = {
  justifyContent: 'space-between',
};
