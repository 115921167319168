import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const ProfileContentSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.align ? props.align : 'flex-start')};

  padding: 25px 0 0 0;

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
  }
`;

export default ProfileContentSectionWrapper;

ProfileContentSectionWrapper.propTypes = {
  align: PropTypes.string.isRequired,
};

/**
 * probably redundant
 */
ProfileContentSectionWrapper.defaultProps = {
  align: 'flex-start',
};
