import styled from 'styled-components/macro';

const AppContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  overflow: hidden;

  position: relative;
`;

export default AppContentContainer;
