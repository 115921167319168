import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const AfLoanStatusTop = styled.div`
  background-color: ${getColor('coolBlue')};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 10px;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 15px;
  }

  h2,
  h3 {
    color: ${getColor('white')};
    margin: 0;
  }

  h2 {
    font-size: 3rem;
    font-weight: ${getFontWeight('regular')};

    @media (min-width: ${getBreakpoint('md')}) {
      font-size: 4rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: ${getFontWeight('light')};
  }
`;

export default AfLoanStatusTop;
