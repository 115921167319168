import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor } from '../../../util/theme';

const ProfileHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid ${getColor('lightestGrey')};

  padding: 0 10px 10px 10px;
  margin: 0 0 25px 0;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 0 0 10px 0;
  }

  div {
    display: flex;
    align-items: center;
  }

  svg {
    height: 50px;
    width: 50px;

    margin: 0 16px 0 0;
  }

  h1 {
    color: ${getColor('titleBlue')};
    font-size: 3rem;

    margin: 0;
  }
`;

export default ProfileHeaderWrapper;
