import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';

// import context
import { LoggedInContext } from '../Context/LoggedInContext';

// import styled components
import { Spinner } from '../styled';
import { DashboardWelcome, DashboardWrapper } from './styled';

// import components
import DashboardLoanList from './DashboardLoanList';

/**
 * does not make use of any props, but does receive router props - see prop types below
 */
function Dashboard() {
  const {
    state: {
      profileData: { firstName, lastName },
      processedLoans: { active, funded, prequalified, preapproved },
    },
    errors: { loansError },
    loading: { loansLoading },
  } = useContext(LoggedInContext);

  const loanTypes = [
    { type: 'prequalified', title: 'Pre-Qualified Loans', loans: prequalified, defaultOpen: true },
    { type: 'preapproved', title: 'Pre-Approved Loans', loans: preapproved, defaultOpen: true },
    {
      type: 'active',
      title: 'In Process Loans',
      loans: active,
      defaultOpen: true,
    },
    {
      type: 'funded',
      title: 'Funded Loans',
      loans: funded,
      defaultOpen: false,
    },
  ];

  const drawLoanLists = () => {
    const loanTypesWithLoans = loanTypes.filter(type => type.loans.length > 0);

    if (loanTypesWithLoans.length === 0) {
      return (
        <p>
          No loans found for {firstName} {lastName}.
        </p>
      );
    }

    return loanTypes.map(type => {
      if (type.loans.length > 0) {
        return (
          <DashboardLoanList
            key={type.type}
            title={type.title}
            type={type.type}
            loans={type.loans}
            defaultOpen={type.defaultOpen}
          />
        );
      }

      return null;
    });
  };

  if (loansError) {
    return (
      <DashboardWrapper>
        <DashboardWelcome aria-label="welcome">
          <h2>Welcome,</h2>
          <h1>
            {firstName} {lastName}
          </h1>
        </DashboardWelcome>
        <p>There was an error loading loans. Please try again later.</p>
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper>
      <DashboardWelcome aria-label="welcome">
        <h2>Welcome,</h2>
        <h1>
          {firstName} {lastName}
        </h1>
      </DashboardWelcome>
      {loansLoading ? <Spinner /> : <section aria-label="loan dashboard" id="main">{drawLoanLists()}</section>}
    </DashboardWrapper>
  );
}

export default memo(Dashboard);

/**
 * Dashboard props are all from router
 * it doesn't actually use any of these but including for awareness
 */
Dashboard.propTypes = {
  '*': PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};

/**
 * set default props bc all return undefined on initial render
 */
Dashboard.defaultProps = {
  '*': '',
  location: {},
  navigate: () => null,
  path: '',
  uri: '',
};
