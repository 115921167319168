import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../util/theme';

const LoginFormFooter = styled.div`
  text-align: center;

  margin: 0 auto;

  a,
  p {
    font-size: 2rem;
    font-weight: ${getFontWeight('medium')};
    line-height: 1.5;
  }

  p {
    color: ${getColor('black')};
  }

  a {
    color: ${getColor('primaryBlue')};
    text-decoration: underline;

    &:hover {
      color: ${getColor('medGrey')};
    }
  }
`;

export default LoginFormFooter;
