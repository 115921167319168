import styled from 'styled-components/macro';
import { Link } from '@reach/router';

// import helpers
import { getColor } from '../../../util/theme';

const ProfileHeaderRightLink = styled(Link)`
  color: ${getColor('cardBlue')};
  font-size: 1.4rem;

  border: 1px solid ${getColor('cardBlue')};
  border-radius: 2px;

  padding: 1rem;

  &:hover {
    border: 1px solid ${getColor('accentOrange')};

    background-color: ${getColor('accentOrange')};

    color: ${getColor('white')};
  }
`;

export default ProfileHeaderRightLink;
