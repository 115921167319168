import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor } from '../../../util/theme';

const FooterText = styled.div`
  background-color: ${getColor('black')};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${getColor('white')};
  font-size: 1.3rem;

  height: 40px;
  width: 100%;

  @media (min-width: ${getBreakpoint('md')}) {
    justify-content: flex-start;

    padding: 0 0 0 315px;
  }

  a {
    color: ${getColor('white')};
    text-decoration: underline;

    &:hover {
      color: ${getColor('accentGreen')};
    }
  }
`;

export default FooterText;
