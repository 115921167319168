import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor } from '../../../util/theme';

const HeaderWrapper = styled.header`
  background-color: ${getColor('white')};

  height: 50px;
  width: 100%;

  position: fixed;
  top: 0;

  z-index: 9998;

  @media (min-width: ${getBreakpoint('md')}) {
    border-bottom: 2px solid ${getColor('lightestGrey')};

    height: auto;

    position: relative;
  }
`;

export default HeaderWrapper;
