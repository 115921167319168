import styled from 'styled-components/macro';

// import helpers
import { getColor } from '../../../util/theme';

const RegisterInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${getColor('backgroundGrey')};

  padding: 15px;
`;

export default RegisterInputWrapper;
