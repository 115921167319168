import React from 'react';
import PropTypes from 'prop-types';

// import hooks
import { useBrandFinder } from '../../hooks';

// import components
import Header from '../Header';
import Footer from '../Footer';
import ResetPasswordForm from './ResetPasswordForm';

// import styled components
import { ResetPasswordWrapper, ResetPasswordContainer } from './styled';

function ResetPassword({ location }) {
  const [brandState] = useBrandFinder(location.origin);

  return (
    <ResetPasswordWrapper>
      <Header />
      <ResetPasswordContainer>
        <h1>Reset Your Password</h1>
        <ResetPasswordForm location={location} />
      </ResetPasswordContainer>
      <Footer brand={brandState} />
    </ResetPasswordWrapper>
  );
}

export default ResetPassword;

/**
 * ResetPassword props are all from router
 * the only one it needs is location, but all of them come through so including here
 */
ResetPassword.propTypes = {
  '*': PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};

/**
 * set default props bc all return undefined on initial render
 */
ResetPassword.defaultProps = {
  '*': '',
  location: {},
  navigate: () => null,
  path: '',
  uri: '',
};
