import { darken, rgba } from 'polished';

const theme = {
  // OneTrust colors plus additional design colors
  colors: {
    // blues
    primaryBlue: '#2d536f',
    complimentaryBlue: '#5481a3',
    titleBlue: '#5682a4',
    cardBlue: '#4f80b1',
    coolBlue: '#6aa8e7',
    controlBlue: '#1b75bc',
    buttonTeal: '#1997bf',
    electricBlue: '#459dce',
    // other misc
    accentGreen: '#99c24d',
    accentOrange: '#eab345',
    accentRed: '#fd5f54',
    // monotones
    black: '#323232',
    white: '#ffffff',
    lightestGrey: '#d1d3d4',
    lightGrey: '#bcbec0',
    medGrey: '#808080',
    titleGrey: '#707071',
    backgroundGrey: '#f2f2f2',
    borderGrey: '#b3b3b3',
    cardGrey: '#e6e6e6',
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  typography: {
    // correspond with Google font actual weights for Roboto
    weights: {
      thin: '100',
      light: '300',
      regular: '400',
      medium: '500',
      bold: '700',
      black: '900',
    },
  },
};

// helper functions to ease using theme - components can import just the helpers they need and easily access the theme with them
export const getBreakpoint = breakpoint => props => props.theme.breakpoints[breakpoint];

export const getColor = color => props => props.theme.colors[color];

export const getFontSize = (element, size) => props => props.theme.typography.sizes[element][size];

export const getFontWeight = weight => props => props.theme.typography.weights[weight];

export const getRgba = (color, amount) => props => rgba(props.theme.colors[color], amount);

export const darkenColor = (color, amount) => props => darken(amount, props.theme.colors[color]);

export default theme;
