import styled from 'styled-components/macro';

const ProfileWrapper = styled.section`
  display: flex;
  flex-direction: column;

  margin: 50px 0 25px 0;

  min-height: 90vh;
`;

export default ProfileWrapper;
