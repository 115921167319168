import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../../util/theme';

const AfLoanTabsHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding: 25px 0 0 0;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 25px 40px 0 0;
  }
`;

export default AfLoanTabsHeaderWrapper;
