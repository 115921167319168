import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const PreLoanInformationTable = styled.table`
  display: flex;
  justify-content: center;

  width: 100%;

  margin: 0;
  padding: 0;

  border-collapse: collapse;

  td {
    color: ${getColor('medGrey')};
    font-size: 1.4rem;
    font-weight: ${getFontWeight('regular')};
    text-align: left;

    padding: 5px 0px 5px 15px;

    &:first-of-type {
      border-right: 1px solid ${getColor('lightestGrey')};

      font-weight: ${getFontWeight('light')};
      text-align: right;

      padding: 5px 10px 5px 0;
    }

    @media (min-width: ${getBreakpoint('md')}) {
      font-size: 2.2rem;

      padding: 10px 0 10px 40px;

      &:first-of-type {
        padding: 10px 35px 10px 0;
      }
    }
  }
`;

export default PreLoanInformationTable;
