import { format } from 'date-fns';
// import formatAddress from '../../../../../util/formatAddress';

const transformAssessmentString = (assessmentType, assessmentString) => {
  switch (assessmentType) {
    case 'ApplicantsCreditReportReviewed':
      if (assessmentString === 'Credit Report Not Reviewed') return '';
      return assessmentString;
    case 'AssetsReviewed':
      if (assessmentString === 'Assets Not Reviewed') return '';
      return assessmentString;
    case 'FundsToCloseReviewed':
      if (assessmentString === 'Funds to Close Not Reviewed') return '';
      return assessmentString;
    default:
      return assessmentString;
  }
};

const transformOccupancyType = occupancyType => {
  switch (occupancyType) {
    case 'PrimaryResidence':
      return 'Primary Residence';
    case 'SecondHome':
      return 'Second Home';
    case 'Investor':
      return 'Investor';
    default:
      return 'Unknown';
  }
};

const determineLoanBrand = email => {
  const emailSubstr = email.substr(email.indexOf('@') + 1, email.length);

  switch (emailSubstr) {
    case 'onetrusthomeloans.com':
      return 'onetrust';
    case 'calconmutualmortgage.com':
      return 'calcon';
    case 'tabormortgage.com':
      return 'tabor';
    default:
      return 'calcon';
  }
};

const getLoanType = (prequalified, preapproved) => {
  if (prequalified === 'True' && preapproved === 'False') {
    return 'Pre-Qualified';
  }

  return 'Pre-Approved';
};

const sortLoanLetters = letters => {
  const lettersWithFormattedDates = letters.map(letter => {
    const letterWithDate = { ...letter, sortingDate: new Date(format(letter.createdOn)) };
    return letterWithDate;
  });

  const sortedLetters = lettersWithFormattedDates.sort((a, b) => {
    return b.sortingDate - a.sortingDate;
  });

  return sortedLetters;
};

async function transformPrequalifiedPreapprovedLoan(loanDetailsData) {
  // destructure out all needed keys to not have to type loanDetailsData.key everywhere
  const {
    // borrower keys
    borrowerAddress,
    borrowerFirstName,
    borrowerLastName,
    borrowerEmail,
    // co-borrower keys
    coBorrowerAddress,
    coBorrowerFirstName,
    coBorrowerLastName,
    coBorrowerEmail,
    // property address key
    subjectPropertyAddress,
    // MLO keys
    loanOfficerEmail,
    loanOfficerNMLS,
    loanOfficerName,
    loanOfficerPhone,
    loanOfficerWebsiteAddress,
    // loan information keys
    purchasePrice,
    loanAmount,
    loanToValue,
    loanTerm,
    propertyType,
    occupancyType,
    propertyState,
    preQualificationDate,
    loanType, // corresponds with loanInformation.loanProgram - loanProgram field from API is different
    prequalExpirationDate,
    // use to get loan type (not pre-qualified or pre-approved, not same as loanType above)
    preapproved,
    prequalified,
    // permission levels
    level1Permissions,
    level2Permissions,
    // min/max values for new letters
    prequalMaxLTV,
    prequalMaxLoanAmount,
    prequalMinLoanAmount,
    prequalMaxPurchasePrice,
    prequalMinPurchasePrice,
    // loan letters array
    loanLetters,
    // misc keys needed for loan letter
    loanNumber,
    loanGUID,
    incomeDocumentsReviewed,
    applicantsCreditReportReviewed,
    satisfactoryApproveEligible,
    assetsReviewed,
    fundsToCloseReviewed,
    additionalContingency_09,
    additionalContingency_10,
    additionalContingency_11,
    loBranchAddress,
    loBranchCity,
    loBranchState,
    loBranchZip,
    loanProgram,
    loanOfficerTitle,
    propertyCity,
    propertyZip,
    lenderDBAName,
  } = loanDetailsData;

  const formattedLoanDetailsData = {
    // propertyAddress: await formatAddress(subjectPropertyAddress),
    propertyAddress: subjectPropertyAddress,
    contacts: {
      borrower: {
        role: 'Borrower',
        exists: borrowerFirstName ? true : false,
        name: `${borrowerFirstName} ${borrowerLastName}`,
        email: borrowerEmail,
        // address: await formatAddress(borrowerAddress),
        address: borrowerAddress,
      },
      coBorrower: {
        role: 'Co-Borrower',
        exists: coBorrowerFirstName ? true : false,
        name: `${coBorrowerFirstName} ${coBorrowerLastName}`,
        email: coBorrowerEmail,
        // address: coBorrowerFirstName ? await formatAddress(coBorrowerAddress) : null,
        address: coBorrowerAddress,
      },
      mlo: {
        role: 'Mortgage Loan Officer',
        exists: loanOfficerName ? true : false,
        name: loanOfficerName,
        phone: loanOfficerPhone,
        email: loanOfficerEmail,
        nmls: loanOfficerNMLS,
        website: loanOfficerWebsiteAddress,
      },
    },
    loanType: getLoanType(prequalified, preapproved),
    loanInformation: {
      purchasePrice: {
        label: 'Purchase Price',
        value: purchasePrice,
        display: `$${parseInt(purchasePrice).toLocaleString()}`,
      },
      loanAmount: {
        label: 'Loan Amount',
        value: loanAmount,
        display: `$${parseInt(loanAmount).toLocaleString()}`,
      },
      loanToValue: {
        label: 'Loan to Value',
        value: loanToValue,
        display: `${loanToValue}%`,
      },
      loanTerm: {
        label: 'Loan Term',
        value: loanTerm,
        display: `${(loanTerm / 12).toFixed(0)} Years`,
      },
      propertyType: {
        label: 'Property Type',
        value: propertyType,
        display: propertyType,
      },
      occupancyType: {
        label: 'Occupancy Type',
        value: occupancyType,
        display: transformOccupancyType(occupancyType),
      },
      propertyState: {
        label: 'Subject Property State',
        value: propertyState,
        display: propertyState,
      },
      preQualificationDate: {
        label: 'Pre-Qualification Date',
        value: preQualificationDate,
        display: format(preQualificationDate, 'MM/DD/YYYY'),
      },
      loanProgram: {
        label: 'Loan Program',
        value: loanType,
        display: loanType,
      },
      prequalExpirationDate: {
        label: 'Expiration Date',
        value: prequalExpirationDate,
        display: format(prequalExpirationDate, 'MM/DD/YYYY'),
      },
    },
    loanLetters: sortLoanLetters(loanLetters),
    letterInformation: {
      // loan info
      LoanNumber: loanNumber,
      LoanGUID: loanGUID,
      LoanBrand: determineLoanBrand(loanOfficerEmail),
      // letter type
      LetterType: '',
      // borrower info
      borrowerEmail,
      borrowerFirstName,
      borrowerLastName,
      // lo info
      LoanOfficerName: loanOfficerName,
      LoanOfficerPhone: loanOfficerPhone,
      LoanOfficerEmail: loanOfficerEmail,
      LoanOfficerNMLS: loanOfficerNMLS,
      // property info
      PropertyCity: propertyCity,
      PropertyState: propertyState,
      PurchasePrice: `$${parseInt(purchasePrice).toLocaleString()}`,
      LoanAmount: `$${parseInt(loanAmount).toLocaleString()}`,
      PropertyType: propertyType,
      OccupancyType: transformOccupancyType(occupancyType),
      LoanProgram: loanProgram,
      PrequalExpirationDate: format(prequalExpirationDate, 'MM/DD/YYYY'),
      IncomeDocumentsReviewed: incomeDocumentsReviewed,
      ApplicantsCreditReportReviewed: transformAssessmentString(
        'ApplicantsCreditReportReviewed',
        applicantsCreditReportReviewed
      ),
      SatisfactoryApproveEligible: satisfactoryApproveEligible,
      AssetsReviewed: transformAssessmentString('AssetsReviewed', assetsReviewed),
      FundsToCloseReviewed: transformAssessmentString('FundsToCloseReviewed', fundsToCloseReviewed),
      AdditionalContingency_09: additionalContingency_09,
      // AdditionalContingency_09: 'this is an additional contingency',
      AdditionalContingency_10: additionalContingency_10,
      AdditionalContingency_11: additionalContingency_11,
      LOBranchAddress: loBranchAddress,
      LOBranchCity: loBranchCity,
      LOBranchState: loBranchState,
      LOBranchZip: loBranchZip,
      LoanType: loanType,
      LoanOfficerTitle: loanOfficerTitle,
      PropertyZip: propertyZip,
      lenderDBAName,
    },
    permissions: {
      levelOne: level1Permissions,
      levelTwo: level2Permissions,
    },
    /**
     * in processLoans.js loans without the five constraints fields are filtered out so this isn't really necessary
     * neverthless this serves to set 'default values' for the constraints just incase, was set before filtering
     * keeping here if they aren't filtered out in the future
     */
    constraints: {
      loanAmount: {
        min: prequalMinLoanAmount ? parseInt(prequalMinLoanAmount) : parseInt(loanAmount),
        max: prequalMaxLoanAmount ? parseInt(prequalMaxLoanAmount) : parseInt(loanAmount),
      },
      purchasePrice: {
        min: prequalMaxPurchasePrice ? parseInt(prequalMinPurchasePrice) : parseInt(purchasePrice),
        // min: parseInt(prequalMaxLoanAmount) / parseFloat(prequalMaxLTV / 100),
        max: prequalMaxPurchasePrice ? parseInt(prequalMaxPurchasePrice) : parseInt(purchasePrice),
      },
      ltv: prequalMaxLTV ? parseFloat(prequalMaxLTV / 100) : 0.8,
    },
  };

  const formattedLoanDetailsDataWithAddressClipped = {
    ...formattedLoanDetailsData,
    propertyAddress: formattedLoanDetailsData.propertyAddress.replace('<br />', ' ').replace(/[0-9]/g, ''),
  };

  return formattedLoanDetailsDataWithAddressClipped;
}

export default transformPrequalifiedPreapprovedLoan;
