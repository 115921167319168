import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../util/theme';

const LoanHeaderPersonLabel = styled.p`
  color: ${getColor('medGrey')};
  font-size: 1.4rem;
  font-weight: ${getFontWeight('medium')};

  margin: 0;
`;

export default LoanHeaderPersonLabel;
