import styled from 'styled-components/macro';

const SkipToContentLink = styled.a`
  opacity: 0;

  position: absolute;
  left: 0;

  &:focus {
    opacity: 1;
  }
`;

export default SkipToContentLink;
