import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../util/theme';

const ForgotPasswordFormContent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  @media (min-width: ${getBreakpoint('md')}) {
    width: 60rem;
  }

  div {
    display: flex;
    flex-direction: column;

    padding: 10px 0;

    width: 100%;
  }

  label {
    color: ${getColor('titleGrey')};
    font-size: 2rem;
    font-weight: ${getFontWeight('medium')};
    text-transform: uppercase;

    margin: 10px 0;
  }

  input {
    color: ${getColor('complimentaryBlue')};
    font-size: 1.6rem;

    height: 40px;
    width: 100%;

    padding: 3px 10px;

    &.error {
      border: 2px solid ${getColor('accentRed')};
    }

    &::placeholder {
      color: ${getColor('medGrey')};
    }
  }

  p {
    text-align: center;

    &.error {
      color: ${getColor('accentRed')};
    }

    &.success {
      color: ${getColor('accentGreen')};
    }
  }
`;

export default ForgotPasswordFormContent;
