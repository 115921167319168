import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../util/theme';

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: 105px;

  margin: 25px 0 0 0;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 0;
  }

  &.tabor {
    height: 135px;
  }
`;

export default FooterWrapper;
