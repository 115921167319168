import { useContext, useState } from 'react';
import { format } from 'date-fns';

// import config
import { API_BASE_URL } from '../../../../util/config';

// import context
import { LoggedInContext } from '../../../Context/LoggedInContext';

const downloadLetterPdf = (letterData, fileName) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(letterData);
  link.href = url;
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
};

const formatLetterRequest = async (loanType, letterInformation, accessToken, setSendingRequest, fileName, dispatch) => {
  setSendingRequest(true);

  const createLetterUrl = `${API_BASE_URL}/api/LoanLetter`;

  const letterType = loanType => {
    switch (loanType) {
      case 'Pre-Qualified':
        return 'Prequalification';
      case 'Pre-Approved':
        return 'Preapproval';
      default:
        return 'Prequalification';
    }
  };

  const formattedLetterRequest = {
    ...letterInformation,
    LetterType: letterType(loanType),
    createdOn: format(new Date(), 'MM/DD/YYYY'),
  };

  const jsonFormattedLetterRequest = JSON.stringify(formattedLetterRequest);

  const createLetterRequest = await fetch(createLetterUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${accessToken}`,
    },
    responseType: 'arraybuffer',
    dataType: 'blob',
    body: jsonFormattedLetterRequest,
  });

  const regeneratedLetterPdf = await createLetterRequest.blob();

  setSendingRequest(false);

  const data = new Blob([regeneratedLetterPdf], { type: 'application/pdf' });

  downloadLetterPdf(data, fileName);

  dispatch({ type: 'toggleSuccess' });
};

function useCreateLetter(loanType, letterInformation, dispatch) {
  const {
    state: {
      userState: { accessToken },
    },
  } = useContext(LoggedInContext);

  const { borrowerFirstName, borrowerLastName, LoanNumber } = letterInformation;
  const fileName = `${borrowerFirstName}-${borrowerLastName}-${loanType}-${LoanNumber}-letter-${format(
    new Date(),
    'MMDDYYYY:hhmmss'
  )}`.toLowerCase();

  const [sendingRequest, setSendingRequest] = useState(false);

  const handleLetterRequest = () => {
    formatLetterRequest(loanType, letterInformation, accessToken, setSendingRequest, fileName, dispatch);
  };

  return [handleLetterRequest, sendingRequest, setSendingRequest];
}

export default useCreateLetter;
