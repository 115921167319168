import React from 'react';
import PropTypes from 'prop-types';

// import styled components
import { AfLoanTabsHeaderWrapper, AfLoanTab, AfLoanTabTitle } from './styled';

// import svg
import { ReactComponent as ContactsIcon } from '../../../../assets/icons/contacts-icon.svg';
import { ReactComponent as DatesIcon } from '../../../../assets/icons/calendar-icon.svg';

const AfLoanTabsHeader = ({ active, setActiveTab }) => (
  <AfLoanTabsHeaderWrapper>
    <AfLoanTab
      active={active === 'dates' ? true : false}
      onClick={() => setActiveTab('dates')}
      onKeyPress={() => setActiveTab('dates')}
      tabIndex="0"
    >
      <DatesIcon />
      <AfLoanTabTitle active={active === 'dates' ? true : false}>Key Dates</AfLoanTabTitle>
    </AfLoanTab>
    <AfLoanTab
      active={active === 'contacts' ? true : false}
      onClick={() => setActiveTab('contacts')}
      onKeyPress={() => setActiveTab('contacts')}
      tabIndex="0"
    >
      <ContactsIcon />
      <AfLoanTabTitle active={active === 'contacts' ? true : false}>Contacts</AfLoanTabTitle>
    </AfLoanTab>
  </AfLoanTabsHeaderWrapper>
);

export default AfLoanTabsHeader;

AfLoanTabsHeader.propTypes = {
  active: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};
