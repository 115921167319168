import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

// import hooks
import { useLoginReducer } from './hooks';

// import styled components
import { Spinner } from '../styled';
import {
  LoginForm,
  LoginFormErrors,
  LoginFormFooter,
  LoginFormFooterImageWrapper,
  LoginFormInputGroup,
  LoginFormLogo,
  LoginFormSubmitButton,
  LoginLogo,
  LoginWrapper,
} from './styled';

// import svg components
import { ReactComponent as LfLogo } from '../../assets/logos/loanforce-logo.svg';
import { ReactComponent as InsiderLogo } from '../../assets/logos/insider-logo.svg';
import { ReactComponent as CalconLogo } from '../../assets/logos/calcon-logo-color.svg';
import { ReactComponent as OthlLogo } from '../../assets/logos/othl-logo-color.svg';
import { ReactComponent as TaborLogo } from '../../assets/logos/tabor-logo-color.svg';

function Login({ location: { origin } }) {
  const [state, brand, dispatch, loginMiddleware, setUserState] = useLoginReducer(origin);

  const handleSubmit = e => {
    e.preventDefault();

    loginMiddleware(state, dispatch, setUserState);
  };

  const drawLogo = brand => {
    switch (brand) {
      case 'calcon':
        return <CalconLogo />;
      case 'othl':
        return <OthlLogo />;
      case 'tabor':
        return <TaborLogo />;
      default:
        return <OthlLogo />;
    }
  };

  return (
    <LoginWrapper>
      <LoginLogo>
        <LfLogo />
      </LoginLogo>
      <LoginForm onSubmit={e => handleSubmit(e)} novalidate>
        <LoginFormLogo>
          <InsiderLogo />
        </LoginFormLogo>
        <LoginFormErrors>
          {Object.keys(state.errors).map(key => (state.errors[key] ? <p key={key}>{state.errors[key]}</p> : null))}
        </LoginFormErrors>
        <LoginFormInputGroup>
          <label htmlFor="username" role="heading" aria-level="1" tabIndex="0">
            Username
            <span className="tooltip" tabIndex="0">
              ?
            </span>
            <span className="tooltip-content">Email Regularly Used for Business Communications</span>
          </label>
          <input
            type="email"
            placeholder="work_email@example.com"
            id="username"
            name="username"
            value={state.user.username}
            onChange={e => dispatch({ type: 'setUserValue', name: 'username', value: e.target.value })}
          />
        </LoginFormInputGroup>

        <LoginFormInputGroup>
          <label htmlFor="password" role="heading" aria-level="2">
            Password
          </label>
          <input
            type="password"
            placeholder="Password"
            id="password"
            name="password"
            value={state.user.password}
            onChange={e => dispatch({ type: 'setUserValue', name: 'password', value: e.target.value })}
          />
        </LoginFormInputGroup>
        {state.loading ? (
          <Spinner heightProp="40" widthProp="40" marginProp="20px auto" />
        ) : (
          <LoginFormSubmitButton type="submit">Login</LoginFormSubmitButton>
        )}
      </LoginForm>
      <LoginFormFooter>
        <p>
          Don't have an Insider account? <Link to="/register">Create one with your email</Link>
        </p>
        <p>
          or <Link to="/forgotPassword">Reset your password</Link>
        </p>
        <LoginFormFooterImageWrapper>{drawLogo(brand)}</LoginFormFooterImageWrapper>
      </LoginFormFooter>
    </LoginWrapper>
  );
}

export default Login;

/**
 * Login props are all from router
 * the only one it needs is location, but all of them come through so including here
 */
Login.propTypes = {
  '*': PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};

/**
 * set default props bc all return undefined on initial render
 */
Login.defaultProps = {
  '*': '',
  location: {},
  navigate: () => null,
  path: '',
  uri: '',
};
