import 'babel-polyfill'; // needed for the below to work
import 'react-app-polyfill/ie9'; // For IE 9-11 support, layout will look pretty gnarly tho lol
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from '@reach/router';
import { ThemeProvider } from 'styled-components';
import theme from './util/theme';

// import typeface
import 'typeface-roboto';

// import components
import App from './components/App';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
// root context component, stores login and brand info
import { RootContext as InsiderRootContext } from './components/Context';

// import global styled components
import { GlobalStyle } from './components/styled';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <InsiderRootContext>
      <>
        <GlobalStyle />
        <Router style={{ height: '100%' }}>
          <App path="/*" />
          <Login path="login" />
          <Register path="register" />
          <ForgotPassword path="forgotPassword" />
          <ResetPassword path="reset" />
        </Router>
      </>
    </InsiderRootContext>
  </ThemeProvider>,
  document.getElementById('root')
);
