/**
 * this is mostly identical to PreLoanLetterButton styled component, with absolute positioning
 * created as new component incase it ever needs to do something more/different in the future
 */

import styled from 'styled-components/macro';

// import theme helpers
import { getColor } from '../../../util/theme';

const ModalDownloadButton = styled.button`
  color: ${getColor('white')};
  font-size: 1.6rem;

  background-color: ${getColor('buttonTeal')};

  display: flex;

  padding: 7px 20px;

  border: 0;
  border-radius: 2px;

  position: absolute;
  z-index: 999;

  cursor: pointer;

  &:disabled {
    opacity: 0.75;

    cursor: not-allowed;
  }

  &:hover {
    background-color: ${getColor('accentOrange')};
  }

  &.download {
    background-color: ${getColor('accentRed')};
    margin: 25px 0 0 0;

    &:hover {
      background-color: ${getColor('accentGreen')};
    }
  }

  svg {
    margin: 0 20px 0 0;
  }
`;

export default ModalDownloadButton;
