import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// import components
import PreLoanHistory from '../PreLoanHistory';

// import styled components
import { PreLoanSectionWrapper, PreLoanSuccessContent, PreLoanSuccessReturnButton } from '../styled';

// import svg
import { ReactComponent as ReturnArrow } from '../../../../assets/buttons/return-arrow.svg';
import { ReactComponent as SuccessBanner } from '../../../../assets/img/success-message.svg';

function PreLoanSuccess({ dispatch, loanData, loanLetters, modifiedLoan, setModifyingLetter }) {
  const {
    contacts: { borrower, mlo },
    loanType,
  } = loanData;

  const returnToLoanInformation = () => {
    dispatch({ type: 'toggleSuccess' });
    setModifyingLetter(false);
  };

  useEffect(() => {
    dispatch({ type: 'addLoanLetter' });
  }, []);

  return (
    <>
      <PreLoanSectionWrapper>
        <PreLoanSuccessContent>
          <SuccessBanner />
          <p>
            Your request for an new {loanType} Letter indicating a purchase price of{' '}
            {`$${parseInt(modifiedLoan.purchasePrice).toLocaleString()}`}, loan amount of{' '}
            {`$${parseInt(modifiedLoan.loanAmount).toLocaleString()}`}, and LTV of {`${modifiedLoan.loanToValue}%`} has
            been sent to the Borrower at <strong>{borrower.email}</strong> and the Loan Officer at{' '}
            <strong>{mlo.email}</strong>.
          </p>
          <PreLoanSuccessReturnButton onClick={returnToLoanInformation}>
            <ReturnArrow />
            Return to Loan Overview
          </PreLoanSuccessReturnButton>
        </PreLoanSuccessContent>
      </PreLoanSectionWrapper>
      <PreLoanSectionWrapper>
        <PreLoanHistory loanLetters={loanLetters} />
      </PreLoanSectionWrapper>
    </>
  );
}

export default PreLoanSuccess;

PreLoanSuccess.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanData: PropTypes.object.isRequired,
  loanLetters: PropTypes.array.isRequired,
  modifiedLoan: PropTypes.object.isRequired,
  setModifyingLetter: PropTypes.func.isRequired,
};
