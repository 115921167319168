import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../util/theme';

const LoanHeaderContentEntryContact = styled.div`
  background-color: ${getColor('white')};

  border: 1px solid ${getColor('borderGrey')};

  display: none;

  position: absolute;
  top: 50px;
  z-index: 1000;

  padding: 15px 15px 5px 15px;

  a,
  p {
    color: ${getColor('primaryBlue')};
    font-size: 1.6rem;
    font-weight: ${getFontWeight('light')};

    margin: 0;
    padding: 0 0 15px 0;
  }

  a {
    display: block;

    &:hover {
      color: ${getColor('accentGreen')};
      text-decoration: underline;
    }
  }

  h4 {
    color: ${getColor('complimentaryBlue')};
    font-size: 1.2rem;
    text-transform: uppercase;

    margin: 0;
    padding: 0;
  }
`;

export default LoanHeaderContentEntryContact;
