import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../util/theme';

const FooterLogo = styled.div`
  align-self: flex-end;

  height: 45px;

  margin: 20px 0 10px 0;

  svg {
    height: 45px;
    width: auto;

    margin: 4px 0 10px 0;
    padding: 0 15px 0 0;

    @media (min-width: ${getBreakpoint('md')}) {
      padding: 0 50px 0 0;
    }
  }

  &.tabor {
    height: 75px;

    svg {
      height: 75px;
    }
  }
`;

export default FooterLogo;
