function checkIfEmailValid(emailState) {
  const regexpTest = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailValid = regexpTest.test(emailState) && emailState.length > 0;

  if (!emailValid) {
    return false;
  }

  return true;
}

export default checkIfEmailValid;
