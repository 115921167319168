import { createGlobalStyle } from 'styled-components/macro';

import { getColor, getFontWeight } from '../../util/theme';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: Roboto, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  }

  html, body {
    height: 100%;
  }

  html {
    font-size: 10px;
  }

  #root {
    height: 100%;
  }

  html, body {
    font-family: Roboto, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.4;

    margin: 0;
    padding: 0;
  }

  img, svg {
    max-width: 100%;
  }

  button, input {
    font-family: Roboto, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  }

  h1 {
    font-size: 4rem;
    font-weight: ${getFontWeight('light')};
  }

  p {
    font-size: 1.6rem;

  }

  a {
    color: ${getColor('titleBlue')};
    text-decoration: none;
  }

  label {
    color: ${getColor('titleGrey')};
    font-size: 1.4rem;
    font-weight: ${getFontWeight('medium')};
    text-transform: uppercase;
  }

  input {
    color: ${getColor('complimentaryBlue')};
    font-size: 1.6rem;

    height: 40px;
    width: 100%;

    padding: 3px 10px;

    &.error {
      border: 2px solid ${getColor('accentRed')};
    }

    &::placeholder {
      color: ${getColor('medGrey')};
    }
  }

  /**
  * fix dumb iPhone input zoom thing
  * https://blog.osmosys.asia/2017/01/05/prevent-ios-from-zooming-in-on-input-fields/
  */
  /*** Styles added to fix the issue with zoom in on iphone ***/
  /* iPhone < 5: */
  @media screen and (device-aspect-ratio: 2/3) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"],
      input[type="url"]{ font-size: 16px !important; }
  }

  /* iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"],
      input[type="url"]{ font-size: 16px !important; }
  }

  /* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
  @media screen and (device-aspect-ratio: 375/667) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"],
      input[type="url"]{ font-size: 16px !important; }
  }

  /* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
  @media screen and (device-aspect-ratio: 9/16) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"],
      input[type="url"]{ font-size: 16px !important; }
  }
`;

export default GlobalStyle;
