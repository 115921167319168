import styled from 'styled-components/macro';

// import theme helpers
import { getColor } from '../../../../util/theme';

const ProfilePasswordMessage = styled.div`
  text-align: center;

  p {
    &.error {
      color: ${getColor('accentRed')};
    }

    &.success {
      color: ${getColor('accentGreen')};
    }
  }
`;

export default ProfilePasswordMessage;
