import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor } from '../../../../util/theme';

const ProfileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${getColor('backgroundGrey')};

  padding: 15px;

  @media (max-width: ${getBreakpoint('md')}) {
    min-height: 1820px;
  }
`;

export default ProfileContentWrapper;
