import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../util/theme';

const AppContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100%;

  padding: 50px 0 0 0;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 0 0 0 60px;
  }

  @media (min-width: ${getBreakpoint('xl')}) {
    padding: 0;
  }

  /* rules for IE11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }
`;

export default AppContentWrapper;
