import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// import hooks
import { useRegisterReducer } from './hooks';

// import components
import RegisterFormFields from './RegisterFormFields';
import RegisterFormUploads from './RegisterFormUploads';
import RegisterSubmit from './RegisterSubmit';

// import styled components
import { RegisterFormWrapper, RegisterFormHeader } from './styled';

function RegisterForm({ emailAddress, setRegistrationStatusState }) {
  const [state, dispatch] = useRegisterReducer();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <RegisterFormWrapper>
      <RegisterFormHeader>
        <div>
          <h3>Email Address</h3>
          <p>{emailAddress}</p>
        </div>
        <h3>* is required</h3>
      </RegisterFormHeader>
      <RegisterFormFields state={state} dispatch={dispatch} />
      <RegisterFormUploads state={state} dispatch={dispatch} />
      <RegisterSubmit
        state={state}
        dispatch={dispatch}
        email={emailAddress}
        setRegistrationStatusState={setRegistrationStatusState}
      />
    </RegisterFormWrapper>
  );
}

export default RegisterForm;

RegisterForm.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  setRegistrationStatusState: PropTypes.func.isRequired,
};

RegisterForm.defaultProps = {
  emailAddress: 'unknown@unknown.com',
};
