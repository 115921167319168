import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const AfLoanStatusBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15px 30px;

  border: 1px solid ${getColor('borderGrey')};
  border-bottom: 10px solid ${getColor('complimentaryBlue')};

  h1 {
    color: ${getColor('titleGrey')};
    font-size: 2.5rem;
    font-weight: ${getFontWeight('medium')};
    text-align: center;

    margin: 0;
    padding: 0 0 15px 0;

    @media (min-width: ${getBreakpoint('md')}) {
      font-size: 3rem;

      width: 75%;
    }
  }

  ul {
    width: 100%;

    list-style-type: none;

    margin: 0;
    padding: 0;
  }

  li {
    display: flex;

    color: ${getColor('titleGrey')};
    font-size: 1.5rem;
    font-weight: ${getFontWeight('light')};

    padding: 5px 0;

    @media (min-width: ${getBreakpoint('md')}) {
      display: block;

      font-size: 2rem;
    }

    svg {
      flex-basis: 15%;
      align-self: center;

      margin: 5px 0 0 0;

      @media (min-width: ${getBreakpoint('md')}) {
        margin: 0 10px 0 0;
      }
    }

    span {
      flex-basis: 85%;
    }
  }
`;

export default AfLoanStatusBody;
