import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// import hooks
import { useIsOpen } from '../../hooks';

// import components
import DashboardLoanItem from './DashboardLoanItem';

// import styled components
import {
  DashboardLoanContainer,
  DashboardLoanContainerTitle,
  DashboardLoanGrid,
  DashboardLoanGridWrapper,
} from './styled';

// import svg
import { ReactComponent as DashboardArrow } from '../../assets/ui/dashboard-arrow.svg';

function DashboardLoanList({ defaultOpen, loans, title, type }) {
  const [isOpen, setIsOpen] = useIsOpen(defaultOpen);
  const loanGridRef = useRef();

  const mapLoans = loans => {
    return loans.map((loan, i) => {
      /**
       * limit numbers of rendered loans during development
       * not enabled for production currently
       */
      // if (i < 20) {
      //   return <DashboardLoanItem key={loan.id} {...loan} type={type} />;
      // }
      // return null;

      return <DashboardLoanItem key={loan.id} {...loan} type={type} />;
    });
  };

  return (
    <DashboardLoanContainer>
      <DashboardLoanContainerTitle onClick={() => setIsOpen()}>
        <h1>{title}</h1>
        <DashboardArrow className={isOpen ? null : 'isClosed'} />
      </DashboardLoanContainerTitle>
      <DashboardLoanGridWrapper
        ref={loanGridRef}
        nodeHeight={loanGridRef.current ? loanGridRef.current.scrollHeight : 'auto'}
        isOpen={isOpen}
      >
        <DashboardLoanGrid>{mapLoans(loans)}</DashboardLoanGrid>
      </DashboardLoanGridWrapper>
    </DashboardLoanContainer>
  );
}

export default DashboardLoanList;

DashboardLoanList.propTypes = {
  defaultOpen: PropTypes.bool.isRequired,
  loans: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
