// import formatAddress from '../../../../../util/formatAddress';
import { format, isDate, isValid } from 'date-fns';

// const getLoanType = type => {
//   if (type === 'active') return 'In Process';

//   if (type === 'funded') return 'Funded';

//   return null;
// };

const getLoanType = funding => {
  const today = format(new Date());

  const fundingDate = new Date(format(funding));

  if (isDate(fundingDate)) {
    if (isValid(fundingDate) && funding <= today) {
      return 'Funded';
    }
  }

  return 'In Process';
};

async function transformActiveFundedLoan(loanDetailsData) {
  // destructure out all needed keys to not have to type loanDetailsData.key everywhere
  const {
    // borrower keys
    borrowerAddress,
    borrowerFirstName,
    borrowerLastName,
    borrowerEmail,
    // co-borrower keys
    coBorrowerAddress,
    coBorrowerFirstName,
    coBorrowerLastName,
    coBorrowerEmail,
    // property address key
    subjectPropertyAddress,
    // MLO keys
    loanOfficerEmail,
    loanOfficerNMLS,
    loanOfficerName,
    loanOfficerPhone,
    loanOfficerWebsiteAddress,
    // client manager keys
    loanProcessorName,
    loanProcessorPhone,
    loanProcessorEmail,
    // loan production assistant keys
    loanProductionAssistantName,
    loanProductionAssistantPhone,
    loanProductionAssistantEmail,
    // escrow company keys
    escrowCompanyName,
    escrowContactName,
    escrowPhone,
    escrowEmail,
    // title company keys
    titleCompanyName,
    titleContactName,
    titlePhone,
    titleEmail,
    // buyer's attorney keys
    buyerAttorneyCompanyName,
    buyerAttorneyContactName,
    buyerAttorneyPhone,
    buyerAttorneyEmail,
    // seller's agent keys
    sellerAgentCompanyName,
    sellerAgentContactName,
    sellerAgentPhone,
    sellerAgentEmail,
    sellerAgentLicenseNumber,
    //buyer's agent keys
    buyerAgentCompanyName,
    buyerAgentContactName,
    buyerAgentEmail,
    buyerAgentPhone,
    buyerAgentLicenseNumber,
    // buyer transaction coordinator keys
    buyerTransactionCoordinatorName,
    buyerTransactionCoordinatorTitle,
    buyerTransactionCoordinatorPhone,
    buyerTransactionCoordinatorEmail,
    // date keys
    cdReleasedDate,
    titleReportRequestDate,
    titleReportReceivedDate,
    appraisalOrderedDate,
    appraisalReceivedDate,
    inspectionDueDate,
    inspectionCompletedDate,
    expectedSigningDate,
    expectedCloseDate,
    docsDelieveredDate, // theres a typo in the API are you serious
    funding,
    // status/progress keys
    mloSetupComplete,
    submittedToUnderwriting,
    conditionallyApproved,
    initialUnderwritingComplete,
    p1ConditionResolutionComplete,
    underwritingIIConditionReviewComplete,
    piiFinalCondition,
    underwritingIIIClearToClose,
    docPrep,
  } = loanDetailsData;

  /**
   * process the loan into usable format
   * contacts that may or may not exist are given an 'exists' property
   * don't really like doing it like this but best I can do with the API :shrug:
   */
  const formattedLoanDetailsData = {
    // propertyAddress: await formatAddress(subjectPropertyAddress),
    propertyAddress: subjectPropertyAddress,
    contacts: {
      borrower: {
        role: 'Borrower',
        exists: borrowerFirstName ? true : false,
        name: `${borrowerFirstName} ${borrowerLastName}`,
        email: borrowerEmail,
        // address: await formatAddress(borrowerAddress),
        address: borrowerAddress,
      },
      coBorrower: {
        role: 'Co-Borrower',
        exists: coBorrowerFirstName ? true : false,
        name: `${coBorrowerFirstName} ${coBorrowerLastName}`,
        email: coBorrowerEmail,
        // address: coBorrowerFirstName ? await formatAddress(coBorrowerAddress) : null,
        address: coBorrowerAddress,
      },
      mlo: {
        role: 'Mortgage Loan Officer',
        exists: loanOfficerName ? true : false,
        name: loanOfficerName,
        phone: loanOfficerPhone,
        email: loanOfficerEmail,
        nmls: loanOfficerNMLS,
        website: loanOfficerWebsiteAddress,
      },
      clientManager: {
        role: 'Client Manager',
        exists: loanProcessorName ? true : false,
        name: loanProcessorName,
        phone: loanProcessorPhone,
        email: loanProcessorEmail,
      },
      loanProductionAssistant: {
        role: 'Loan Production Assistant',
        exists: loanProductionAssistantName ? true : false,
        name: loanProductionAssistantName,
        phone: loanProductionAssistantPhone,
        email: loanProductionAssistantEmail,
      },
      escrowCompany: {
        role: 'Escrow Company',
        exists: escrowCompanyName ? true : false,
        companyName: escrowCompanyName,
        contactName: escrowContactName,
        phone: escrowPhone,
        email: escrowEmail,
      },
      titleCompany: {
        role: 'Title Company',
        exists: titleCompanyName ? true : false,
        companyName: titleCompanyName,
        contactName: titleContactName,
        phone: titlePhone,
        email: titleEmail,
      },
      buyerAttorney: {
        role: "Buyer's Attorney",
        exists: buyerAttorneyContactName ? true : false,
        companyName: buyerAttorneyCompanyName,
        contactName: buyerAttorneyContactName,
        phone: buyerAttorneyPhone,
        email: buyerAttorneyEmail,
      },
      sellerAgent: {
        role: "Seller's Attorney",
        exists: sellerAgentContactName ? true : false,
        companyName: sellerAgentCompanyName,
        contactName: sellerAgentContactName,
        phone: sellerAgentPhone,
        email: sellerAgentEmail,
        license: sellerAgentLicenseNumber,
      },
      buyerAgent: {
        role: "Buyer's Agent",
        companyName: buyerAgentCompanyName,
        contactName: buyerAgentContactName,
        phone: buyerAgentPhone,
        email: buyerAgentEmail,
        license: buyerAgentLicenseNumber,
      },
      buyerTransactionCoordinator: {
        role: 'Buyer Transaction Coordinator',
        exists: buyerTransactionCoordinatorName ? true : false,
        name: buyerTransactionCoordinatorName,
        title: buyerTransactionCoordinatorTitle,
        phone: buyerTransactionCoordinatorPhone,
        email: buyerTransactionCoordinatorEmail,
      },
    },
    statusInfo: {
      expectedCloseDate: expectedCloseDate.substr(0, expectedCloseDate.indexOf(' ')),
      mloSetupComplete,
      submittedToUnderwriting,
      conditionallyApproved,
      initialUnderwritingComplete,
      p1ConditionResolutionComplete,
      underwritingIIConditionReviewComplete,
      piiFinalCondition,
      underwritingIIIClearToClose,
      docPrep,
      funding,
    },
    statusInfoMap: {},
    keyDates: {
      cdReleasedDate,
      titleReportRequestDate,
      titleReportReceivedDate,
      appraisalOrderedDate,
      appraisalReceivedDate,
      inspectionDueDate,
      inspectionCompletedDate,
      expectedSigningDate,
      expectedCloseDate,
      docsDelieveredDate,
      funding,
    },
    keyDatesMap: {},
    // loanType: getLoanType(type),
    loanType: getLoanType(funding),
  };

  return formattedLoanDetailsData;
}

export default transformActiveFundedLoan;
