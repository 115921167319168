import { useContext } from 'react';
import PropTypes from 'prop-types';

// import context
import { LoggedInContext } from '../Context/LoggedInContext';

const AppLoader = props => {
  const {
    loading: { loansLoading, profileLoading },
  } = useContext(LoggedInContext);

  if (loansLoading || profileLoading) return null;

  return props.children;
};

export default AppLoader;

/**
 * receives an array of child components
 */
AppLoader.propTypes = {
  children: PropTypes.array.isRequired,
};
