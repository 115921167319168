import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../util/theme';

const LoanHeaderAddress = styled.div`
  display: flex;
  align-items: center;

  position: relative;

  padding: 0 0 15px 0;

  svg {
    height: 50px;
    width: 50px;
  }

  p {
    color: ${getColor('titleBlue')};
    font-size: 1.8rem;
    font-weight: ${getFontWeight('regular')};

    margin: 0 0 0 15px;
  }
`;

export default LoanHeaderAddress;
