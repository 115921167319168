import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../../util/theme';

const AfLoanKeyDatesList = styled.div`
  ul {
    list-style-type: none;

    color: ${getColor('primaryBlue')};
    font-size: 1.8rem;

    padding: 0;

    @media (min-width: ${getBreakpoint('md')}) {
      padding: 0 0 0 50px;
    }

    li {
      display: flex;

      height: 50px;

      @media (min-width: ${getBreakpoint('md')}) {
        padding: 0;
      }

      &.label {
        span {
          color: ${getColor('complimentaryBlue')};
          font-weight: ${getFontWeight('bold')};

          &:first-of-type {
            width: 125px;

            justify-content: center;

            border-right: 2px solid ${getColor('lightestGrey')};

            @media (min-width: ${getBreakpoint('md')}) {
              width: 150px;
            }
          }
        }
      }

      span {
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        color: ${getColor('titleGrey')};
        font-weight: ${getFontWeight('regular')};

        @media (min-width: ${getBreakpoint('md')}) {
          margin: 0 60px 0 0;
          padding: 0;
        }

        &:nth-of-type(2) {
          padding: 0 25px;

          @media (min-width: ${getBreakpoint('md')}) {
            padding: 0;
          }
        }

        &.date {
          justify-content: flex-end;

          border-right: 2px solid ${getColor('lightestGrey')};

          font-weight: ${getFontWeight('light')};

          width: 125px;

          padding: 0 25px 0 0;

          @media (min-width: ${getBreakpoint('md')}) {
            width: 150px;

            padding: 0 50px 0 0;
          }
        }
      }
    }
  }
`;

export default AfLoanKeyDatesList;
