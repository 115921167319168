import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight, getBreakpoint } from '../../../util/theme';

// import reuseable styled components
import { Grid } from '../../styled';

const ResetPasswordContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;

  width: 100%;

  padding: 25px;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 0;
  }

  h1 {
    color: ${getColor('titleBlue')};
    font-weight: ${getFontWeight('thin')};

    margin: 25px 0 25px 0;

    text-align: center;

    @media (min-width: ${getBreakpoint('md')}) {
      margin: 0 0 75px 0;
    }
  }

  div {
    p {
      color: ${getColor('black')};
      font-size: 1.8rem;
      font-weight: ${getFontWeight('light')};
    }

    a {
      font-weight: ${getFontWeight('medium')};
    }

    h3 {
      color: ${getColor('complimentaryBlue')};
      font-size: 3rem;
      font-weight: ${getFontWeight('bold')};

      &.error {
        color: ${getColor('accentRed')};
      }
    }
  }
`;

export default ResetPasswordContainer;
