import React from 'react';
import PropTypes from 'prop-types';

// import components
import PreLoanLetterLevelOneButtons from './PreLoanLetterLevelOneButtons';
import PreLoanLetterLevelTwoButtons from './PreLoanLetterLevelTwoButtons';

// import styled components
import { PreLoanLetterWidgetWrapper, PreLoanTitle } from '../styled';

const PreLoanLetterWidget = ({
  fileName,
  letterData,
  levelOne,
  levelTwo,
  loadingLetter,
  loanData: { loanType },
  toggleModify,
}) => (
  <PreLoanLetterWidgetWrapper hideMobile={true}>
    <PreLoanTitle>{`${loanType} Letter`}</PreLoanTitle>
    {levelOne === 'True' && levelTwo === 'False' ? (
      <PreLoanLetterLevelOneButtons
        letterData={letterData}
        fileName={fileName}
        loadingLetter={loadingLetter}
        mobileOnly={false}
      />
    ) : (
      <PreLoanLetterLevelTwoButtons
        letterData={letterData}
        fileName={fileName}
        loadingLetter={loadingLetter}
        mobileOnly={false}
        toggleModify={toggleModify}
      />
    )}
  </PreLoanLetterWidgetWrapper>
);

export default PreLoanLetterWidget;

PreLoanLetterWidget.propTypes = {
  fileName: PropTypes.string.isRequired,
  /**
   * letterData is initially null, even with default prop set, so not set to required
   */
  letterData: PropTypes.object,
  levelOne: PropTypes.string.isRequired,
  levelTwo: PropTypes.string.isRequired,
  loadingLetter: PropTypes.bool.isRequired,
  loanData: PropTypes.object.isRequired,
  /**
   * only level two permissions uses this, not required for level one
   */
  toggleModify: PropTypes.func,
};

PreLoanLetterWidget.defaultProps = {
  levelOne: 'True',
  levelTwo: 'False',
};
