import styled from 'styled-components/macro';

const ProfileContentHeadshotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  cursor: pointer;
  svg,
  img {
    height: auto;
    max-width: 175px;
  }

  div {
    opacity: 0;
  }

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

export default ProfileContentHeadshotContainer;
