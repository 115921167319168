import React, { useState } from 'react';

// import config
import { API_BASE_URL } from '../../util/config';

// import styled components
import { Spinner, SubmitButton } from '../styled';
import { ForgotPasswordFormWrapper, ForgotPasswordFormContent } from './styled';

const checkIfEmailValid = emailState => {
  const regexpTest = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailValid = regexpTest.test(emailState) && emailState.length > 0;

  if (!emailValid) {
    return false;
  }

  return true;
};

function ForgotPasswordForm() {
  const [sendingRequest, setSendingRequest] = useState(false);
  const [emailState, setEmailState] = useState({ email: '' });
  const [message, setMessage] = useState({ type: '', text: '' });

  const sendPasswordResetRequest = async e => {
    e.preventDefault();

    const emailValid = checkIfEmailValid(emailState.email);

    if (!emailValid) {
      setMessage({
        type: 'error',
        text: 'Please enter a valid email address',
      });

      return;
    }

    setSendingRequest(true);

    const forgotPasswordUrl = `${API_BASE_URL}/api/Account/ForgotPassword`;

    const forgotPasswordData = JSON.stringify(emailState);

    const sendForgotPasswordRequest = await fetch(forgotPasswordUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: forgotPasswordData,
    });

    setSendingRequest(false);

    if (sendForgotPasswordRequest.status === 500) {
      setMessage({
        type: 'error',
        text:
          'The email you entered is not in our system. Please make sure to use the email account for business communication.',
      });
      setEmailState({
        email: '',
      });

      return;
    }

    setMessage({
      type: 'success',
      text: 'Password reset request sent successfully! Please check your email inbox for instruction.',
    });

    setEmailState({
      email: '',
    });

    return;
  };

  return (
    <ForgotPasswordFormWrapper>
      <ForgotPasswordFormContent onSubmit={e => sendPasswordResetRequest(e)}>
        <div>
          <label htmlFor="email">Email Address *</label>
          <input
            type="email"
            name="email"
            placeholder="abc@example.com"
            value={emailState.email}
            onChange={e => setEmailState({ email: e.target.value })}
          />
        </div>
        {message.type ? <p className={message.type}>{message.text}</p> : <p>&nbsp;</p>}
        {sendingRequest ? (
          <Spinner heightProp="60" widthProp="60" />
        ) : (
          <SubmitButton margin="0 0 20px 0">Submit</SubmitButton>
        )}
      </ForgotPasswordFormContent>
    </ForgotPasswordFormWrapper>
  );
}

export default ForgotPasswordForm;
