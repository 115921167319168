import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// import field data
import registerTopFields from './data/registerTopFields';
import registerBottomFields from './data/registerBottomFields';

// import styled components
import { RegisterFormFieldsContainer, RegisterFormField } from './styled';

function RegisterFormFields({ dispatch, state }) {
  const drawRegistrationFields = fields =>
    fields.map(field => {
      const { name, label, placeholder, type, error, required, validator } = field;

      if (type === 'spacer') {
        return <RegisterFormField key={name} />;
      }

      if (type === 'phone') {
        return (
          <RegisterFormField key={name}>
            <label htmlFor={name}>{label}</label>
            <NumberFormat
              name={name}
              id={name}
              placeholder={placeholder}
              value={state[name].value}
              type="text"
              format="(###) ###-####"
              onChange={e => handleChange(e, validator)}
            />
          </RegisterFormField>
        );
      }

      if (type === 'gender') {
        return (
          <RegisterFormField key={name}>
            <label>{label}</label>
            {field.options.map(option => (
              <label key={option} htmlFor={option}>
                <input
                  type="radio"
                  checked={state[name].value === option ? true : false}
                  name={name}
                  id={option}
                  value={option}
                  onChange={e => handleChange(e, validator)}
                />
                {option}
              </label>
            ))}
          </RegisterFormField>
        );
      }

      if (type === 'address') {
        return (
          <RegisterFormField key={name}>
            <label>Mailing Address</label>
            {field.subFields.map(field => {
              const { name, label } = field;

              return (
                <input
                  key={name}
                  type="text"
                  name={name}
                  id={name}
                  value={state.address[name]}
                  placeholder={label}
                  onChange={e => handleAddressChange(e)}
                  aria-label={name}
                />
              );
            })}
          </RegisterFormField>
        );
      }

      return (
        <RegisterFormField key={name}>
          <label htmlFor={name}>{`${label} ${required ? '*' : ''}`}</label>
          <input
            name={name}
            id={name}
            placeholder={placeholder}
            value={state[name].value}
            type={type}
            required={required}
            onChange={e => handleChange(e, validator)}
            className={state[name].error ? 'error' : null}
          />
          {state[name].error ? <p className="error">{error}</p> : <p>&nbsp;</p>}
        </RegisterFormField>
      );
    });

  const handleChange = (e, validator) => {
    const {
      target: { name, value },
    } = e;

    dispatch({ type: 'inputChange', name, value, validator });
  };

  const handleAddressChange = e => {
    const {
      target: { name, value },
    } = e;

    dispatch({ type: 'addressInputChange', name, value });
  };

  return (
    <>
      <RegisterFormFieldsContainer>{drawRegistrationFields(registerTopFields)}</RegisterFormFieldsContainer>
      <hr />
      <RegisterFormFieldsContainer>{drawRegistrationFields(registerBottomFields)}</RegisterFormFieldsContainer>
    </>
  );
}

export default RegisterFormFields;

RegisterFormFields.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};
