import styled from 'styled-components/macro';
import { Link } from '@reach/router';

const ProfileHeaderLeftLink = styled(Link)`
  display: flex;
  align-items: center;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }
`;

export default ProfileHeaderLeftLink;
