import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// import config
import { API_BASE_URL } from '../../util/config';

// import functions
import { checkIfEmailValid, checkIfPasswordsValid } from './functions';

// import components
import FailedPasswordResetRequest from './FailedPasswordResetRequest';
import SuccessfulPasswordResetRequest from './SuccessfulPasswordResetRequest';

// import styled components
import { Spinner, SubmitButton } from '../styled';
import { ResetPasswordFormWrapper, ResetPasswordFormContent } from './styled';

/**
 * would benefit from being broken up into multiple files probably
 */

function ResetPasswordForm({ location }) {
  const [sendingRequest, setSendingRequest] = useState(false);
  const initialResetPasswordFormState = {
    email: '',
    newPassword: '',
    confirmNewPassword: '',
    code: '',
    touched: {
      email: false,
      newPassword: false,
      confirmNewPassword: false,
    },
    minLength: {
      newPassword: 6,
      confirmNewPassword: 6,
    },
    error: {
      email: false,
      newPassword: false,
      confirmNewPassword: false,
    },
  };

  const [resetPasswordFormState, setResetPasswordFormState] = useState(initialResetPasswordFormState);
  const [codeState, setcodeState] = useState('');
  const [message, setMessage] = useState({ type: '', text: '' });
  const [successfulRequestState, setSuccessfulRequestState] = useState(false);
  const [failedRequestState, setFailedRequestState] = useState(false);

  useEffect(() => {
    const codeIndex = location.search.indexOf('&c');
    const codeString = location.search.substr(codeIndex + 6, location.search.length);

    setcodeState(codeString);
  }, [location]);

  const handleChange = e => {
    const {
      target: { name, value },
    } = e;

    const updatedResetPasswordFormState = { ...resetPasswordFormState };
    updatedResetPasswordFormState[name] = value;
    updatedResetPasswordFormState.touched[name] = true;

    if (updatedResetPasswordFormState.touched[name] && value.length < updatedResetPasswordFormState.minLength[name]) {
      updatedResetPasswordFormState.error[name] = true;
    } else {
      updatedResetPasswordFormState.error[name] = false;
    }

    setResetPasswordFormState(updatedResetPasswordFormState);
  };

  const sendPasswordReset = async e => {
    e.preventDefault();

    const emailValid = checkIfEmailValid(resetPasswordFormState.email);

    if (!emailValid) {
      const updatedResetPasswordFormState = { ...resetPasswordFormState };
      updatedResetPasswordFormState.error.email = true;

      setResetPasswordFormState(updatedResetPasswordFormState);

      setMessage({
        type: 'error',
        text: 'Please enter a valid email address',
      });

      return;
    } else {
      setMessage({
        type: '',
        text: '',
      });
    }

    const passwordsValid = checkIfPasswordsValid(resetPasswordFormState, 'newPassword', 'confirmNewPassword');

    if (!passwordsValid.allValid) {
      const updatedResetPasswordFormState = { ...resetPasswordFormState };
      updatedResetPasswordFormState.error.newPassword = !passwordsValid.newPassword;
      updatedResetPasswordFormState.error.confirmNewPassword = !passwordsValid.confirmNewPassword;

      setResetPasswordFormState(updatedResetPasswordFormState);

      setMessage({
        type: 'error',
        text: 'Please make sure entered passwords are the same and at least 6 characters long.',
      });

      return;
    }

    const formStateToSend = {
      email: resetPasswordFormState.email,
      Password: resetPasswordFormState.newPassword,
      ConfirmPassword: resetPasswordFormState.confirmNewPassword,
      Code: codeState,
    };

    setSendingRequest(true);

    const resetPasswordUrl = `${API_BASE_URL}/api/Account/ResetPassword`;

    const resetPasswordData = JSON.stringify(formStateToSend);

    const sendResetRequest = await fetch(resetPasswordUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: resetPasswordData,
    });

    setSendingRequest(false);

    if (sendResetRequest.status === 200) {
      setResetPasswordFormState(initialResetPasswordFormState);
      setSuccessfulRequestState(true);

      return;
    }

    setResetPasswordFormState(initialResetPasswordFormState);
    setFailedRequestState(true);

    return;
  };

  return (
    <>
      {failedRequestState ? (
        <FailedPasswordResetRequest />
      ) : successfulRequestState ? (
        <SuccessfulPasswordResetRequest />
      ) : (
        <ResetPasswordFormWrapper>
          <ResetPasswordFormContent onSubmit={e => sendPasswordReset(e)}>
            <div>
              <label htmlFor="email">Email Address *</label>
              <input
                type="email"
                name="email"
                placeholder="abc@example.com"
                value={resetPasswordFormState.email}
                onChange={e => handleChange(e)}
                className={resetPasswordFormState.error.email ? 'error' : null}
              />
              <label htmlFor="email">New Password (6 Characters Minimum) *</label>
              <input
                type="password"
                name="newPassword"
                value={resetPasswordFormState.newPassword}
                onChange={e => handleChange(e)}
                className={resetPasswordFormState.error.newPassword ? 'error' : null}
              />
              <label htmlFor="email">Confirm Password *</label>
              <input
                type="password"
                name="confirmNewPassword"
                value={resetPasswordFormState.confirmNewPassword}
                onChange={e => handleChange(e)}
                className={resetPasswordFormState.error.confirmNewPassword ? 'error' : null}
              />
            </div>
            <div>{message.type ? <p className={message.type}>{message.text}</p> : <p>&nbsp;</p>}</div>
            {sendingRequest ? (
              <Spinner heightProp="60" widthProp="60" />
            ) : (
              <SubmitButton margin="0 0 20px 0">Submit</SubmitButton>
            )}
          </ResetPasswordFormContent>
        </ResetPasswordFormWrapper>
      )}
    </>
  );
}

export default ResetPasswordForm;

ResetPasswordForm.propTypes = {
  location: PropTypes.object.isRequired,
};
