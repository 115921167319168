import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const PreLoanModifyInputTable = styled.table`
  border-collapse: collapse;

  width: 100%;

  margin: 0 0 50px 0;

  td {
    color: ${getColor('complimentaryBlue')};
    font-size: 1.8rem;
    font-weight: ${getFontWeight('regular')};
    text-align: center;

    &:first-of-type {
      border-right: 1px solid ${getColor('lightestGrey')};

      padding: 0 5px 0 0;
      margin: 0 5px 0 0;

      width: 40%;

      @media (min-width: ${getBreakpoint('md')}) {
        padding: 15px;

        width: 30%;
      }
    }

    &:nth-of-type(2) {
      font-size: 2rem;

      width: 60%;

      padding: 5px 10px;

      @media (min-width: ${getBreakpoint('md')}) {
        font-size: 2.2rem;

        padding: 15px 25px;

        width: 70%;
      }
    }

    @media (min-width: ${getBreakpoint('md')}) {
      font-size: 2.2rem;
    }
  }
`;

export default PreLoanModifyInputTable;
