import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor } from '../../../util/theme';

const LoanHeaderContainer = styled.div`
  background-color: ${getColor('white')};

  margin: 0;
  padding: 10px 25px 0 25px;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 50px 0 25px 0;
    padding: 25px;
  }
`;

export default LoanHeaderContainer;
