import styled from 'styled-components/macro';

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;

  /* rules for IE11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 100px;
  }
`;

export default HeaderContent;
