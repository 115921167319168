import { useEffect, useState } from 'react';

// import config
import { API_BASE_URL } from '../util/config';

// import functions
import arrayBufferToBase64 from '../util/arrayBufferToBase64';
import formatDate from '../util/formatDate';

const checkGenderValue = gender => {
  const genderLower = gender.toLowerCase();
  const genderArray = ['male', 'female', 'm', 'f'];

  const genderInArray = genderArray.indexOf(genderLower);

  if (genderInArray === -1) {
    return 'Not Specified';
  }

  return gender;
};

const fetchUserProfileImages = async (userId, accessToken) => {
  const userHeadshotUrl = `${API_BASE_URL}/api/User/${userId}/download/HeadShot`;
  const companyLogoUrl = `${API_BASE_URL}/api/User/${userId}/download/CompanyLogo`;

  let headshot = null;
  let companyLogo = null;

  const userHeadshotResponse = await fetch(userHeadshotUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${accessToken}`,
    },
  });

  const companyLogoResponse = await fetch(companyLogoUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${accessToken}`,
    },
  });

  if (userHeadshotResponse.status === 200) {
    const userHeadshotData = await userHeadshotResponse.arrayBuffer();
    headshot = `data:image/jpeg;base64,${arrayBufferToBase64(userHeadshotData)}`;
  }

  if (companyLogoResponse.status === 200) {
    const companyLogoData = await companyLogoResponse.arrayBuffer();
    companyLogo = `data:image/jpeg;base64,${arrayBufferToBase64(companyLogoData)}`;
  }

  return {
    headshot,
    companyLogo,
  };
};

const fetchUserProfile = async (userId, accessToken, initialProfileData) => {
  if (userId === 0) {
    return initialProfileData;
  }

  const profileUrl = `${API_BASE_URL}/api/User/${userId}`;

  const userProfileResponse = await fetch(profileUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${accessToken}`,
    },
  });

  const userProfileImages = await fetchUserProfileImages(userId, accessToken);

  const { profile } = await userProfileResponse.json();

  const gender = checkGenderValue(profile.gender);

  const birthDate = formatDate(profile.birthDate);

  const hasImages = { newImage: { headshot: false, companyLogo: false } };

  const mergedProfile = { ...profile, ...userProfileImages, ...hasImages, gender, birthDate };

  return mergedProfile;
};

const updateProfileImage = (state, type, value) => {
  return {
    ...state,
    [type]: value,
  };
};

function useGetUserProfile(userId, accessToken) {
  const initialProfileData = {
    address: { street: '', city: '', state: '', zip: '' },
    birthDate: '',
    company: '',
    companyLogoUrl: '',
    email: '',
    firstName: '',
    gender: '',
    headShotUrl: '',
    lastName: '',
    license: '',
    mobilePhone: '',
    newsLetter: false,
    officePhone: '',
    title: '',
    userId: 0,
    website: '',
  };

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(initialProfileData);

  useEffect(() => {
    setLoading(true);

    fetchUserProfile(userId, accessToken, initialProfileData).then(value => setProfileData(value));

    if (error) {
      setError(error);
    }

    setLoading(false);
  }, [userId]);

  return [error, loading, profileData, updateProfileImage];
}

export default useGetUserProfile;
