import styled from 'styled-components/macro';

const LoginFormFooterImageWrapper = styled.div`
  margin: 25px auto;

  svg {
    margin: 1.5rem 0 0 0;

    /* min-height: 1px; */
    height: auto;
    width: 250px;
  }
`;

export default LoginFormFooterImageWrapper;
