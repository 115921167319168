const findLastStatus = statusList => {
  const statusesWithDates = [];
  let lastStatus = 0;

  statusList.map((status, i) => {
    const { date } = status;
    if (date) {
      statusesWithDates.push(i);
    }

    return i;
  });

  lastStatus = statusesWithDates[statusesWithDates.length - 1];

  return lastStatus;
};

function formatLoanStatus(statusMap) {
  let lastStatus = 0;
  const statusList = [
    {
      text: 'Expected Funding Date',
      date: '',
    },
    {
      text: 'MLO Setup Complete',
      date: '',
    },
    {
      text: 'Submitted to Underwriting',
      date: '',
    },
    {
      text: 'Conditionally Approved',
      date: '',
    },
    {
      text: 'Initial Underwriting Complete',
      date: '',
    },
    {
      text: 'PI Conditional Resolution Complete',
      date: '',
    },
    {
      text: 'Underwriting II Condition Review Complete',
      date: '',
    },
    {
      text: 'PII Final Condition',
      date: '',
    },
    {
      text: 'Clear to Close Complete',
      date: '',
    },
    {
      text: 'Doc Prep Complete',
      date: '',
    },
    {
      text: 'Funding',
      date: '',
    },
  ];

  Array.from(statusMap).map((status, i) => {
    statusList[i]['date'] = status[1];

    return null;
  });

  lastStatus = findLastStatus(statusList);

  return [statusList, lastStatus];
}

export default formatLoanStatus;
