import styled from 'styled-components/macro';

const ForgotPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
`;

export default ForgotPasswordWrapper;
