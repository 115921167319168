import React from 'react';
import PropTypes from 'prop-types';

// import hooks
import { useCreateLetter } from '../hooks';

// import styled components
import { Spinner } from '../../../styled';
import { PreLoanLetterButton, PreLoanModifyDownloadWrapper } from '../styled';

// import svg
import { ReactComponent as DownloadLetterIcon } from '../../../../assets/buttons/download-letter.svg';

function PreLoanModifyDownload({ dispatch, loanData, modifiedLoan }) {
  const modifiedLetterData = {
    ...loanData.letterInformation,
    LoanAmount: `$${parseInt(modifiedLoan.loanAmount).toLocaleString()}`,
    PurchasePrice: `$${parseInt(modifiedLoan.purchasePrice).toLocaleString()}`,
    // for testing, override MLO email so as not to spam actual MLOs
    // LoanOfficerEmail: 'gbromser@onetrusthomeloans.com',
  };

  const [handleLetterRequest, sendingLetterRequest] = useCreateLetter(loanData.loanType, modifiedLetterData, dispatch);

  return (
    <PreLoanModifyDownloadWrapper>
      <div style={{ height: '50px' }}>
        {sendingLetterRequest ? (
          <Spinner heightProp="50" widthProp="50" />
        ) : (
          <PreLoanLetterButton className="download" onClick={() => handleLetterRequest()}>
            <DownloadLetterIcon disabled={sendingLetterRequest} /> Download NEW Letter
          </PreLoanLetterButton>
        )}
      </div>
      <p>
        The new {loanData.loanType} letter will be sent to the Borrower at{' '}
        <strong>{loanData.contacts.borrower.email}</strong> and the Loan Officer at{' '}
        <strong>{loanData.contacts.mlo.email}</strong>.
      </p>
      <p>This may take a few minutes to download, please do not refresh the page.</p>
    </PreLoanModifyDownloadWrapper>
  );
}

export default PreLoanModifyDownload;

PreLoanModifyDownload.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanData: PropTypes.object.isRequired,
  modifiedLoan: PropTypes.object.isRequired,
};
