import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// import theme helpers
import { getBreakpoint } from '../../../util/theme';

const ModalWindow = styled.div`
  display: flex;
  justify-content: center;

  position: relative;

  width: 100%;

  margin-top: 400px;

  height: ${props => props.height};
  width: ${props => props.width};

  overflow-x: scroll;
  overflow-y: scroll;

  @media (min-width: ${getBreakpoint('md')}) {
    margin-top: 0;

    overflow-x: auto;
    overflow-y: auto;
  }

  h4 {
    display: inline;
    padding: 0 15px;
  }

  hr {
    margin: 15px 0;
  }

  img {
    display: block;
    margin: 0 auto;
    position: relative;
  }

  > div {
    max-width: 100%;
  }
`;

export default ModalWindow;

ModalWindow.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};
