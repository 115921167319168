import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../../util/theme';

const PreLoanLetterHistoryTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: ${getColor('medGrey')};
    font-weight: ${getFontWeight('medium')};
    text-align: center;
  }
`;

export default PreLoanLetterHistoryTableWrapper;
