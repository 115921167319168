import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const PreLoanLetterHistoryTable = styled.table`
  border-collapse: collapse;

  th,
  td {
    font-size: 1.4rem;
    text-align: center;

    padding: 0 10px;

    &:first-of-type {
      border-right: 1px solid ${getColor('lightestGrey')};

      text-align: right;

      padding: 5px 10px 5px 0;
      margin: 0 5px 0 0;
    }

    @media (min-width: ${getBreakpoint('md')}) {
      font-size: 2.2rem;
    }
  }

  th {
    color: ${getColor('titleBlue')};
    font-weight: ${getFontWeight('light')};
  }

  td {
    color: ${getColor('medGrey')};
    font-weight: ${getFontWeight('regular')};

    &:first-of-type {
      font-weight: ${getFontWeight('light')};
    }
  }
`;

export default PreLoanLetterHistoryTable;
