import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getFontWeight } from '../../../../util/theme';

const ProfileContentRadioInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;

    border: 0;

    width: 100%;

    color: ${getColor('black')};
    font-size: 1.4rem;
    font-weight: ${getFontWeight('regular')};
    text-transform: uppercase;

    cursor: pointer;

    &:hover {
      color: ${getColor('coolBlue')};
    }
  }

  input {
    width: auto;
  }
`;

export default ProfileContentRadioInputWrapper;
