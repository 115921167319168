import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import config
import { API_BASE_URL } from '../../util/config';

// import styled components
import { RegisterInputWrapper, RegisterInputContent, RegisterSubmitButton } from './styled';

const checkIfEmailValid = emailState => {
  const regexpTest = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailValid = regexpTest.test(emailState) && emailState.length > 0;

  if (!emailValid) {
    return false;
  }

  return true;
};

const handleValidationFailure = error => {
  if (error === 'Emailid already exists') {
    return {
      component: 'validationFailed',
      type: 'alreadyExists',
    };
  }

  if (error === 'Invalid Emailid! Emailid is not associated with any loans.') {
    return {
      component: 'validationFailed',
      type: 'noLoansAssociated',
    };
  }

  return {
    component: 'validationFailed',
    type: 'failedException',
  };
};

function RegisterInput({ setRegistrationStatusState }) {
  const [emailState, setEmailState] = useState({ email: '' });
  const [message, setMessage] = useState({ type: '', text: '' });

  const sendRegisterRequest = async e => {
    e.preventDefault();

    const emailValid = checkIfEmailValid(emailState.email);

    if (!emailValid) {
      setMessage({
        type: 'error',
        text: 'Please enter a valid email address',
      });

      return;
    }

    const registerUserUrl = `${API_BASE_URL}/api/Registration/Validate`;

    const registerUserData = JSON.stringify(emailState);

    const sendRegisterUserRequest = await fetch(registerUserUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: registerUserData,
    });

    const registerUserResponse = await sendRegisterUserRequest.json();

    if (sendRegisterUserRequest.status === 400) {
      const typeOfRegistrationFailure = handleValidationFailure(registerUserResponse.modelState['request.Email'][0]);

      setRegistrationStatusState(typeOfRegistrationFailure);

      return;
    }

    setRegistrationStatusState({
      type: null,
      component: 'validationSucceeded',
      email: emailState.email,
    });
  };

  return (
    <RegisterInputWrapper>
      <RegisterInputContent onSubmit={e => sendRegisterRequest(e)}>
        <div>
          <label htmlFor="email">Email Address *</label>
          <p className="note">Must be the email used for business communication</p>
          <input
            type="email"
            name="email"
            placeholder="abc@example.com"
            value={emailState.email}
            onChange={e => setEmailState({ email: e.target.value })}
          />
        </div>
        {message.type ? <p className={message.type}>{message.text}</p> : <p>&nbsp;</p>}
        <RegisterSubmitButton>Next</RegisterSubmitButton>
      </RegisterInputContent>
    </RegisterInputWrapper>
  );
}

export default RegisterInput;

RegisterInput.propTypes = {
  setRegistrationStatusState: PropTypes.func.isRequired,
};
