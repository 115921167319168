import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../../../util/theme';

const AfLoanContactCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${getColor('cardBlue')};

  padding: 10px 25px;
  margin: 0 0 10px 0;

  cursor: pointer;

  &:hover {
    background-color: ${getColor('accentGreen')};
  }

  h3 {
    color: ${getColor('white')};
    font-size: 1.5rem;
    font-weight: ${getFontWeight('regular')};

    margin: 0;
    padding: 0;
  }

  svg {
    &:first-of-type {
      visibility: hidden;
    }

    &:not(first-of-type) {
      transition: all 0.3s;

      &.isClosed {
        transform: rotate(-90deg);
      }
    }
  }
`;

export default AfLoanContactCardHeader;
