import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const DashboardLoanGridWrapper = styled.div`
  /* opacity: ${props => (props.isOpen ? '1' : '0')}; */
  /* height: ${props => (props.isOpen ? 'auto' : '0')}; */

  /* better way of doing height transition with ref */
  height: ${props => (props.isOpen ? `${props.nodeHeight}px` : '0')};

  transition: all 0.3s ease;

  overflow: hidden;
`;

export default DashboardLoanGridWrapper;

DashboardLoanGridWrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
