import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const ProfileContentFieldInputWrapper = styled.div`
  flex-basis: 70%;

  display: flex;

  min-height: 40px;

  div {
    color: ${getColor('black')};

    border-bottom: 1px dashed ${getColor('coolBlue')};

    cursor: pointer;
  }

  input {
    color: ${getColor('coolBlue')};

    border: 0;
    border-bottom: 1px solid ${getColor('coolBlue')};

    line-height: 1.4;
  }

  input,
  div {
    font-size: 2rem;
    font-weight: ${getFontWeight('light')};

    background-color: transparent;

    margin: 0 20px 0 0;
    padding: 5px 0 0 0;

    width: 80%;

    display: flex;
    align-items: center;

    @media (min-width: ${getBreakpoint('md')}) {
      padding: 5px;
    }

    &:hover {
      color: ${getColor('coolBlue')};
    }
  }

  span {
    display: none;

    color: ${getColor('coolBlue')};
    font-size: 2rem;
    font-weight: ${getFontWeight('bold')};

    width: 10%;

    cursor: pointer;

    &:hover {
      color: ${getColor('accentGreen')};
    }

    &.visible {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export default ProfileContentFieldInputWrapper;
