import React from 'react';
import PropTypes from 'prop-types';

// import hooks
import { useBrandFinder } from '../../hooks';

// import components
import Header from '../Header';
import Footer from '../Footer';
import ForgotPasswordForm from './ForgotPasswordForm';

// import styled components
import { ForgotPasswordWrapper, ForgotPasswordContainer } from './styled';

function ForgotPassword({ location: { origin } }) {
  const [brandState] = useBrandFinder(origin);

  return (
    <ForgotPasswordWrapper>
      <Header />
      <ForgotPasswordContainer>
        <h1>Password Reset</h1>
        <ForgotPasswordForm />
      </ForgotPasswordContainer>
      <Footer brand={brandState} />
    </ForgotPasswordWrapper>
  );
}

export default ForgotPassword;

/**
 * ForgotPassword props are all from router
 * the only one it needs is location, but all of them come through so including here
 */
ForgotPassword.propTypes = {
  '*': PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};

/**
 * set default props bc all return undefined on initial render
 */
ForgotPassword.defaultProps = {
  '*': '',
  location: {},
  navigate: () => null,
  path: '',
  uri: '',
};
