import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../util/theme';

const PreLoanLetterWidgetWrapper = styled.div`
  display: none;

  @media (min-width: ${getBreakpoint('md')}) {
    display: block;

    margin: 0 0 75px 0;
  }
`;

export default PreLoanLetterWidgetWrapper;
