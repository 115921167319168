import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor } from '../../../../util/theme';

const RegisterFormHeader = styled.div`
  align-self: center;

  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  padding: 0 25px;
  margin: 0 0 25px 0;

  width: 100%;

  border-bottom: 1px solid ${getColor('lightestGrey')};

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 90%;
  }

  h3 {
    color: ${getColor('titleBlue')};
    font-size: 2rem;
    text-transform: uppercase;

    margin: 10px 0;

    @media (min-width: ${getBreakpoint('md')}) {
      margin: 0;
    }
  }

  p {
    color: ${getColor('black')};

    margin: 5px 0 15px 0;
  }
`;

export default RegisterFormHeader;
