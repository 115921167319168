import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const ProfileContentSectionSide = styled.div`
  flex-basis: 55%;

  display: flex;
  flex-direction: column;

  width: 100%;

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
  }

  &:not(:first-of-type) {
    flex-direction: column;
    flex-basis: 45%;
  }
`;

export default ProfileContentSectionSide;
