import styled from 'styled-components/macro';

const DrawerItem = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
`;

export default DrawerItem;
