import { useEffect, useState } from 'react';

import { getAndCacheIndividualLoanDetails } from '../../App/functions';

function useGetIndividualLoanDetails(userState, loanNumber, loanCache, loanCacheList, loanCacheDispatch) {
  const [loanData, setLoanData] = useState({});

  useEffect(() => {
    const isLoanInCache = loanCacheList.includes(loanNumber);

    if (isLoanInCache) {
      setLoanData(loanCache[loanCacheList.indexOf(loanNumber)]);
    } else if (userState.userId === 0) {
      return;
    } else {
      getAndCacheIndividualLoanDetails(userState, loanNumber, loanCacheDispatch);
    }

    return function cleanup() {
      setLoanData({});
    };
  }, [loanCache[loanCacheList.indexOf(loanNumber)]]);

  return loanData;
}

export default useGetIndividualLoanDetails;
