import styled from 'styled-components/macro';

const ProfileContentLogoContainer = styled.div`
  cursor: pointer;

  position: relative;

  padding: 15px;

  svg,
  img {
    height: auto;

    max-width: 250px;
    max-height: 200px;
  }

  div {
    opacity: 0;
  }

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

export default ProfileContentLogoContainer;
