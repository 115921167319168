import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

// import styled components
import {
  PreLoanLetterHistoryTable,
  PreLoanLetterHistoryTableWrapper,
  PreLoanLetterHistoryWrapper,
  PreLoanTitle,
} from '../styled';

function PreLoanLetterRequestHistory({ loanLetters }) {
  const drawLoanLetters = letters => {
    if (letters.length === 0) {
      return (
        <>
          <p>No loan letters have been created.</p>
          <p>Click "Modify Letter" above to create the first letter.</p>
        </>
      );
    }

    return (
      <PreLoanLetterHistoryTable>
        <thead>
          <tr>
            <th>Date</th>
            <th>Purchase Price</th>
            <th>Loan Amount</th>
            <th>Loan-to-Value</th>
          </tr>
        </thead>
        <tbody>
          {letters.map((letter, i) => (
            <tr key={`${letter.loanNumber}-${i}`}>
              <td>{format(letter.createdOn, 'M/D/YYYY')}</td>
              <td>{`$${parseInt(letter.purchasePrice).toLocaleString()}`}</td>
              <td>{`$${parseInt(letter.loanAmount).toLocaleString()}`}</td>
              <td>{`${((parseInt(letter.loanAmount) / parseInt(letter.purchasePrice)) * 100).toFixed(0)}%`}</td>
            </tr>
          ))}
        </tbody>
      </PreLoanLetterHistoryTable>
    );
  };

  return (
    <PreLoanLetterHistoryWrapper>
      <PreLoanTitle>Created Letter History</PreLoanTitle>
      <PreLoanLetterHistoryTableWrapper>{drawLoanLetters(loanLetters)}</PreLoanLetterHistoryTableWrapper>
    </PreLoanLetterHistoryWrapper>
  );
}

export default PreLoanLetterRequestHistory;

PreLoanLetterRequestHistory.propTypes = {
  loanLetters: PropTypes.array.isRequired,
};
