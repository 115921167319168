import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// import context
import { LoggedInContext } from '../../Context/LoggedInContext';

// import hooks
import { useGetLoanPartners } from './hooks';

// import icon svg
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu-icon.svg';

// import menu components
import DrawerHome from './DrawerHome';
import DrawerLoans from './DrawerLoans';
import DrawerPartners from './DrawerPartners';
import DrawerProfile from './DrawerProfile';

// import styled components
import { DrawerWrapper, DrawerContent } from './styled';

function Drawer({ expanded, setExpanded, drawerWrapperRef }) {
  const {
    functions: { handleIndividualLoan },
    state: { rawLoans, processedLoans },
  } = useContext(LoggedInContext);

  const loanPartners = useGetLoanPartners(rawLoans);

  /**
   * this does the same thing as closeMenu() in App, could prolly pass that as prop but w/e
   */
  const toggleDrawer = () => {
    drawerWrapperRef.current.scrollTop = 0;
    setExpanded(!expanded);
  };

  /**
   * currently state from context for Drawer sub-component is all in parent Drawer
   * maybe change to have each sub-component handle their relevant state directly idk
   */

  return (
    <DrawerWrapper ref={drawerWrapperRef} width={expanded ? '300px' : '60px'} expanded={expanded}>
      <DrawerContent>
        <MenuIcon onClick={() => toggleDrawer()} onKeyPress={() => toggleDrawer()} className="menu" tabIndex="0" />
        <DrawerHome expanded={expanded} />
        <DrawerLoans
          expanded={expanded}
          toggleDrawer={toggleDrawer}
          processedLoans={processedLoans}
          handleIndividualLoan={handleIndividualLoan}
        />
        <DrawerPartners expanded={expanded} toggleDrawer={toggleDrawer} loanPartners={loanPartners} />
        <DrawerProfile expanded={expanded} toggleDrawer={toggleDrawer} />
      </DrawerContent>
    </DrawerWrapper>
  );
}

export default Drawer;

Drawer.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};
