import React from 'react';
import PropTypes from 'prop-types';

// import functions
import { formatLoanStatus } from './functions';

// import styled components
import { AfLoanStatusContainer, AfLoanStatusTop, AfLoanStatusBody } from './styled';

// import svg
import { ReactComponent as CheckboxChecked } from '../../../assets/ui/checkbox-checked.svg';
import { ReactComponent as CheckboxUnchecked } from '../../../assets/ui/checkbox-unchecked.svg';

function AfLoanStatus({ loanData }) {
  const { statusInfo = {}, statusInfoMap = new Map() } = loanData;

  const { expectedCloseDate = '' } = statusInfo;

  const drawStatusTop = () => {
    if (!expectedCloseDate) {
      return <h3>No Closing Date Data</h3>;
    }
    return (
      <>
        <h3>Expected Closing On</h3>
        <h2>{expectedCloseDate}</h2>
      </>
    );
  };

  const drawStatusBody = () => {
    const [statusList, lastStatus] = formatLoanStatus(statusInfoMap);

    return statusList.map((status, i) => {
      if ((status.date || i > lastStatus) && i > 0) {
        const { text, date } = status;
        if (i === lastStatus) {
          return (
            <li key={text}>
              <CheckboxChecked />{' '}
              <span>
                <strong>{text}</strong>
              </span>
            </li>
          );
        } else if (date) {
          return (
            <li key={text}>
              <CheckboxChecked /> <span>{text}</span>
            </li>
          );
        }
        return (
          <li key={text}>
            <CheckboxUnchecked /> <span>{text}</span>
          </li>
        );
      }
      return null;
    });
  };

  return (
    <AfLoanStatusContainer>
      <AfLoanStatusTop>{drawStatusTop()}</AfLoanStatusTop>
      <AfLoanStatusBody>
        <h1>Progress Summary</h1>
        <ul>{drawStatusBody()}</ul>
      </AfLoanStatusBody>
    </AfLoanStatusContainer>
  );
}

export default AfLoanStatus;

AfLoanStatus.propTypes = {
  loanData: PropTypes.object.isRequired,
};
