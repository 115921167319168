import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../../util/theme';

const AfLoanContactsGroup = styled.ul`
  list-style-type: none;
  margin: 10px 0;
  padding: 0 15px 0 0;

  /** use max width to allow user agent stylesheet defaults non-mobile screens */
  @media (max-width: ${getBreakpoint('md')}) {
    margin: 0;
    padding: 0;
  }

  @media (min-width: ${getBreakpoint('md')}) {
    flex-basis: 50%;

    max-width: 50%;
  }
`;

export default AfLoanContactsGroup;
