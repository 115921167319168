import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const RootContext = createContext();

function InsiderRootContext({ children }) {
  const initialUserState = {
    userId: 0,
    userName: '',
    accessToken: '',
  };

  const [userState, setUserState] = useState(initialUserState);
  const [brandState, setBrandState] = useState('none');

  const contextValues = {
    state: {
      brandState,
      userState,
    },
    functions: {
      setBrandState,
      setUserState,
    },
  };

  return <RootContext.Provider value={contextValues}>{children}</RootContext.Provider>;
}

export default InsiderRootContext;

/**
 * receives one child element prop, a fragment in root index.js that wraps all other components
 */
InsiderRootContext.propTypes = {
  children: PropTypes.element.isRequired,
};
