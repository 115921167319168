import styled from 'styled-components/macro';

// import reuseable styled components
import { SubmitButton } from '../../styled';

const LoginFormSubmitButton = styled(SubmitButton)`
  margin: 15px 0;

  &::after {
    content: 'Login';
  }
`;

export default LoginFormSubmitButton;
