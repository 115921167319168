import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../util/theme';

export const LoanHeaderContentEntry = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    &:hover, &:focus {
      div {
        display: block;
      }

      svg {
        border: 1px solid ${getColor('primaryBlue')};
      }
    }
  }

  h3 {
    display: flex;
    align-items: center;

    color: ${getColor('titleBlue')};
    font-size: 2.3rem;
    font-weight: ${getFontWeight('light')};

    margin: 0;
  }

  svg {
    height: 25px;
    width: 25px;

    margin: 0 0 0 15px;
    padding: 5px;

    border: 1px solid ${getColor('borderGrey')};
    border-radius: 5px;
  }
`;

export default LoanHeaderContentEntry;
