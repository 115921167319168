import React from 'react';
import { Link } from '@reach/router';

const FailedPasswordResetRequest = () => (
  <div>
    <h3 className="error">Oops, password reset failed.</h3>
    <p>
      You could try to <Link to="/forgotPassword">submit the request</Link> again or contact system administrator for
      more information.
    </p>
  </div>
);

export default FailedPasswordResetRequest;
