import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import functions
import { submitPasswordUpdate } from './functions';

// import components
import ProfilePasswordForm from './ProfilePasswordForm';

// import styled components
import { ProfilePasswordWrapper, ProfilePasswordContent } from './styled';

/**
 * this could be cleaned up with useReducer and some modularization
 * might get around to it sometime
 */
function ProfilePassword({ accessToken, profileState: { email } }) {
  const initialPasswordState = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    touched: {
      oldPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    },
    minLength: {
      oldPassword: 0,
      newPassword: 6,
      confirmNewPassword: 6,
    },
    error: {
      oldPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    },
  };
  const initialResponseMessageState = {
    type: '',
    text: '',
  };

  const [passwordState, setPasswordState] = useState(initialPasswordState);
  const [responseMessageState, setResponseMessageState] = useState(initialResponseMessageState);

  const handleChange = e => {
    const {
      target: { name, value },
    } = e;

    const updatedPasswordState = { ...passwordState };
    updatedPasswordState[name] = value;
    updatedPasswordState.touched[name] = true;

    if (updatedPasswordState.touched[name] && value.length < updatedPasswordState.minLength[name]) {
      updatedPasswordState.error[name] = true;
    } else {
      updatedPasswordState.error[name] = false;
    }

    setPasswordState(updatedPasswordState);
  };

  const tryPasswordUpdate = async e => {
    e.preventDefault();

    const updatedPasswordResponse = await submitPasswordUpdate(accessToken, email, passwordState);

    if (updatedPasswordResponse.status === 200) {
      setResponseMessageState({ type: 'success', text: '<strong>Success!</strong> Password updated.' });
      setPasswordState(initialPasswordState);

      return;
    }

    const formattedErrorResponse = {
      type: 'error',
      text: updatedPasswordResponse.response.message,
      errors: updatedPasswordResponse.response.modelState ? updatedPasswordResponse.response.modelState : null,
    };

    if (formattedErrorResponse.text === 'Invalid Old Password! Please enter valid password.') {
      formattedErrorResponse.text =
        '<strong>Updated Failed</strong>: Password incorrect. Please check your password and try again';
    } else if (
      formattedErrorResponse.errors.model &&
      formattedErrorResponse.errors.model[0] === 'Old Password and New Password should not be same.'
    ) {
      formattedErrorResponse.text =
        '<strong>Updated Failed</strong>: The new password is the same as the old one. Please use a different password.';
    } else {
      formattedErrorResponse.text =
        '<strong>Updated Failed</strong>: Please make sure that you <strong>have the correct old password entered</strong>, <strong>the new password is different from the old one</strong>, and <strong>the new password contains at least 6 characters.</strong>';
    }

    setResponseMessageState(formattedErrorResponse);

    return;
  };

  return (
    <ProfilePasswordWrapper>
      <ProfilePasswordContent>
        <h1>Update Your Password</h1>
        <ProfilePasswordForm
          email={email}
          accessToken={accessToken}
          passwordState={passwordState}
          responseMessageState={responseMessageState}
          handleChange={handleChange}
          tryPasswordUpdate={tryPasswordUpdate}
        />
      </ProfilePasswordContent>
    </ProfilePasswordWrapper>
  );
}

export default ProfilePassword;

ProfilePassword.propTypes = {
  accessToken: PropTypes.string.isRequired,
  profileState: PropTypes.object.isRequired,
};
