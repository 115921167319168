import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// import hooks
import { useFileReader } from '../../../hooks';

// import styled components
import { RegisterFormFieldsContainer, RegisterFormField, RegisterFormUploadPreview } from './styled';

function RegisterFormUploads({ state, dispatch }) {
  const headshotUpload = useRef(null);
  const companyLogoUpload = useRef(null);
  const transformImage = useFileReader(dispatch);
  const [headshotKey, setHeadshotKey] = useState(0);
  const [companyLogoKey, setCompanyLogoKey] = useState(0);

  const clearImage = name => {
    // goofy way to force re-render of the file input
    if (name === 'headshot') {
      setHeadshotKey(Math.random().toString(36));
    }

    if (name === 'companyLogo') {
      setCompanyLogoKey(Math.random().toString(36));
    }

    dispatch({ type: 'clearImage', name });
  };

  return (
    <>
      <RegisterFormFieldsContainer>
        <RegisterFormField>
          <label htmlFor="headshot">Upload Headshot</label>
          <input
            type="file"
            name="headshot"
            id="headshot"
            accept="image/png, image/jpeg"
            ref={headshotUpload}
            key={headshotKey}
            onChange={e => transformImage(headshotUpload, 'headshot', dispatch)}
          />
        </RegisterFormField>
        <RegisterFormField>
          <label htmlFor="companyLogo">Upload Company Logo</label>
          <input
            type="file"
            name="companyLogo"
            id="companyLogo"
            accept="image/png, image/jpeg"
            ref={companyLogoUpload}
            key={companyLogoKey}
            onChange={e => transformImage(companyLogoUpload, 'companyLogo', dispatch)}
          />
        </RegisterFormField>
      </RegisterFormFieldsContainer>
      <RegisterFormFieldsContainer>
        {state.headshot.value ? (
          <RegisterFormUploadPreview>
            <button onClick={() => clearImage('headshot')}>Remove Headshot</button>
            <img src={state.headshot.value} alt="headshot" />
          </RegisterFormUploadPreview>
        ) : (
          <RegisterFormUploadPreview>&nbsp;</RegisterFormUploadPreview>
        )}
        {state.companyLogo.value ? (
          <RegisterFormUploadPreview>
            <button onClick={() => clearImage('companyLogo')}>Remove Company Logo</button>
            <img src={state.companyLogo.value} alt="company logo" />
          </RegisterFormUploadPreview>
        ) : (
          <RegisterFormUploadPreview>&nbsp;</RegisterFormUploadPreview>
        )}
      </RegisterFormFieldsContainer>
    </>
  );
}

export default RegisterFormUploads;

RegisterFormUploads.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};
