import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// import components
import { LoanAmountSlider, PurchasePriceSlider } from './PreLoanModifySlider';

// import styled components
import { PreLoanLetterButton, PreLoanModifyInputInput, PreLoanModifyInputTable } from '../styled';

// import svg
import { ReactComponent as CalculateValuesIcon } from '../../../../assets/icons/calculate-values-icon.svg';

const PreLoanModifyInput = ({
  dispatch,
  isMobile,
  modifiedLoan: { constraints, loanAmount, inputLoanAmount, purchasePrice, inputPurchasePrice, loanToValue },
}) => (
  <PreLoanModifyInputTable>
    <tbody>
      <tr>
        <td>
          Purchase Price
          <PreLoanModifyInputInput>
            <NumberFormat
              thousandSeparator={true}
              prefix={'$'}
              type="text"
              name="purchasePrice"
              value={inputPurchasePrice}
              onChange={e => dispatch({ type: 'modifyLoanInput', name: 'inputPurchasePrice', value: e.target.value })}
            />
            <PreLoanLetterButton onClick={() => dispatch({ type: 'modifyLoanFromInput', name: 'inputPurchasePrice' })}>
              <CalculateValuesIcon />
              Update
            </PreLoanLetterButton>
          </PreLoanModifyInputInput>
        </td>
        <td>
          <PurchasePriceSlider
            constraints={constraints}
            purchasePrice={purchasePrice}
            dispatch={dispatch}
            isMobile={isMobile}
          />
        </td>
      </tr>
      <tr>
        <td>
          Loan Amount
          <PreLoanModifyInputInput>
            <NumberFormat
              thousandSeparator={true}
              prefix={'$'}
              type="text"
              name="loanAmount"
              value={inputLoanAmount}
              onChange={e => dispatch({ type: 'modifyLoanInput', name: 'inputLoanAmount', value: e.target.value })}
            />
            <PreLoanLetterButton onClick={() => dispatch({ type: 'modifyLoanFromInput', name: 'inputLoanAmount' })}>
              <CalculateValuesIcon /> Update
            </PreLoanLetterButton>
          </PreLoanModifyInputInput>
        </td>
        <td>
          <LoanAmountSlider constraints={constraints} loanAmount={loanAmount} dispatch={dispatch} isMobile={isMobile} />
        </td>
      </tr>
      <tr>
        <td>
          Max Loan to Value
          <br />
          {`${constraints.ltv * 100}%`}
        </td>
        <td>{`${loanToValue}%`}</td>
      </tr>
    </tbody>
  </PreLoanModifyInputTable>
);

export default PreLoanModifyInput;

PreLoanModifyInput.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  modifiedLoan: PropTypes.object.isRequired,
};
