import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../util/theme';

const LoanHeaderContacts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-direction: row;
  }
`;

export default LoanHeaderContacts;
