const registerTopFields = [
  {
    name: 'firstName',
    label: 'First Name',
    placeholder: 'Jane',
    type: 'text',
    error: 'Please enter a first name',
    required: true,
    validator: 'lengthValidator',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    placeholder: 'Doe',
    type: 'text',
    error: 'Please enter a last name',
    required: true,
    validator: 'lengthValidator',
  },
  {
    name: 'company',
    label: 'Company',
    placeholder: 'Realtor Inc.',
    type: 'text',
    error: 'Please enter a company name',
    required: true,
    validator: 'lengthValidator',
  },
  {
    name: 'title',
    label: 'Title',
    placeholder: 'Real Estate Specialist',
    type: 'text',
    error: 'Please enter a title',
    required: true,
    validator: 'lengthValidator',
  },
  {
    name: 'licensingNumber',
    label: 'Licensing Number (BRE Number)',
    placeholder: '12345',
    type: 'text',
    error: 'Please enter a a valid licensing number',
    required: true,
    validator: 'numberValidator',
  },
  {
    name: 'firstSpacer',
    type: 'spacer',
  },
  {
    name: 'password',
    label: 'Password (6 Characters Minimum)',
    placeholder: 'Enter password',
    type: 'password',
    error: 'Please enter a password of at least 6 characters',
    required: true,
    validator: 'passwordValidator',
  },
  {
    name: 'confirmPassword',
    label: 'Confirm Password',
    placeholder: 'Enter password again',
    type: 'password',
    error: 'Please confirm that passwords match',
    required: true,
    validator: 'confirmPasswordValidator',
  },
];

export default registerTopFields;
