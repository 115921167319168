import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../util/theme';

const DashboardLoanGrid = styled.div`
  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;

    padding: 10px 0;

    @media (min-width: ${getBreakpoint('md')}) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 15px;
    }

    @media (min-width: ${getBreakpoint('lg')}) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  /* rules for IE11 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: flex;
    flex-wrap: wrap;

    a {
      flex-basis: 30%;

      margin: 15px 1%;
    }
  }
`;

export default DashboardLoanGrid;
