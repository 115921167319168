import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../util/theme';

const ModalClose = styled.span`
  color: ${getColor('medGrey')};
  font-size: 48px;
  font-weight: ${getFontWeight('bold')};

  position: absolute;
  top: 50px;
  right: 0;

  height: 75px;
  width: 75px;

  cursor: pointer;

  @media (min-width: ${getBreakpoint('lg')}) {
    top: 75px;
    right: 75px;
  }

  &:hover {
    color: ${getColor('accentRed')};
  }
`;

export default ModalClose;
