import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor } from '../../../../util/theme';

const PreLoanSuccessContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 50px 0 0 0;
  }

  p {
    color: ${getColor('titleGrey')};
    text-align: center;
  }
`;

export default PreLoanSuccessContent;
