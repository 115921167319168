import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const ProfileContentFieldsWrapper = styled.div`
  flex-basis: ${props => (props.flexBasis ? props.flexBasis : 'auto')};

  padding: 0 0 0 25px;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 0;
  }
`;

export default ProfileContentFieldsWrapper;

ProfileContentFieldsWrapper.propTypes = {
  flexBasis: PropTypes.string.isRequired,
};

/**
 * I think this is redundant with the ternary but that's ok
 */
ProfileContentFieldsWrapper.defaultProps = {
  flexBasis: 'auto',
};
