import React from 'react';
import PropTypes from 'prop-types';
import { Slider, Handles } from 'react-compound-slider';

// import components
import Handle from './Handle';

// import styles
import { mobileRailStyle, mobileSliderStyle, railStyle, sliderStyle } from './styled';

const PurchasePriceSlider = ({
  constraints: {
    loanAmount: { min },
    purchasePrice: { max },
  },
  dispatch,
  isMobile,
  purchasePrice,
}) => (
  <Slider
    rootStyle={isMobile ? mobileSliderStyle : sliderStyle}
    domain={[min, max]}
    mode={2}
    step={100}
    values={[parseInt(purchasePrice)]}
    onChange={values => dispatch({ type: 'modifyLoan', name: 'purchasePrice', value: values[0] })}
  >
    <div style={isMobile ? mobileRailStyle : railStyle} />
    <Handles>
      {({ handles, getHandleProps }) => (
        <div className="slider-handles">
          {handles.map(handle => (
            <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
          ))}
        </div>
      )}
    </Handles>
  </Slider>
);

export default PurchasePriceSlider;

PurchasePriceSlider.propTypes = {
  constraints: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  purchasePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
