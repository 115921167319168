import styled from 'styled-components/macro';

// import helpers
import { getColor, getFontWeight } from '../../../../../util/theme';

const AfLoanContactCardBody = styled.div`
  div {
    padding: 0 0 10px 0;
  }

  span {
    color: ${getColor('complimentaryBlue')};
    font-size: 1.2rem;
    font-weight: ${getFontWeight('bold')};
  }

  p,
  a {
    color: ${getColor('titleGrey')};
    font-size: 1.6rem;
    font-weight: ${getFontWeight('regular')};
    word-break: break-all;

    margin: 0;
  }

  a {
    &:hover {
      color: ${getColor('accentGreen')};
    }
  }
`;

export default AfLoanContactCardBody;
