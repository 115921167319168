import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../../util/theme';

const AfLoanContactsGroupsWrapper = styled.div`
  width: 100%;

  padding: 25px 0 0 0;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 25px 25px 0 0;
  }
`;

export default AfLoanContactsGroupsWrapper;
