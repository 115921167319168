import { useCallback, useState } from 'react';

function useModal(attr, content) {
  const [showModal, setShowModal] = useState(false);
  const [modalAttr, setModalAttr] = useState({
    data: null,
    showDownloadButton: false,
    fileName: '',
    display: 'none',
    overflow: '',
    position: '',
    height: 'auto',
    width: '100%',
  });

  const toggleModal = useCallback((data, showDownloadButton, fileName) => {
    if (!showModal) {
      setShowModal(true);
      setModalAttr({ ...modalAttr, display: 'flex', data, showDownloadButton, fileName });

      return;
    }

    setShowModal(false);
    setModalAttr({ ...modalAttr, display: 'none' });

    return;
  });

  return [modalAttr, toggleModal];
}

export default useModal;
