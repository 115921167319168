import styled from 'styled-components/macro';

import { getColor } from '../../../../util/theme';

const RegisterSubmitWrapper = styled.form`
  text-align: center;

  p {
    color: ${getColor('accentRed')};
  }
`;

export default RegisterSubmitWrapper;
