import styled from 'styled-components/macro';

// import reuseable styled components
import { SubmitButton } from '../../../styled';

const ProfileContentSubmitButton = styled(SubmitButton)`
  &::after {
    content: 'Save';
  }
`;

export default ProfileContentSubmitButton;
