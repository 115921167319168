import { useContext } from 'react';

// import context
import { AppContext } from '../../../App';

function useLetterActions(letterData, fileName) {
  const {
    functions: { toggleModal },
  } = useContext(AppContext);

  const previewLetterPdf = () => {
    toggleModal(letterData, true, fileName);
  };

  const downloadLetterPdf = (letterData, fileName) => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(letterData);
    link.href = url;
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
    // window.open(url);
  };

  return [previewLetterPdf, downloadLetterPdf];
}

export default useLetterActions;
