import { useContext, useReducer } from 'react';
import { navigate } from '@reach/router';

// import context
import { RootContext } from '../../Context/RootContext';

// import hooks
import { useBrandFinder } from '../../../hooks';

// import functions
import { checkIfUsernameOrPasswordBlank, saveLoginToSessionStorage, sendLoginRequest } from './functions';

async function loginMiddleware(state, dispatch, setUserState) {
  const isUsernameOrPasswordBlank = checkIfUsernameOrPasswordBlank(state.user);

  if (isUsernameOrPasswordBlank.value === true) {
    dispatch({
      type: 'updateErrors',
      value: {
        ...isUsernameOrPasswordBlank.errors,
      },
    });

    return;
  }

  dispatch({ type: 'setLoading', value: true });

  const loginUserRequest = await sendLoginRequest(state.user);

  if (loginUserRequest.failed) {
    dispatch({
      type: 'setLoading',
      value: false,
    });
    dispatch({
      type: 'updateErrors',
      value: {
        ...isUsernameOrPasswordBlank.errors,
        ...loginUserRequest.error,
      },
    });

    return;
  }

  setUserState(loginUserRequest);

  saveLoginToSessionStorage(loginUserRequest);

  dispatch({ type: 'setLoading', value: false });

  navigate('/');
}

function loginFormReducer(state, action) {
  const { type, name, value } = action;

  switch (type) {
    case 'setUserValue':
      return {
        ...state,
        user: {
          ...state.user,
          [name]: value,
        },
      };
    case 'updateErrors':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...value,
        },
      };
    case 'setLoading':
      return {
        ...state,
        loading: value,
      };
    default:
      return { ...state };
  }
}

function useLoginReducer(origin) {
  const initialLoginFormState = {
    user: {
      username: '',
      password: '',
    },
    errors: {
      username: '',
      password: '',
      loginFailed: '',
      serviceFailed: '',
    },
    loading: false,
  };

  const [loginFormState, dispatch] = useReducer(loginFormReducer, initialLoginFormState);
  const [brandState] = useBrandFinder(origin);

  const {
    functions: { setUserState },
  } = useContext(RootContext);

  return [loginFormState, brandState, dispatch, loginMiddleware, setUserState];
}

export default useLoginReducer;
