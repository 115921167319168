import styled from 'styled-components/macro';

// import assets
import bannerBg from '../../../assets/img/bannerBg.jpg';

// import helpers
import { getBreakpoint, getColor } from '../../../util/theme';

const DashboardWelcome = styled.section`
  background-image: url(${bannerBg});
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 10px;

  word-wrap: break-word;

  @media (min-width: ${getBreakpoint('lg')}) {
    background-color: ${getColor('lightGrey')};

    height: 15vh;

    margin: 50px auto;
    padding: 0 50px;
  }

  h1 {
    color: ${getColor('titleBlue')};
    font-size: 3rem;

    margin: 0;
    padding: 0;

    @media (min-width: ${getBreakpoint('md')}) {
      font-size: 4.5rem;
    }

    @media (min-width: ${getBreakpoint('lg')}) {
      font-size: 6rem;
    }
  }
`;

export default DashboardWelcome;
