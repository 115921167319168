import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const ProfileContentFirstWrapper = styled.div`
  flex-basis: 65%;

  padding: 0 0 0 25px;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 0;
  }
`;

export default ProfileContentFirstWrapper;
