import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../util/theme';

const AfLoanBody = styled.div`
  display: flex;
  flex-direction: column-reverse;

  padding: 0 25px;

  position: relative;
  z-index: 999;

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;

    padding: 0;
  }
`;

export default AfLoanBody;
