import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import hooks
import { useBrandFinder } from '../../hooks';

// import components
import Header from '../Header';
import Footer from '../Footer';
import RegisterInput from './RegisterInput';
import RegisterValidateFailed from './RegisterValidateFailed';
import RegisterForm from './RegisterForm';
import RegisterSubmitFailed from './RegisterSubmitFailed';
import RegisterSubmitSuccess from './RegisterSubmitSuccess';

// import styled components
import { RegisterWrapper, RegisterContainer } from './styled';

// import svg
import { ReactComponent as InsiderLogo } from '../../assets/logos/insider-logo.svg';

function Register({ location: { origin } }) {
  const [brandState] = useBrandFinder(origin);

  const [registrationStatusState, setRegistrationStatusState] = useState({
    component: 'emailInput',
    type: null,
    email: '',
  });

  const drawRegistrationStatus = () => {
    if (registrationStatusState.component === 'emailInput') {
      return <RegisterInput setRegistrationStatusState={setRegistrationStatusState} />;
    }

    if (registrationStatusState.component === 'validationFailed') {
      return (
        <RegisterValidateFailed
          failureType={registrationStatusState.type}
          setRegistrationStatusState={setRegistrationStatusState}
        />
      );
    }

    if (registrationStatusState.component === 'validationSucceeded') {
      return (
        <RegisterForm
          emailAddress={registrationStatusState.email}
          setRegistrationStatusState={setRegistrationStatusState}
        />
      );
    }

    if (registrationStatusState.component === 'registrationSubmitFailed') {
      return <RegisterSubmitFailed setRegistrationStatusState={setRegistrationStatusState} />;
    }

    if (registrationStatusState.component === 'registrationSubmitSuccess') {
      return <RegisterSubmitSuccess />;
    }
  };

  return (
    <RegisterWrapper>
      <Header />
      <RegisterContainer>
        <h1>
          Create Your <InsiderLogo /> Account
        </h1>
        {drawRegistrationStatus()}
      </RegisterContainer>
      <Footer brand={brandState} />
    </RegisterWrapper>
  );
}

export default Register;

/**
 * Register props are all from router
 * the only one it needs is location, but all of them come through so including here
 */
Register.propTypes = {
  '*': PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};

/**
 * set default props bc all return undefined on initial render
 */
Register.defaultProps = {
  '*': '',
  location: {},
  navigate: () => null,
  path: '',
  uri: '',
};
