import styled from 'styled-components/macro';

// import theme helpers
import { getRgba } from '../../../util/theme';

const ModalScreen = styled.div`
  background-color: ${getRgba('black', 0.9)};

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ModalScreen;
