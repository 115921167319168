import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

// import field data
import profileContentFirstFields from './data/profileContentFirstFields';

// import hooks
import { useFileReader } from '../../../hooks';
import { useEditModeState } from './hooks';

// import styled components
import {
  ProfileContentSectionSide,
  ProfileContentFieldsWrapper,
  ProfileContentHeadshotWrapper,
  ProfileContentHeadshotContainer,
  ProfileContentImageOverlay,
  ProfileContentField,
  ProfileContentFieldLabel,
  ProfileContentFieldInputWrapper,
  ProfileContentRadioInputWrapper,
} from './styled';

// import svg
import { ReactComponent as HeadshotPlaceholder } from '../../../assets/img/person-placeholder-rectangular.svg';

function ProfileContentFirst({ dispatch, profileState }) {
  const headshotUpload = useRef(null);

  const initialEditModeState = {
    headshot: false,
    firstName: false,
    lastName: false,
    company: false,
    title: false,
    license: false,
    birthDate: false,
  };

  const [editModeState, toggleFieldEditMode] = useEditModeState(initialEditModeState);
  const transformImage = useFileReader(dispatch);

  const drawInputFields = fields => {
    return fields.map(field => {
      const { name, label, type } = field;

      if (name === 'gender') {
        return drawGenderField(field);
      }

      return (
        <ProfileContentField key={name}>
          <ProfileContentFieldLabel>{label}</ProfileContentFieldLabel>
          <ProfileContentFieldInputWrapper>
            {editModeState[name] ? (
              <input
                type={type ? type : 'text'}
                name={name}
                value={profileState[name]}
                placeholder={label}
                onChange={e => dispatch({ type: 'inputChange', name, value: e.target.value })}
                autoFocus
              />
            ) : (
              <div onClick={() => toggleFieldEditMode(name)} onKeyPress={() => toggleFieldEditMode(name)} tabIndex="0">
                {profileState[name] === ''
                  ? `Add your ${label}`
                  : name === 'birthDate'
                  ? format(profileState[name], 'MM/DD/YYYY')
                  : profileState[name]}
              </div>
            )}
            <span
              onClick={() => toggleFieldEditMode(name)}
              onKeyPress={() => toggleFieldEditMode(name)}
              className={editModeState[name] ? 'visible' : null}
              tabIndex="0"
            >
              ✓
            </span>
          </ProfileContentFieldInputWrapper>
        </ProfileContentField>
      );
    });
  };

  const drawGenderField = field => {
    const { name, label, options } = field;

    return (
      <ProfileContentField key={name}>
        <ProfileContentFieldLabel>{label}</ProfileContentFieldLabel>
        <ProfileContentFieldInputWrapper>
          {editModeState[name] ? (
            <ProfileContentRadioInputWrapper>
              {options.map(option => (
                <label key={option}>
                  <input
                    type="radio"
                    checked={profileState[name] === option ? true : false}
                    name={name}
                    value={option}
                    onChange={e => dispatch({ type: 'inputChange', name, value: e.target.value })}
                    autoFocus
                  />
                  {option}
                </label>
              ))}
            </ProfileContentRadioInputWrapper>
          ) : (
            <div onClick={() => toggleFieldEditMode(name)} onKeyPress={() => toggleFieldEditMode(name)} tabIndex="0">
              {profileState[name]}
            </div>
          )}
          <span
            onClick={() => toggleFieldEditMode(name)}
            onKeyPress={() => toggleFieldEditMode(name)}
            className={editModeState[name] ? 'visible' : null}
            tabIndex="0"
          >
            ✓
          </span>
        </ProfileContentFieldInputWrapper>
      </ProfileContentField>
    );
  };

  return (
    <ProfileContentSectionSide>
      <ProfileContentHeadshotWrapper>
        <ProfileContentHeadshotContainer>
          {profileState.headshot ? (
            <img src={profileState.headshot} alt={`${profileState.firstName} ${profileState.lastName}`} />
          ) : (
            <HeadshotPlaceholder />
          )}
          <ProfileContentImageOverlay
            onClick={e => headshotUpload.current.click()}
            onKeyPress={e => headshotUpload.current.click()}
            tabIndex="0"
          >
            <p>Upload a headshot</p>
          </ProfileContentImageOverlay>
        </ProfileContentHeadshotContainer>
        <input
          type="file"
          name="headshot"
          id="headshot"
          accept="image/png, image/jpeg, image/svg"
          ref={headshotUpload}
          onChange={e => transformImage(headshotUpload, 'headshot', dispatch)}
        />
      </ProfileContentHeadshotWrapper>
      <ProfileContentFieldsWrapper flexBasis="65%">
        {drawInputFields(profileContentFirstFields)}
      </ProfileContentFieldsWrapper>
    </ProfileContentSectionSide>
  );
}

export default ProfileContentFirst;

ProfileContentFirst.propTypes = {
  dispatch: PropTypes.func.isRequired,
  profileState: PropTypes.object.isRequired,
};
