import styled from 'styled-components/macro';

// import helpers
import { getColor } from '../../../util/theme';

const LoginFormErrors = styled.div`
  align-self: center;

  background-color: rgba(45, 83, 111, 0.8);

  width: 60%;

  margin: 0 auto;

  p {
    color: ${getColor('accentRed')};
    font-size: 1.6rem;

    margin: 0;
    padding: 5px 0;
  }
`;

export default LoginFormErrors;
