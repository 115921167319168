import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// import helpers
import { getColor } from '../../../../../util/theme';

const AfLoanContactCardBodyWrapper = styled.div`
  background-color: ${getColor('cardGrey')};

  opacity: ${props => (props.isOpen ? '1' : '0')};
  height: ${props => (props.isOpen ? 'auto' : '0')};

  padding: ${props => (props.isOpen ? '15px 15px 5px 15px' : '5px 15px')};

  transition: all 0.3s ease;

  overflow: hidden;
`;

export default AfLoanContactCardBodyWrapper;

AfLoanContactCardBodyWrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
