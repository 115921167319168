function checkIfPasswordsValid(passwordFormState, passwordField, confirmPasswordField) {
  const validity = {
    allValid: true,
    newPassword: true,
    confirmNewPassword: true,
    passwordsMatch: true,
  };

  if (passwordFormState[passwordField].length < passwordFormState.minLength[passwordField]) {
    validity.newPassword = false;
    validity.allValid = false;
  }

  if (passwordFormState[confirmPasswordField].length < passwordFormState.minLength[confirmPasswordField]) {
    validity.confirmNewPassword = false;
    validity.allValid = false;
  }

  if (passwordFormState[passwordField] !== passwordFormState[confirmPasswordField]) {
    validity.passwordsMatch = false;
    validity.allValid = false;
  }

  return validity;
}

export default checkIfPasswordsValid;
