import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

// import root context
import { RootContext } from './RootContext';

// import hooks
import { useGetUserLoans, useGetUserProfile, useIsUserLoggedIn, useLoanCache } from '../../hooks';

// import functions
import { getAndCacheIndividualLoanDetails } from '../App/functions';

export const LoggedInContext = createContext();

function InsiderLoggedInContext({ children }) {
  const {
    state: { userState },
    functions: { setUserState },
  } = useContext(RootContext);

  const [isUserLoggedIn] = useIsUserLoggedIn(setUserState);

  const [profileError, profileLoading, profileData] = useGetUserProfile(userState.userId, userState.accessToken);
  const [loansError, loansLoading, rawLoans, processedLoans] = useGetUserLoans(userState.userId, userState.accessToken);
  const [loanCache, loanCacheDispatch] = useLoanCache();

  /**
   * state for preserving changed profile images when Profile component is unmounted
   * maybe extract this into a custom hook idk
   */
  const [profileImagesUpdated, setProfileImagesUpdated] = useState({
    headshot: {
      updated: false,
      value: '',
    },
    companyLogo: {
      updated: false,
      value: '',
    },
  });

  // individual loan detail hooks
  const handleIndividualLoan = loanNumber => {
    const isLoanInCache = loanCache.loanNumbers.includes(loanNumber);
    if (!isLoanInCache) {
      getAndCacheIndividualLoanDetails(userState, loanNumber, loanCacheDispatch);
    }
  };

  const loggedInContextValues = {
    state: {
      userState,
      profileData,
      profileImagesUpdated,
      rawLoans,
      processedLoans,
      loanCacheList: loanCache.loanNumbers,
      loanCache: loanCache.loans,
    },
    errors: {
      profileError,
      loansError,
    },
    loading: {
      profileLoading,
      loansLoading,
    },
    functions: {
      handleIndividualLoan,
      loanCacheDispatch,
      setProfileImagesUpdated,
    },
  };

  if (!isUserLoggedIn) return null;

  return <LoggedInContext.Provider value={loggedInContextValues}>{children}</LoggedInContext.Provider>;
}

export default InsiderLoggedInContext;

/**
 * receives one child element prop
 */
InsiderLoggedInContext.propTypes = {
  children: PropTypes.element.isRequired,
};
