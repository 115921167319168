import styled from 'styled-components/macro';

// import reusable styled components
import { SubmitButton } from '../../../styled';

const ProfilePasswordSubmitButton = styled(SubmitButton)`
  margin: 25px 0;

  &::after {
    content: 'Update';
  }
`;

export default ProfilePasswordSubmitButton;
