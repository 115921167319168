import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 5px 10px;

  @media (min-width: ${getBreakpoint('lg')}) {
    padding: 15px 10px;
  }

  svg,
  img {
    height: 40px;
    width: 40px;

    margin: 10px 0;

    cursor: pointer;

    &:hover {
      filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    &.menu {
      margin: 0;

      @media (min-width: ${getBreakpoint('md')}) {
        margin: 15px 0 35px 0;
      }
    }
  }
`;

export default DrawerContent;
