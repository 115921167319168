import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../util/theme';

// import reuseable styled components
import { Grid } from '../../styled';

const RegisterContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;

  padding: 50px 0;

  h1 {
    display: block;
    text-align: center;

    color: ${getColor('titleBlue')};
    font-size: 2.9rem;
    font-weight: ${getFontWeight('thin')};

    margin: 25px 0 25px 0;

    @media (min-width: ${getBreakpoint('md')}) {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 4rem;

      margin: 0 0 75px 0;
    }

    svg {
      height: auto;
      width: 100px;

      vertical-align: bottom;

      @media (min-width: ${getBreakpoint('md')}) {
        width: 140px;

        margin: 0 10px;
      }
    }
  }
`;

export default RegisterContainer;
