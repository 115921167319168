import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// import styled components
import { ProfilePasswordFormWrapper, ProfilePasswordSubmitButton, ProfilePasswordMessage } from './styled';

const ProfilePasswordForm = ({
  handleChange,
  passwordState: { oldPassword, newPassword, confirmNewPassword, error },
  responseMessageState,
  tryPasswordUpdate,
}) => (
  <ProfilePasswordFormWrapper onSubmit={e => tryPasswordUpdate(e)}>
    <div>
      <label htmlFor="oldPassword">Old Password *</label>
      <input
        type="password"
        name="oldPassword"
        value={oldPassword}
        onChange={e => handleChange(e)}
        className={error.oldPassword ? 'error' : null}
      />
    </div>
    <div>
      <label htmlFor="newPassword">New Password (6 Characters Minimum) *</label>
      <input
        type="password"
        name="newPassword"
        value={newPassword}
        onChange={e => handleChange(e)}
        className={error.newPassword ? 'error' : null}
      />
    </div>
    <div>
      <label htmlFor="confirmNewPassword">Confirm New Password *</label>
      <input
        type="password"
        name="confirmNewPassword"
        value={confirmNewPassword}
        onChange={e => handleChange(e)}
        className={error.confirmNewPassword ? 'error' : null}
      />
    </div>
    <ProfilePasswordSubmitButton>Update</ProfilePasswordSubmitButton>
    <ProfilePasswordMessage>
      {responseMessageState.type ? (
        <p className={responseMessageState.type}>{ReactHtmlParser(responseMessageState.text)}</p>
      ) : (
        <p>&nbsp;</p>
      )}
    </ProfilePasswordMessage>
  </ProfilePasswordFormWrapper>
);

export default ProfilePasswordForm;

ProfilePasswordForm.propTypes = {
  passwordState: PropTypes.object.isRequired,
  responseMessageState: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  tryPasswordUpdate: PropTypes.func.isRequired,
};
