// import config
import { API_BASE_URL } from '../../../../util/config';

// import loan processing
import processActiveFundedLoan from './processActiveFundedLoan';
import processPrequalifiedPreapprovedLoan from './processPrequalifiedPreapprovedLoan';

async function fetchIndividualLoanDetails(userState, loanNumber) {
  if (userState.userId === 0) {
    return;
  }

  const loanDetailsUrl = `${API_BASE_URL}/api/Loan/${userState.userId}/${loanNumber}`;

  const loanDetails = await fetch(loanDetailsUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${userState.accessToken}`,
    },
  });

  const loanDetailsData = await loanDetails.json();

  if (loanDetailsData.preapproved === 'False' && loanDetailsData.prequalified === 'False') {
    const loan = processActiveFundedLoan(loanDetailsData);
    return loan;
  } else if (loanDetailsData.preapproved === 'True' || loanDetailsData.prequalified === 'True') {
    const loan = processPrequalifiedPreapprovedLoan(loanDetailsData);
    return loan;
  } else {
    // fallback - in process loans may not have any value for preapproved and/or prequalified field
    const loan = processActiveFundedLoan(loanDetailsData);
    return loan;
  }
}

const getIndividualLoanDetails = async (userState, loanNumber, loanCacheDispatch) => {
  await fetchIndividualLoanDetails(userState, loanNumber).then(value =>
    loanCacheDispatch({ type: 'addLoan', loanNumber, loan: value })
  );
};

export default getIndividualLoanDetails;
