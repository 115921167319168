import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

// import styled components
import { DrawerItem, DrawerItemTitle } from './styled';

// import icon
import homeIcon from '../../../assets/icons/home-icon.svg';

const DrawerHome = ({ expanded }) => (
  <Link to="/">
    <DrawerItem expanded={expanded}>
      <img src={homeIcon} alt="Go Home" />
      <DrawerItemTitle>
        <span>Home</span>
      </DrawerItemTitle>
    </DrawerItem>
  </Link>
);

export default DrawerHome;

DrawerHome.propTypes = {
  expanded: PropTypes.bool.isRequired,
};
