import styled from 'styled-components/macro';

const ProfileContentLogoWrapper = styled.div`
  align-self: center;
  flex-basis: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 25px;
  margin: 0 0 25px 0;

  height: 200px;

  input {
    display: none;
  }
`;

export default ProfileContentLogoWrapper;
