function groupContacts(contacts) {
  const contactGroups = {
    0: [],
    1: [],
    2: [],
    3: [],
  };

  Object.keys(contacts).map(key => {
    const newContact = contacts[key];
    if (newContact.exists) {
      switch (key) {
        case 'borrower':
        case 'coBorrower':
          contactGroups[0].push(newContact);
          break;
        case 'mlo':
        case 'clientManager':
        case 'loanProductionAssistant':
          contactGroups[1].push(newContact);
          break;
        case 'escrowCompany':
        case 'titleCompany':
          contactGroups[2].push(newContact);
          break;
        case 'buyerAttorney':
        case 'sellerAgent':
        case 'buyerTransactionCoordinator':
          contactGroups[3].push(newContact);
          break;
        default:
          // do nothing
          break;
      }
    }

    return null;
  });

  return contactGroups;
}

export default groupContacts;
