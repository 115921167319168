import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint } from '../../../../util/theme';

const PreLoanLetterHistoryWrapper = styled.div`
  @media (min-width: ${getBreakpoint('md')}) {
    margin: 35px 0 0 0;
  }
`;

export default PreLoanLetterHistoryWrapper;
