// import functions
import { formatLoans } from '../../../../../util/processLoans';
import transformActiveFundedLoan from './transformActiveFundedLoan';

const addLoanMaps = loanData => {
  const statusInfoMap = new Map(Object.entries(loanData.statusInfo));
  const keyDatesMap = new Map(Object.entries(loanData.keyDates));

  return { ...loanData, statusInfoMap, keyDatesMap };
};

const categorizeLoan = loan => {
  const formattedLoan = formatLoans([loan]);

  const loanCategory = Object.keys(formattedLoan).map(key => {
    if (formattedLoan[key].length > 0) {
      const categoryLoanIsIn = `${key.charAt(0).toUpperCase()}${key.slice(1, key.length)}`;
      if (categoryLoanIsIn === 'Active') {
        const inProcessCategory = 'In Process';
        return inProcessCategory;
      }
      return categoryLoanIsIn;
    } else {
      return '';
    }
  });

  // remove empty categories that loan does not fall into
  const filteredLoanCategory = loanCategory.filter(el => el);

  return filteredLoanCategory[0];
};

const addLoanType = (loanData, formattedLoanData) => {
  if (formattedLoanData.loanType.length > 0) {
    return formattedLoanData;
  }

  return { ...formattedLoanData, loanType: categorizeLoan(loanData) };
};

async function processActiveFundedLoan(loanDetailsData) {
  const formattedLoanDetailsData = await transformActiveFundedLoan(loanDetailsData);

  const formattedLoanDetailsDataWithType = addLoanType(loanDetailsData, formattedLoanDetailsData);

  const formattedLoanDetailsDataWithTypeAndMaps = addLoanMaps(formattedLoanDetailsDataWithType);

  return formattedLoanDetailsDataWithTypeAndMaps;
}

export default processActiveFundedLoan;
