import styled from 'styled-components/macro';

const ProfileContentAddressFieldsWrapper = styled.div`
  flex-basis: 70%;

  display: flex;
  flex-direction: column;

  input:first-of-type {
    flex-basis: 100%;
    align-self: flex-start;
  }

  input:not(first-of-type) {
    flex-basis: 25%;
  }
`;

export default ProfileContentAddressFieldsWrapper;
