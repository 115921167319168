import React from 'react';
import PropTypes from 'prop-types';

// import components
import ProfileContentFirst from './ProfileContentFirst';
import ProfileContentSecond from './ProfileContentSecond';
import ProfileSubmit from './ProfileSubmit';

// import styled components
import { Spinner } from '../../styled';
import { ProfileContentWrapper, ProfileContentSectionWrapper } from './styled';

// import svg

const ProfileContent = ({ accessToken, dispatch, profileState }) => (
  <ProfileContentWrapper>
    {profileState.userId === 0 ? (
      <Spinner />
    ) : (
      <>
        <ProfileContentSectionWrapper>
          <ProfileContentFirst profileState={profileState} dispatch={dispatch} />
          <ProfileContentSecond profileState={profileState} dispatch={dispatch} />
        </ProfileContentSectionWrapper>
        <ProfileSubmit profileState={profileState} accessToken={accessToken} />
      </>
    )}
  </ProfileContentWrapper>
);

export default ProfileContent;

ProfileContent.propTypes = {
  accessToken: PropTypes.string.isRequired,
  profileState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};
