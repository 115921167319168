import React from 'react';
import PropTypes from 'prop-types';

// import hooks
import { useLetterActions } from '../hooks';

// import styled components
import { PreLoanLetterButton, PreLoanLetterButtonWrapper } from '../styled';

// import svg
import { ReactComponent as ViewLetterIcon } from '../../../../assets/buttons/view-letter.svg';
import { ReactComponent as DownloadLetterIcon } from '../../../../assets/buttons/download-letter.svg';

function PreLoanLetterLevelOneButtons({ fileName, letterData, loadingLetter, mobileOnly }) {
  const [previewLetterPdf, downloadLetterPdf] = useLetterActions(letterData, fileName);

  return (
    <PreLoanLetterButtonWrapper justifyContent="space-evenly" className={mobileOnly ? 'mobile-only' : ''}>
      <PreLoanLetterButton disabled={loadingLetter} level="one" onClick={() => previewLetterPdf()}>
        <ViewLetterIcon /> View Letter
      </PreLoanLetterButton>
      <PreLoanLetterButton disabled={loadingLetter} level="one" onClick={() => downloadLetterPdf(letterData, fileName)}>
        <DownloadLetterIcon /> Download Letter
      </PreLoanLetterButton>
    </PreLoanLetterButtonWrapper>
  );
}

export default PreLoanLetterLevelOneButtons;

PreLoanLetterLevelOneButtons.propTypes = {
  fileName: PropTypes.string.isRequired,
  /**
   * letterData is initially null, even with default prop set, so not set to required
   */
  letterData: PropTypes.object,
  loadingLetter: PropTypes.bool.isRequired,
  mobileOnly: PropTypes.bool.isRequired,
};
