import styled from 'styled-components/macro';

// import helpers
import { getColor } from '../../../../util/theme';

const PreLoanModifyDownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  border-top: 1px solid ${getColor('lightestGrey')};

  padding: 15px 0 0 0;
  margin: 25px 0 0 0;

  p {
    color: ${getColor('titleGrey')};
    font-size: 1.4rem;

    padding: 10px 0 0 25px;

    &:first-of-type {
      margin: 1em 1em 0 1em;
    }
  }
`;

export default PreLoanModifyDownloadWrapper;
