import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getFontWeight } from '../../../../util/theme';

const DrawerItemList = styled.ul`
  list-style-type: none;

  padding: 0 15px 0 50px;

  width: 280px;

  li {
    color: ${getColor('white')};
    font-size: 1.3rem;

    padding: 10px 0;

    border-bottom: 1px solid ${getColor('white')};

    &.interactive {
      cursor: pointer;

      &:hover {
        color: ${getColor('accentGreen')};
      }
    }

    &:first-child {
      padding: 0 0 10px 0;
    }

    a {
      color: ${getColor('white')};

      &:hover {
        color: ${getColor('accentGreen')};
      }
    }

    span {
      display: block;

      &:first-of-type {
        font-weight: ${getFontWeight('medium')};
      }
    }
  }
`;

export default DrawerItemList;
