import React from 'react';
import PropTypes from 'prop-types';

// import functions
import { processKeyDates } from '../functions';

// import styled components
import { AfLoanKeyDatesList } from './styled';

function AfLoanKeyDates({ keyDatesMap }) {
  const drawKeyDates = () => {
    const keyDatesList = processKeyDates(keyDatesMap);

    return keyDatesList.map(keyDate => {
      const { date, text } = keyDate;

      if (date) {
        return (
          <li key={text}>
            <span className="date">{date}</span>
            <span>{text}</span>
          </li>
        );
      }

      return null;
    });
  };

  return (
    <AfLoanKeyDatesList>
      <ul>
        <li className="label">
          <span>Date</span>
          <span>Milestone</span>
        </li>
        {drawKeyDates(keyDatesMap)}
      </ul>
    </AfLoanKeyDatesList>
  );
}

export default AfLoanKeyDates;

AfLoanKeyDates.propTypes = {
  keyDatesMap: PropTypes.object.isRequired,
};
