import { useReducer } from 'react';

// import validators
import initializeValidators from '../validators/initializeValidators';

function registrationFormReducer(state, action) {
  const validators = initializeValidators();

  const { type, name, value, validator, file } = action;

  switch (type) {
    case 'inputChange':
      const checkIfError = () => {
        if (!validator) {
          return false;
        }

        if (name === 'confirmPassword') {
          return !validators[validator](value, state.password.value);
        } else {
          return !validators[validator](value);
        }
      };

      return {
        ...state,
        [name]: {
          error: checkIfError(),
          value,
          touched: true,
          required: state[name].required,
        },
      };
    case 'addressInputChange':
      return {
        ...state,
        address: {
          ...state.address,
          [name]: value,
        },
      };
    case 'imageChange':
      return {
        ...state,
        [name]: {
          ...state[name],
          value: value[name],
          file,
        },
      };
    case 'clearImage':
      return {
        ...state,
        [name]: {
          file: null,
          value: '',
          required: false,
        },
      };
    case 'addUntouchedError':
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
        },
      };
    default:
      return { ...state };
  }
}

const initialRegistrationFormState = {
  firstName: {
    value: '',
    touched: false,
    error: false,
    required: true,
  },
  lastName: {
    value: '',
    touched: false,
    error: false,
    required: true,
  },
  company: {
    value: '',
    touched: false,
    error: false,
    required: true,
  },
  title: {
    value: '',
    touched: false,
    error: false,
    required: true,
  },
  licensingNumber: {
    value: '',
    touched: false,
    error: false,
    required: true,
  },
  password: {
    value: '',
    touched: false,
    error: false,
    required: true,
  },
  confirmPassword: {
    value: '',
    touched: false,
    error: false,
    required: true,
  },
  /**
   * Prefilled data for development testing
   */
  // firstName: {
  //   value: 'Gareth',
  //   touched: true,
  //   error: false,
  //   required: true,
  // },
  // lastName: {
  //   value: 'Kloeden',
  //   touched: true,
  //   error: false,
  //   required: true,
  // },
  // company: {
  //   value: 'OneTrust Home Loans',
  //   touched: true,
  //   error: false,
  //   required: true,
  // },
  // title: {
  //   value: 'Developer',
  //   touched: true,
  //   error: false,
  //   required: true,
  // },
  // licensingNumber: {
  //   value: '12345',
  //   touched: true,
  //   error: false,
  //   required: true,
  // },
  // password: {
  //   value: '1317bk',
  //   touched: true,
  //   error: false,
  //   required: true,
  // },
  // confirmPassword: {
  //   value: '1317bk',
  //   touched: true,
  //   error: false,
  //   required: true,
  // },
  /**
   * End prefilled data
   */
  gender: {
    value: '',
    touched: false,
    error: false,
    required: false,
  },
  officePhone: {
    value: '',
    touched: false,
    error: false,
    required: false,
  },
  mobilePhone: {
    value: '',
    touched: false,
    error: false,
    required: false,
  },
  website: {
    value: '',
    touched: false,
    error: false,
    required: false,
  },
  birthDate: {
    value: '',
    touched: false,
    error: false,
    required: false,
  },
  address: {
    street: '',
    city: '',
    state: '',
    zip: '',
    required: false,
  },
  headshot: {
    value: '',
    file: null,
    required: false,
  },
  companyLogo: {
    value: '',
    file: null,
    required: false,
  },
};

function useRegisterReducer() {
  const [state, dispatch] = useReducer(registrationFormReducer, initialRegistrationFormState);

  return [state, dispatch];
}

export default useRegisterReducer;
