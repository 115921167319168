import React from 'react';
import PropTypes from 'prop-types';

// import components
import AfLoanDatesAndContacts from './AfLoanDatesAndContacts';
import AfLoanStatus from './AfLoanStatus';

// import styled components
import { AfLoanBodyBackground, AfLoanBody } from './styled';
import { Grid } from '../../styled';

const ActiveFundedLoan = ({ loanData }) => (
  <AfLoanBodyBackground>
    <Grid>
      <AfLoanBody>
        <AfLoanDatesAndContacts loanData={loanData} />
        <AfLoanStatus loanData={loanData} />
      </AfLoanBody>
    </Grid>
  </AfLoanBodyBackground>
);

export default ActiveFundedLoan;

ActiveFundedLoan.propTypes = {
  loanData: PropTypes.object.isRequired,
};
