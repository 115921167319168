import React, { useRef } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// import hooks & functions
import { useIsOpen } from '../../../hooks';
import { setIsOpenAndSetExpanded } from './functions';

// import styled components
import { DrawerItem, DrawerItemTitle, DrawerItemListWrapper, DrawerItemList } from './styled';

// import svg
import loansIcon from '../../../assets/icons/loans-icon.svg';
import { ReactComponent as DrawerArrow } from '../../../assets/ui/drawer-arrow.svg';

function DrawerLoans({
  expanded,
  toggleDrawer,
  handleIndividualLoan,
  processedLoans: { active = [], prequalified = [], preapproved = [] },
}) {
  const loansListRef = useRef(null);
  const [isOpen, setIsOpen] = useIsOpen(true);
  return (
    <>
      <DrawerItem expanded={expanded}>
        <img
          src={loansIcon}
          alt="Loans"
          onClick={() => setIsOpenAndSetExpanded(isOpen, setIsOpen, expanded, toggleDrawer)}
        />
        <DrawerItemTitle expanded={expanded} onClick={() => setIsOpen()}>
          <span>Loans</span>
          <DrawerArrow className={isOpen ? null : 'isClosed'} />
        </DrawerItemTitle>
      </DrawerItem>
      <DrawerItemListWrapper
        isOpen={isOpen}
        expanded={expanded}
        refHeight={loansListRef.current ? loansListRef.current.clientHeight : 0}
      >
        <div ref={loansListRef}>
          {active.length > 0 ? (
            <>
              <DrawerItemTitle>
                <span className="secondary">In Process Loans</span>
              </DrawerItemTitle>
              <DrawerItemList>
                {active.map(({ borrower, id, propertyAddr, category: type }) => {
                  return (
                    <li key={id} onMouseOver={() => handleIndividualLoan(id, type)}>
                      <Link to={`/loans/${id}`} state={{ type }}>
                        <span>{borrower}</span>
                        <span>{ReactHtmlParser(propertyAddr)}</span>
                      </Link>
                    </li>
                  );
                })}
              </DrawerItemList>
            </>
          ) : null}

          {prequalified.length > 0 ? (
            <>
              <DrawerItemTitle>
                <span className="secondary">Pre-Qualified Loans</span>
              </DrawerItemTitle>
              <DrawerItemList>
                {prequalified.map(({ borrower, id, propertyAddr, category: type }) => {
                  return (
                    <li key={id} onMouseOver={() => handleIndividualLoan(id, type)}>
                      <Link to={`/loans/${id}`} state={{ type }}>
                        <span>{borrower}</span>
                        <span>{ReactHtmlParser(propertyAddr)}</span>
                      </Link>
                    </li>
                  );
                })}
              </DrawerItemList>
            </>
          ) : null}

          {preapproved.length > 0 ? (
            <>
              <DrawerItemTitle>
                <span className="secondary">Pre-Approved Loans</span>
              </DrawerItemTitle>
              <DrawerItemList>
                {preapproved.map(({ borrower, id, propertyAddr, category: type }) => {
                  return (
                    <li key={id} onMouseOver={() => handleIndividualLoan(id, type)}>
                      <Link to={`/loans/${id}`} state={{ type }}>
                        <span>{borrower}</span>
                        <span>{ReactHtmlParser(propertyAddr)}</span>
                      </Link>
                    </li>
                  );
                })}
              </DrawerItemList>
            </>
          ) : null}
        </div>
      </DrawerItemListWrapper>
    </>
  );
}

export default DrawerLoans;

DrawerLoans.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  handleIndividualLoan: PropTypes.func.isRequired,
  processedLoans: PropTypes.object.isRequired,
};
