import { useContext, useEffect } from 'react';

// import context
import { RootContext } from '../components/Context/RootContext';

function useBrandFinder(origin) {
  const {
    state: { brandState },
    functions: { setBrandState },
  } = useContext(RootContext);

  useEffect(() => {
    // if brand already loaded and stored in context, exit
    if (brandState !== 'none') return;

    const isCalcon = origin.includes('calcon');
    const isOthl = origin.includes('othl');
    const isTabor = origin.includes('tabor');

    if (isCalcon) {
      setBrandState('calcon');
    } else if (isOthl) {
      setBrandState('othl');
    } else if (isTabor) {
      setBrandState('tabor');
    }
  }, []);

  return [brandState];
}

export default useBrandFinder;
