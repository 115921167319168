const profileContentFirstFields = [
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'company',
    label: 'Company',
  },
  {
    name: 'title',
    label: 'Title',
  },
  {
    name: 'gender',
    label: 'Gender',
    options: ['Male', 'Female', 'Not Specified'],
  },
  {
    name: 'license',
    label: 'License',
  },
  {
    name: 'birthDate',
    label: 'Birth Date',
    type: 'date',
  },
];

export default profileContentFirstFields;
