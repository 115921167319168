import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../util/theme';

const HeaderLogo = styled.div`
  padding: 0 0 0 65px;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 25px 0;

    padding: 0 0 0 50px;
  }

  svg {
    margin: 0 5px;

    @media (min-width: ${getBreakpoint('md')}) {
      margin: 0 25px;
    }

    &.lfLogo {
      width: 120px;

      @media (min-width: ${getBreakpoint('md')}) {
        width: 200px;
      }
    }

    &.insiderLogo {
      width: 70px;

      margin: 0 0 -5px 0;

      @media (min-width: ${getBreakpoint('md')}) {
        width: 120px;

        margin: 0 0 -10px 0;
      }
    }
  }

  a {
    display: flex;
    align-items: center;

    @media (min-width: ${getBreakpoint('md')}) {
      padding: 0 15px;
    }
  }
`;

export default HeaderLogo;
